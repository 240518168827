import '../../styling/TriStateSwitch.css';

import React, { useEffect, useState } from 'react';

import { TEST_ATTRIBUTES } from '../../config/testConstants';
import TriStateLeftButton from '../Buttons/presentational/TriStateLeftButton';
import TriStateRightButton from '../Buttons/presentational/TriStateRightButton';
import TriStateWhiteButton from '../Buttons/presentational/TriStateWhiteButton';

export default function TriStateSwitch({ onChange, state = null }) {
    const STATES = {
        UNSET: null,
        LEFT: false,
        RIGHT: true
    };

    const [switchState, setSwitchState] = useState(state);

    useEffect(() => {
        if (typeof onChange === 'function' && state !== switchState) {
            onChange(switchState);
        }
    }, [switchState]);

    useEffect(() => {
        if (state !== switchState) {
            setSwitchState(state);
        }
    }, [state]);

    if (!Object.values(STATES).includes(state)) {
        console.error(
            'TriStateSwitch: state must be null (UNSET), false (LEFT), or true (RIGHT)'
        );
        return null;
    }

    function getSwitchClass() {
        if (switchState === false) {
            return 'TriStateLeft';
        } else if (switchState === true) {
            return 'TriStateRight';
        } else {
            return 'TriStateUnset';
        }
    }

    return (
        /*
            Three-state switch without using any actual input elements.
            When unset, it will have a grey a background, and both the left
            and right side buttons (reject and accept), will be shown and
            clickable. On clicking either of the buttons, the state will be
            updated to left or right, and the switch will change into what
            looks like a toggle that is set to the left or right. That entire
            toggle is clickable to toggle the state back to unset. There are no
            fancy animations/transitions that allow people to click and drag
            the toggle or show the toggle button moving left or right.
        */
        <div
            className={`TriStateSwitch ${getSwitchClass()}`}
            data-testid={TEST_ATTRIBUTES.THREAT_DECK.TRI_SWITCH.SWITCH}
            onClick={() =>
                switchState !== STATES.UNSET
                    ? setSwitchState(STATES.UNSET)
                    : undefined
            }
        >
            <div>
                {switchState === STATES.UNSET && (
                    <TriStateLeftButton
                        dataTestId={
                            TEST_ATTRIBUTES.THREAT_DECK.TRI_SWITCH.BUTTONS
                                .UNSET_LEFT
                        }
                        onClick={() => setSwitchState(STATES.LEFT)}
                    />
                )}
                {switchState === STATES.LEFT && (
                    <TriStateWhiteButton
                        dataTestId={
                            TEST_ATTRIBUTES.THREAT_DECK.TRI_SWITCH.BUTTONS.LEFT
                        }
                    />
                )}
            </div>
            <div>
                {switchState === STATES.UNSET && (
                    <TriStateWhiteButton inactive={true} />
                )}
            </div>
            <div>
                {switchState === STATES.UNSET && (
                    <TriStateRightButton
                        dataTestId={
                            TEST_ATTRIBUTES.THREAT_DECK.TRI_SWITCH.BUTTONS
                                .UNSET_RIGHT
                        }
                        onClick={() => setSwitchState(STATES.RIGHT)}
                    />
                )}
                {switchState === STATES.RIGHT && (
                    <TriStateWhiteButton
                        dataTestId={
                            TEST_ATTRIBUTES.THREAT_DECK.TRI_SWITCH.BUTTONS.RIGHT
                        }
                    />
                )}
            </div>
        </div>
    );
}
