import './ThreatDeckColumnForm.css';

import capitalize from 'lodash/capitalize';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import max from 'lodash/max';
import pick from 'lodash/pick';
import some from 'lodash/some';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import validate from 'validate.js';

import {
    COLUMN_CONTENT_TYPES,
    COLUMNS,
    NAMESPACES,
    ROLES,
    THREAT_DECK_MULTISELECT_COMPONENT_STYLES,
    TWITTER_RULES
} from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getAuthInfo, getGroups, getNamespace } from '../../../redux/selectors';
import { auditsFailedModal } from '../../../utils';
import ICButton from '../../Buttons/presentational/ICButton';
import ThreatDeckColumnFormCategories from './ThreatDeckColumnFormCategories';
import ThreatDeckColumnFormRegions from './ThreatDeckColumnFormRegions';
// import ThreatDeckColumnFormSeverities from './ThreatDeckColumnFormSeverities';
import ThreatDeckColumnFormSources from './ThreatDeckColumnFormSources';
import ThreatDeckColumnFormTwitterRules from './ThreatDeckColumnFormTwitterRules';

const {
    THREAT_DECK: {
        COLUMNS: { COLUMN_CONFIG }
    }
} = TEST_ATTRIBUTES;

export default function ThreatDeckColumnForm({
    closeModal,
    column,
    columns = []
}) {
    // STATE
    const [name, setName] = useState(column?.name || '');
    const [severity, setSeverity] = useState(column?.severity || []);
    const [categories, setSelectedCategories] = useState(
        column?.categories || []
    );
    const [confidenceFloor, setConfidenceFloor] = useState(
        column?.confidenceFloor || 0
    );
    const [confidenceCeiling, setConfidenceCeiling] = useState(
        column?.confidenceCeiling || 100
    );
    const [order, setOrder] = useState(column?.order || 0);
    const [sources, setSources] = useState(column?.sources || []);
    const [type, setType] = useState(column?.type || COLUMNS.TYPE.USER);
    const [contentType, setContentType] = useState(column?.contentType || '');
    const [regions, setRegions] = useState(column?.regions || []);
    const [twitterRules, setTwitterRules] = useState(
        column?.twitterRules || []
    );
    const [followedAccountsFilter, setFollowedAccountsFilter] = useState(
        column?.followedAccountsFilter || false
    );
    const [group, setGroup] = useState(column?.group);

    // REDUX
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );
    const allGroups = useSelector(getGroups);
    const authInfo = useSelector(getAuthInfo);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('columnSaveFailed', () => {
                confirmAlert({
                    title: 'Save Failed',
                    message: 'There was an error.',
                    buttons: [
                        {
                            label: 'No',
                            onClick: () => {}
                        }
                    ]
                });
            });
        }
        return () => {
            threatDeckNamespace.off('columnSaveFailed');
        };
    }, []);

    function auditData() {
        if (!contentType) {
            return ['Columns must have a content type.'];
        }

        const data = {
            name,
            categories,
            confidenceCeiling,
            confidenceFloor,
            contentType,
            order,
            regions,
            severity,
            sources,
            type
        };

        const validationFields = pick(
            data,
            COLUMNS.VALIDATION.FIELDS[contentType]
        );

        const validationConstraints = pick(
            COLUMNS.VALIDATION.CONSTRAINTS,
            COLUMNS.VALIDATION.FIELDS[contentType]
        );

        return validate(validationFields, validationConstraints, {
            format: 'flat'
        });
    }

    function clearAndCloseModal() {
        clearData();
        closeModal();
    }

    function clearData() {
        setName('');
        setSeverity([]);
        setSelectedCategories([]);
        setConfidenceFloor(0);
        setConfidenceCeiling(0);
        setFollowedAccountsFilter(false);
        setTwitterRules([]);
        setOrder(0);
        setSources([]);
        setType(COLUMNS.TYPE.USER);
        setContentType('');
        setRegions([]);
    }

    function confirmDelete() {
        confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure you want to delete this column?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: deleteColumn
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function confirmSave() {
        const validationMessages = auditData();
        if (isEmpty(validationMessages)) {
            saveColumn();
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function deleteColumn() {
        threatDeckNamespace.emit('deleteColumn', column?.id);
        closeModal();
    }

    function saveColumn() {
        const nextOrder = (max(map(columns, (c) => c.order)) || 0) + 1;

        if (contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS) {
            threatDeckNamespace.emit('saveColumn', {
                id: column?.id,
                name,
                severity,
                categories,
                confidenceFloor,
                confidenceCeiling,
                contentType,
                order: column?.id ? order : nextOrder,
                sources,
                twitterRules: map(filter(twitterRules, 'id'), 'id'), // remove followed accounts pseudo rule and only send rule ids
                followedAccountsFilter,
                type,
                regions: map(regions, 'id'),
                group: map(group, 'id')
            });
        } else if (contentType === COLUMNS.CONTENT_TYPE.EVENTS) {
            threatDeckNamespace.emit('saveColumn', {
                id: column?.id,
                name,
                categories,
                contentType,
                order: column?.id ? order : nextOrder,
                type,
                regions: map(regions, 'id'),
                group: map(group, 'id')
            });
        }
        closeModal();
    }

    function updateName(e) {
        setName(e.target.value);
    }

    function updateRegions(newRegionsList) {
        setRegions(newRegionsList);
    }

    function updateGroup(selectedGroup) {
        setGroup(selectedGroup);
    }

    function updateContentType([selectedType]) {
        setContentType(selectedType.value);
    }

    // function updateSeverities(newSeverities) {
    //     setSeverity(newSeverities);
    // }

    function updateCategories(selectedCategories) {
        setSelectedCategories(selectedCategories);
    }

    function updateConfidenceFloor(e) {
        setConfidenceFloor(Number(e.target.value));
    }

    function updateConfidenceCeiling(e) {
        setConfidenceCeiling(Number(e.target.value));
    }

    function updateSources(sources) {
        setSources(sources);
    }

    function updateTwitterRules(rules) {
        if (some(rules, { tag: TWITTER_RULES.FOLLOWED_ACCOUNTS })) {
            setFollowedAccountsFilter(true);
        } else {
            setFollowedAccountsFilter(false);
        }
        setTwitterRules(rules);
    }

    function canDelete() {
        if (isEmpty(column?.group)) {
            return column?.id;
        } else {
            return column?.id && includes([ROLES.ADMINISTRATOR], authInfo.role);
        }
    }

    function canSave() {
        if (!isEmpty(column?.group)) {
            return column?.id && includes([ROLES.ADMINISTRATOR], authInfo.role);
        }
        return true;
    }

    return (
        <div>
            <div className="ThreatDeckColumnForm-header">
                <h2>{`${
                    column?.id
                        ? `Edit ${capitalize(column?.type)} Column`
                        : `Create New ${capitalize(column?.type)} Column`
                }`}</h2>
                <button
                    className="ReactModal__CloseButton"
                    onClick={clearAndCloseModal}
                    data-testid={`${COLUMN_CONFIG.CLOSE_MODAL}${
                        column?.id ? `-${column.id}` : ''
                    }`}
                >
                    <i className="fa fa-window-close" />
                </button>
            </div>

            <div className="ContentManagementModal-body">
                <div className="ThreatDeck-input-container">
                    <div className="input column">
                        <label
                            className="input-label"
                            htmlFor="ThreatDeck-column-name"
                        >
                            Column Name:
                        </label>
                        <input
                            id="ThreatDeck-column-name"
                            className="ContentForm-form-control"
                            type="text"
                            value={name}
                            onChange={updateName}
                            data-testid={COLUMN_CONFIG.COLUMN_NAME}
                        />
                    </div>
                </div>

                <div
                    className="ThreatDeck-input-container"
                    data-testid={`${COLUMN_CONFIG.CONTENT_TYPE}`}
                >
                    <label
                        className="input-label"
                        htmlFor="columnContentTypeMultiselect"
                    >
                        Content Type:
                    </label>
                    <Multiselect
                        id={`columnContentTypeMultiselect`}
                        disable={column?.id}
                        selectionLimit={1}
                        options={COLUMN_CONTENT_TYPES}
                        onSelect={updateContentType}
                        displayValue="label"
                        singleSelect={true}
                        placeholder="Content Type"
                        style={THREAT_DECK_MULTISELECT_COMPONENT_STYLES}
                        selectedValues={
                            column?.contentType
                                ? [
                                      find(COLUMN_CONTENT_TYPES, {
                                          value: column.contentType
                                      })
                                  ]
                                : []
                        }
                    />
                </div>

                <div className="ContentForm-input-container">
                    <ThreatDeckColumnFormRegions
                        updateRegions={updateRegions}
                        column={column}
                    />
                </div>

                <ThreatDeckColumnFormCategories
                    selectedCategories={categories}
                    onChange={updateCategories}
                />

                {contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS && (
                    <ThreatDeckColumnFormSources
                        sources={sources}
                        onChange={updateSources}
                    />
                )}

                {contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS &&
                    includes(sources, 'TWITTER') && (
                        <ThreatDeckColumnFormTwitterRules
                            selectedRules={twitterRules}
                            followedAccountsFilter={followedAccountsFilter}
                            onChange={updateTwitterRules}
                        />
                    )}

                {contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS && (
                    <div className="ThreatDeck-input-container">
                        <div className="input column">
                            <label
                                className="input-label"
                                htmlFor="column-confidence-floor"
                            >
                                Minimum and Maximum Confidence Levels:
                            </label>
                            <div className="ThreatDeck-column-confidence-inputs">
                                <input
                                    id="column-confidence-floor"
                                    min="0"
                                    max="99"
                                    step="5"
                                    className="ContentForm-form-control"
                                    type="number"
                                    value={confidenceFloor.toString()}
                                    onChange={updateConfidenceFloor}
                                    data-testid={COLUMN_CONFIG.CONFIDENCE_FLOOR}
                                />
                                <input
                                    id="column-confidence-ceiling"
                                    min="0"
                                    max="99"
                                    step="5"
                                    className="ContentForm-form-control"
                                    type="number"
                                    value={confidenceCeiling.toString()}
                                    onChange={updateConfidenceCeiling}
                                    data-testid={
                                        COLUMN_CONFIG.CONFIDENCE_CEILING
                                    }
                                />
                            </div>
                            <div>
                                Only items in this range of confidence levels
                                will be shown in this column
                            </div>
                        </div>
                    </div>
                )}

                {includes(['Administrator'], authInfo?.user?.role) && (
                    <div
                        className="ThreatDeck-input-container"
                        data-testid={`${COLUMN_CONFIG.GROUP}`}
                    >
                        <label className="input-label" htmlFor="column-group">
                            Group:
                        </label>
                        <Multiselect
                            id="updateTypeMultiselect"
                            selectionLimit={1}
                            options={allGroups}
                            onSelect={updateGroup}
                            displayValue="name"
                            singleSelect={true}
                            placeholder="Group"
                            style={THREAT_DECK_MULTISELECT_COMPONENT_STYLES}
                            selectedValues={column?.group ? column?.group : []}
                        />
                    </div>
                )}

                <div className="ThreatDeck-column-form-footer row">
                    <div className="column-footer-left">
                        {canDelete() && (
                            <ICButton
                                color="red"
                                onClick={confirmDelete}
                                data-testid={COLUMN_CONFIG.BUTTONS.DELETE}
                            >
                                Delete Column
                            </ICButton>
                        )}
                    </div>
                    <div className="column-footer-right">
                        <ICButton
                            color="white"
                            onClick={clearAndCloseModal}
                            data-testid={`${COLUMN_CONFIG.BUTTONS.CANCEL}`}
                        >
                            Cancel
                        </ICButton>
                        <ICButton
                            color="blue"
                            onClick={confirmSave}
                            data-testid={
                                column?.id
                                    ? COLUMN_CONFIG.BUTTONS.SAVE
                                    : COLUMN_CONFIG.BUTTONS.CREATE
                            }
                            disabled={!canSave()}
                        >
                            {`${column?.id ? 'Save' : 'Create'}`}
                        </ICButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
