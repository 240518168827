import '../styling/PageNotFound.css';

import React from 'react';
import { useRouteError } from 'react-router-dom';

import { TEST_ATTRIBUTES } from '../config/testConstants';

export default function ErrorPage() {
    const error = useRouteError();

    return (
        <div
            className="PageNotFound-container"
            data-testid={TEST_ATTRIBUTES.ERROR_PAGE}
        >
            <h2>There was an error</h2>

            <p>
                An error occurred loading this page. Please try refreshing or
                opening in a new browser window.
            </p>

            <p>
                If the issue persists, please reach out to your manager who will
                be able to contact our development team for further assistance.
            </p>

            <p>
                <strong>Error message for reference:</strong> {error?.message}
            </p>
        </div>
    );
}
