import _ from 'lodash';
import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter } from 'react-router-dom';

import SituationReportForm from './components/ContentManagement/SituationReports/forms/SituationReportForm';
import SituationReports from './components/ContentManagement/SituationReports/SituationReports';
import SituationReportsContainer from './components/ContentManagement/SituationReports/SituationReportsContainer';
import TravelBriefForm from './components/ContentManagement/TravelBriefs/forms/TravelBriefForm';
import TravelBriefs from './components/ContentManagement/TravelBriefs/TravelBriefs';
import TravelBriefsContainer from './components/ContentManagement/TravelBriefs/TravelBriefsContainer';
import ErrorPage from './components/ErrorPage';
import PageNotFound from './components/PageNotFound';
import ThreatDeckEventDetails from './components/ThreatDeck/ThreatDeckEvents/ThreatDeckEventDetails';
import ThreatDeckEvents from './components/ThreatDeck/ThreatDeckEvents/ThreatDeckEvents';
import ThreatDeckEventsContainer from './components/ThreatDeck/ThreatDeckEvents/ThreatDeckEventsContainer';
import ThreatDeckHomePage from './components/ThreatDeck/ThreatDeckHomePage';
import ThreatDeckLabeling from './components/ThreatDeck/ThreatDeckLabeling/ThreatDeckLabeling';
import ThreatsHomePage from './components/ThreatsHomePage';
import Unauthorized from './components/Unauthorized';
import IntelCenterApp from './IntelCenterApp';
import store from './redux/store';
import ThreatDeckApp from './ThreatDeckApp';

const routerConfigs = {
    full: [
        {
            path: '/',
            element: (
                <Provider store={store}>
                    <IntelCenterApp />
                </Provider>
            ),
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <ThreatsHomePage />
                },
                {
                    path: 'situation-reports',
                    element: <SituationReportsContainer />,
                    children: [
                        {
                            path: '',
                            element: <SituationReports />
                        },
                        {
                            path: 'edit/:situationId?',
                            element: <SituationReportForm />
                        }
                    ]
                },
                {
                    path: 'travel-briefs',
                    element: <TravelBriefsContainer />,
                    children: [
                        {
                            path: '',
                            element: <TravelBriefs />
                        },
                        {
                            path: 'edit/:travelBriefId?',
                            element: <TravelBriefForm />
                        }
                    ]
                },
                {
                    path: '/401',
                    element: <Unauthorized />
                },
                {
                    path: '*',
                    element: <PageNotFound />
                }
            ]
        }
    ],
    local: [
        {
            path: '/',
            element: (
                <Provider store={store}>
                    <IntelCenterApp />
                </Provider>
            ),
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <ThreatsHomePage />
                },
                {
                    path: 'situation-reports',
                    element: <SituationReportsContainer />,
                    children: [
                        {
                            path: '',
                            element: <SituationReports />
                        },
                        {
                            path: 'edit/:situationId?',
                            element: <SituationReportForm />
                        }
                    ]
                },
                {
                    path: 'travel-briefs',
                    element: <TravelBriefsContainer />,
                    children: [
                        {
                            path: '',
                            element: <TravelBriefs />
                        },
                        {
                            path: 'edit/:travelBriefId?',
                            element: <TravelBriefForm />
                        }
                    ]
                },
                {
                    path: '/threat-deck',
                    element: (
                        <Provider store={store}>
                            <ThreatDeckApp />
                        </Provider>
                    ),
                    children: [
                        {
                            path: '/threat-deck',
                            element: <ThreatDeckHomePage />
                        }
                    ]
                },
                {
                    path: '/401',
                    element: <Unauthorized />
                },
                {
                    path: '*',
                    element: <PageNotFound />
                }
            ]
        }
    ],
    threatdeck: [
        {
            path: '/',
            element: (
                <Provider store={store}>
                    <ThreatDeckApp />
                </Provider>
            ),
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <ThreatDeckHomePage />
                },
                {
                    path: 'labeling',
                    element: <ThreatDeckLabeling />
                },
                {
                    path: 'events',
                    element: <ThreatDeckEventsContainer />,
                    children: [
                        {
                            path: '',
                            element: <ThreatDeckEvents />
                        },
                        {
                            path: ':eventId?',
                            element: <ThreatDeckEventDetails />
                        }
                    ]
                },
                {
                    path: 'events',
                    element: <ThreatDeckEvents />
                },
                {
                    path: '*',
                    element: <PageNotFound />
                }
            ]
        }
    ]
};

// Using logic based on runtime environment variables can't be done here because it is an async operation.
// For now we will use the hostname to determine which service level we are using, and adjust the router config accordingly.
// TODO: We either want to bundle a separate router config for each service level into the builds (Dockerfile)
// or figure out if enabling top level await is something that we want to do so that we can access runtime values here.
let routerServiceLevel;
if (
    _.startsWith(window.location.hostname, 'ic') ||
    _.startsWith(window.location.hostname, 'st1-ic') ||
    _.startsWith(window.location.hostname, 'staging-ic')
) {
    routerServiceLevel = 'full';
} else if (
    _.startsWith(window.location.hostname, 'gator') ||
    _.startsWith(window.location.hostname, 'st1-gator') ||
    _.startsWith(window.location.hostname, 'st1-td') ||
    _.startsWith(window.location.hostname, 'td')
) {
    routerServiceLevel = 'threatdeck';
} else if (_.startsWith(window.location.hostname, 'localhost')) {
    // cypress currently uses IC_SERVICE_LEVEL 'full' but does not have REACT_APP_IC_SERVICE_LEVEL defined
    // TODO: include the REACT_APP_IC_SERVICE_LEVEL in the cypress local_dev entry
    // Later this can also help us to specifically target threat deck when runnning tests by having a cypress-td entry as well!
    if (process.env.REACT_APP_IC_SERVICE_LEVEL === undefined) {
        routerServiceLevel = 'full';
    } else {
        routerServiceLevel = process.env.REACT_APP_IC_SERVICE_LEVEL;
    }
}

const router = createBrowserRouter(routerConfigs[routerServiceLevel]);

export default router;
