import './ThreatDeckLabeling.css';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { getNamespace } from '../../../redux/selectors';
import ThreatDeckCardReviewModal from '../../Modals/ThreatDeck/ThreatDeckCardReviewModal';

export default function ThreatDeckLabeling() {
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );
    const [labelerItem, setLabelerItem] = useState();
    const [queueName, setQueueName] = useState('threatdeck_labeler');

    useEffect(() => {
        if (threatDeckNamespace) {
            // listeners
            threatDeckNamespace.on('LabelerJob', (job) => {
                setLabelerItem(job);
            });
        }
    }, [threatDeckNamespace]);

    useEffect(() => {
        if (threatDeckNamespace) {
            // emits
            threatDeckNamespace.emit('pullJob', {
                queueName
            });
        }
    }, [threatDeckNamespace, queueName]);

    return (
        <>
            {/* <h1>{labelerItem?.id}</h1> */}
            <div className="threat-deck-labeling">
                {labelerItem && (
                    <ThreatDeckCardReviewModal
                        item={labelerItem}
                        labeling={{ setLabelerItem, queueName }}
                    />
                )}
            </div>
        </>
    );
}
