import React from 'react';

import { CONTENT_MANAGEMENT } from '../../../config/constants';

const { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_DRAFT, STATUS_PENDING } =
    CONTENT_MANAGEMENT;

export default function StatusBadge({ status }) {
    let color, extraClassNames, text;
    if (status === STATUS_DRAFT) {
        color = 'yellow';
        text = 'Draft';
        extraClassNames = 'color-black';
    }
    if (status === STATUS_PENDING) {
        color = 'blue';
        text = 'Pending';
    }
    if (status === STATUS_ACTIVE) {
        color = 'dark-green';
        text = 'Active';
    }
    if (status === STATUS_INACTIVE) {
        color = 'grey';
        text = 'Inactive';
    }
    return (
        <span className={`badge ${color}-button ${extraClassNames}`}>
            {text}
        </span>
    );
}
