import '../../../styling/ThreatDeckCard.css';

import React from 'react';

import ThreatDeckCardAcceptReject from './ThreatDeckCardAcceptReject';
import ThreatDeckCardRemoveEvent from './ThreatDeckCardRemoveEvent';
import ThreatDeckCardReview from './ThreatDeckCardReview';

export default function ThreatDeckCardFooter({
    item,
    markAsRead,
    onSwitchChange,
    isEventView = false
}) {
    return (
        <div className="ThreatDeckCard-footer">
            <div className="footer-left">
                <ThreatDeckCardAcceptReject
                    onSwitchChange={onSwitchChange}
                    state={item?.annotation?.ui_type}
                />
            </div>
            <div className="footer-right">
                {!isEventView && (
                    <ThreatDeckCardReview item={item} markAsRead={markAsRead} />
                )}
                {isEventView && <ThreatDeckCardRemoveEvent item={item} />}
            </div>
        </div>
    );
}
