import { Editor } from '@tinymce/tinymce-react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    IoAttachOutline,
    IoImageOutline,
    IoPaperPlaneOutline
} from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../config/constants';
import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { getEnabledCapabilities, getNamespace } from '../../redux/selectors';
import { stripTags } from '../../utils';

const { CHAT_VIEW } = TEST_ATTRIBUTES;
export default function AnalystChatNewMessage(props) {
    const sendText = props.sendText || 'Send';
    const [message, setMessage] = useState(props.message || '');
    const [editorKey, setEditorKey] = useState(0);
    const chatNamespace = useSelector(getNamespace(NAMESPACES.CHAT_NAMESPACE));
    const capabilities = useSelector(getEnabledCapabilities);

    useEffect(() => {
        setEditorKey((currVal) => currVal + 1);
    }, [props.replyToId]);

    function handleSend() {
        sendMessage(message, props.replyToId);
        setMessage('');
        setEditorKey((currVal) => currVal + 1);
    }

    function handleEnterSend(e) {
        if (e.ctrlKey && e.keyCode === 13 && !isEmpty(message)) {
            handleSend();
        }
    }

    function sendMessage(message, replyToId) {
        chatNamespace.emit('sendChatMessage', {
            message: message,
            replyToId: replyToId
        });
    }

    function handleAttachFile() {
        console.log('handling file attachment');
    }

    function handleAttachImage() {
        console.log('handling image attachment');
    }

    function handleInput(inputVal) {
        setMessage(inputVal);
    }

    const TINY_MCE_INIT_OBJECT = {
        placeholder: props.placeholder,
        height: 200,
        menubar: false,
        plugins: ['autolink', 'link'],
        toolbar: 'link',
        paste_preprocess: function (plugin, args) {
            // We will do our own pasting processing - we allow only certain HTML tags below
            args.content = stripTags(
                args.content,
                'b',
                'i',
                'u',
                'p',
                'br',
                'a',
                'strong',
                'em'
            );
        },
        extended_valid_elements: 'span',
        link_default_target: '_blank',
        browser_spellcheck: true,
        contextmenu: false
    };

    return (
        <div className="ChatSendContainer">
            <Editor
                key={editorKey}
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                init={TINY_MCE_INIT_OBJECT}
                onEditorChange={handleInput}
                onKeyDown={handleEnterSend}
            />
            <div className="ChatButtons">
                <div className="ChatButtons-section">
                    {capabilities.includes('analyst_chat_file_uploads') && (
                        <>
                            <button
                                className="ChatButton"
                                data-testid={`${CHAT_VIEW.BUTTONS.CHAT_ATTACH_IMAGE}`}
                                onClick={handleAttachImage}
                            >
                                <IoImageOutline size={'28'} />
                            </button>
                            <button
                                className="ChatButton"
                                data-testid={`${CHAT_VIEW.BUTTONS.CHAT_ATTACH_VIDEO}`}
                                onClick={handleAttachFile}
                            >
                                <IoAttachOutline size={'28'} />
                            </button>
                        </>
                    )}
                </div>
                <div className="ChatButtons-section">
                    <button
                        className="ChatButton"
                        data-testid={`${CHAT_VIEW.BUTTONS.CHAT_SEND}`}
                        onClick={handleSend}
                        disabled={isEmpty(message)}
                    >
                        <IoPaperPlaneOutline size={'28'} />
                        <div className="ChatButton-text">{sendText}</div>
                    </button>
                </div>
            </div>
        </div>
    );
}
