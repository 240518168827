import '../../../styling/ThreatDeckCard.css';

import React from 'react';

import ThreatDeckCardConfidence from './ThreatDeckCardConfidence';
import ThreatDeckCardSource from './ThreatDeckCardSource';

export default function ThreatDeckCardHeader({ item, cardView = false }) {
    return (
        <div className="ThreatDeckCard-header">
            <div className="header-left">
                <ThreatDeckCardSource
                    type={item?.type}
                    sourceName={item?.source_name}
                    sourceLink={item?.link || item?.source_link}
                    cardView={cardView}
                />
            </div>
            <div className="header-right">
                <ThreatDeckCardConfidence
                    confidence={item?.annotation?.ml_confidence}
                />
            </div>
        </div>
    );
}
