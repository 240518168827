import '../../styling/AlertDetails.css';

import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { BiWorld } from 'react-icons/bi';
import { CgFileDocument } from 'react-icons/cg';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import {
    NAMESPACES,
    ROLES,
    UPDATETYPES,
    URGENCIES
} from '../../config/constants';
import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { usePrevious } from '../../hooks/shared';
import { setAlertModal } from '../../redux/actions';
import {
    getAlertModal,
    getAuthInfo,
    getNamespace
} from '../../redux/selectors';
import AlertForm from '../AlertForm';
import AlertMap from '../AlertMap';
import AnalystNotes from '../AnalystNotes';
import ArchiveAlertButton from '../Buttons/alerts/ArchiveAlertButton';
import PublishAlertButton from '../Buttons/alerts/PublishAlertButton';
import RejectAlertButton from '../Buttons/alerts/RejectAlertButton';
import ReviewAlertButton from '../Buttons/alerts/ReviewAlertButton';
import UpdateAlertButton from '../Buttons/alerts/UpdateAlertButton';

const { ADMINISTRATOR, LEAD_ANALYST, READ_ONLY } = ROLES;

export default function AlertDetailsModal() {
    // State
    const [alertDetails, setAlertDetails] = useState(false);
    const [analystNote, setAnalystNote] = useState('');
    const [modalType, setModalType] = useState(false);
    const [buttonsToShow, setButtonsToShow] = useState({
        archive: false,
        pass: false,
        publish: false,
        reject: false,
        update: false
    });
    // Redux
    const alertModal = useSelector(getAlertModal);
    const authInfo = useSelector(getAuthInfo);
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );
    const dispatch = useDispatch();
    // Refs
    const prevAlertModal = usePrevious(alertModal);

    function closeAlertModal() {
        // Don't get rid of this timeout - we need it because TinyMCE gets into a race condition.
        // For some reason it tries to interact with the editor boxes in the modal
        // and if we close it too fast we get an explosion.
        setTimeout(() => {
            dispatch(setAlertModal({ alert: false }));
        }, 250);
    }

    function cancelEditMode(alert) {
        dispatch(
            setAlertModal({
                // We need to spread out the current alertModal, bc we only want to
                //  overwrite the specific values below and maintain all the others
                //  (esp isUpdate) as they are
                ...alertModal,
                alert: alert,
                editMode: false
            })
        );
    }

    function clearModal() {
        setAlertDetails(false);
        setButtonsToShow({
            archive: false,
            pass: false,
            publish: false,
            reject: false,
            update: false
        });
        setModalType(false);
        setAnalystNote('');
    }

    function cancelEdits() {
        cancelEditMode(alertModal.id);
    }

    function submitEdits(data) {
        // Let's make sure that we strip out any "_rejected" string
        let status = _(alertDetails.status)
            .capitalize()
            .replace('_rejected', '');
        data.id = alertModal.id;
        threatsNamespace.emit(`edit${status}${modalType}`, data);
        closeAlertModal();
    }

    function submitDraft(data) {
        // Let's make sure that we strip out any "_rejected" string
        let status = _(alertDetails.status)
            .capitalize()
            .replace('_rejected', '');
        data.id = alertModal.id;
        threatsNamespace.emit(`draft${status}${modalType}`, data);
        closeAlertModal();
    }

    useEffect(() => {
        // When the id changes, load details
        if (prevAlertModal?.id === false && alertModal.id !== false) {
            const modalType = alertModal.isUpdate ? 'Update' : 'Alert';
            threatsNamespace.once(
                `alertDetails_${alertModal.id}`,
                didLoadAlertDetails
            );
            threatsNamespace.emit(
                isNaN(alertModal.id)
                    ? `getAlertDetails`
                    : `get${modalType}DetailsById`,
                alertModal.id
            );
        }

        // When editMode is toggled, load details
        if (!prevAlertModal?.editMode && alertModal.editMode) {
            didLoadAlertDetails(alertDetails);
        }
    }, [alertModal]);

    function didLoadAlertDetails(details) {
        if (details !== undefined && details !== null) {
            let buttonsToShow =
                authInfo.role === READ_ONLY || details.status === 'ARCHIVED'
                    ? {}
                    : {
                          review:
                              details.source_id === '1' && !alertModal.editMode,
                          archive:
                              details.status &&
                              details.status !== 'DRAFT' &&
                              details.status !== 'DRAFT_REJECTED' &&
                              details.status !== 'PENDING' &&
                              details['source_id'] !== '1',
                          publish:
                              details.status &&
                              details.status === 'PENDING' &&
                              (authInfo.role === LEAD_ANALYST ||
                                  authInfo.role === ADMINISTRATOR) &&
                              details['source_id'] !== '1',
                          reject:
                              details.status &&
                              details.status === 'PENDING' &&
                              (authInfo.role === LEAD_ANALYST ||
                                  authInfo.role === ADMINISTRATOR),
                          update:
                              (details.creatorId &&
                                  details['source_id'] !== '1' &&
                                  details.creatorId === authInfo.user.id) ||
                              details['source_id'] === 'IC' ||
                              _.isEmpty(details['source_id'])
                      };
            setButtonsToShow(buttonsToShow);
            setAlertDetails(details);
            setModalType(alertModal.isUpdate ? 'Update' : 'Alert');
            setAnalystNote(details.analystNote ? details.analystNote.note : '');
        }
    }

    function alertUrgency() {
        let urgencyLabel = '';
        URGENCIES.forEach((urgency) => {
            if (urgency.value === parseInt(alertDetails.urgency)) {
                urgencyLabel = urgency.label;
            }
        });
        return (
            <div
                className={`AlertDetails-urgency AlertUrgency-${urgencyLabel}`}
                data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.URGENCY}-${alertModal.id}`}
            >
                {urgencyLabel}
            </div>
        );
    }

    function displayLoadingStatus() {
        return (
            <div className="IntelCenter-loader">
                <i className="fa fa-spinner fa-spin" />
            </div>
        );
    }

    function renderAlertDates() {
        // For threats that haven't been published yet - we actually have `startDate` and `endDate` as timestamps
        let dateFormat = 'MMM D, Y h:mm A z';
        let startDate = alertDetails.startDate
            ? moment.unix(alertDetails.startDate)
            : moment(alertDetails.start_date);
        let endDate = alertDetails.endDate
            ? moment.unix(alertDetails.endDate)
            : moment(alertDetails.end_date);
        let nameOrEmail;
        // Our third party alerts do not have any creator name or email, so nameOrEmail will
        // remain null and is conditionally rendered
        if (alertDetails?.creator?.name || alertDetails?.creator?.email) {
            nameOrEmail =
                alertDetails?.creator?.name?.length > 0
                    ? alertDetails.creator.name
                    : alertDetails.creator.email;
        }
        return (
            <span>
                <div>
                    {nameOrEmail && (
                        <span>
                            <strong>Created By: </strong>
                            {nameOrEmail}
                        </span>
                    )}
                </div>
                <div>
                    <strong>From: </strong>
                    {startDate.format(dateFormat)} -{' '}
                    {endDate.format(dateFormat)}
                </div>
            </span>
        );
    }

    function renderAlertDetails() {
        if (alertDetails.details) {
            return (
                <div className="AlertDetails-section">
                    {alertDetails.details.map((detail, index) => (
                        <div key={`AlertDetails-section-${index}`}>
                            <strong>{detail.label}</strong>
                            {detail.value.map((text, valueIndex) => (
                                <div
                                    className="AlertDetails-value"
                                    key={`AlertDetails-value-${valueIndex}`}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                ></div>
                            ))}
                        </div>
                    ))}
                </div>
            );
        }
    }

    function updateTypeLabel() {
        if (alertDetails && alertDetails.updateType) {
            const updateType = _.capitalize(alertDetails.updateType);
            const draftStatus =
                alertDetails.status === 'DRAFT' ||
                alertDetails.status === 'DRAFT_REJECTED'
                    ? `${_(alertDetails.status)
                          .capitalize()
                          .replace('_rejected', '')} of `
                    : '';
            return (
                <div className="AlertDetails-update">
                    {`${draftStatus}${updateType} to a Published Alert`}
                </div>
            );
        }
    }

    function displayAlertDetails() {
        return (
            <div
                className="AlertDetails-Container"
                data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.MODAL}-${alertModal.id}`}
            >
                {alertModal.editMode ? (
                    <AlertForm
                        alert={alertDetails}
                        onDiscardAlert={cancelEdits}
                        onSendForApproval={submitEdits}
                        onSaveAsDraft={submitDraft}
                        modalCallback={alertModal.modalCallback}
                        closeAlertModal={closeAlertModal}
                    />
                ) : (
                    displayAlertDetailsInfo()
                )}
            </div>
        );
    }

    function saveNotes(note) {
        threatsNamespace.emit(`saveAnalystNotes`, {
            note,
            alert: alertDetails
        });
    }

    function displayAlertDetailsInfo() {
        const situationReport = _.filter(
            alertDetails.situationReports,
            (report) => report.deletedDate === null
        )[0];
        return (
            <div>
                <div className="AlertDetails-header">
                    <h1
                        data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.TITLE}-${alertModal.id}`}
                    >
                        {alertDetails.title}
                    </h1>
                    <div className="AlertDetails-tags">
                        {alertDetails.worldwide && (
                            <span
                                data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.WORLDWIDE}`}
                            >
                                <BiWorld
                                    className="AlertDetails-worldwide-icon"
                                    title={'Worldwide Impact'}
                                />
                            </span>
                        )}
                        <div
                            className="AlertDetails-category"
                            data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.CATEGORY}-${alertModal.id}`}
                        >
                            {alertDetails.new_category
                                ? alertDetails.new_category
                                : alertDetails.category}
                        </div>
                        <div
                            className="AlertDetails-sub-category"
                            data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.SUB_CATEGORY}-${alertModal.id}`}
                        >
                            {alertDetails.subCategory}
                        </div>
                        {alertUrgency()}
                        {situationReport && (
                            <div className="AlertDetails-ContentManagement">
                                <CgFileDocument
                                    className="AlertDetails-ContentManagement-icon"
                                    title={`Linked to Situation Report "${situationReport.title}"`}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {alertDetails && alertDetails.updateType ? (
                    <p>
                        {`Type of Update: ${
                            _.find(
                                UPDATETYPES,
                                (type) => type.value === alertDetails.updateType
                            ).label
                        }`}
                    </p>
                ) : (
                    ''
                )}
                <Container>
                    <Row>
                        <Col>
                            <div className="AlertDetails-section">
                                {renderAlertDates()}
                            </div>
                            <div
                                className="AlertDetails-section"
                                data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.MESSAGE}-${alertModal.id}`}
                            >
                                <div
                                    key={`alertDetails_text`}
                                    dangerouslySetInnerHTML={{
                                        __html: alertDetails.message
                                    }}
                                />
                            </div>
                            {renderAlertDetails()}
                            <Row>
                                <div className="AnalystNotes-alert-details">
                                    Notes:
                                    <AnalystNotes
                                        analystNote={analystNote}
                                        closeModal={closeAlertModal}
                                        existingNote={_.get(
                                            alertDetails,
                                            'analystNote.note'
                                        )}
                                        id={`alert-details-analyst-notes${
                                            alertModal
                                                ? `-${alertModal.id}`
                                                : ''
                                        }`}
                                        saveNotes={saveNotes}
                                        showSave={!!alertDetails}
                                        updateNote={setAnalystNote}
                                    />
                                </div>
                            </Row>
                        </Col>
                        <Col>
                            <div
                                className="AlertDetails-map"
                                data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.MAP}-${alertModal.id}`}
                            >
                                <AlertMap
                                    alert={alertDetails}
                                    editMode={false}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    function displayAlertDetailsHeader() {
        return (
            <div>
                <button
                    className="ReactModal__CloseButton"
                    onClick={closeAlertModal}
                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.CLOSE_ALERT_DETAILS}-${alertDetails.id}`}
                >
                    <i className="fa fa-window-close" />
                </button>
                {alertDetails !== false ? (
                    <div>
                        {alertModal.isUpdate ? updateTypeLabel() : ''}
                        <div
                            className="AlertDetails-published"
                            data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.PUBLISHED_DATE}-${alertModal.id}`}
                        >
                            {alertDetails.published_date
                                ? `Published - ${moment(
                                      alertDetails.published_date
                                  ).format('MMM D, Y h:mm A z')}`
                                : `Last Updated - ${moment(
                                      alertDetails.updatedAt
                                  ).format('MMM D, Y h:mm A z')}`}
                        </div>
                        <div className="AlertDetails-action-buttons">
                            {!alertModal.editMode && buttonsToShow.archive ? (
                                <ArchiveAlertButton alert={alertDetails} />
                            ) : (
                                ''
                            )}
                            {!alertModal.editMode && buttonsToShow.publish ? (
                                <PublishAlertButton
                                    alert={alertDetails}
                                    isUpdate={alertModal.isUpdate}
                                />
                            ) : (
                                ''
                            )}
                            {!alertModal.editMode && buttonsToShow.reject ? (
                                <RejectAlertButton
                                    alert={alertDetails}
                                    isUpdate={alertModal.isUpdate}
                                />
                            ) : (
                                ''
                            )}
                            {!alertModal.editMode && buttonsToShow.update ? (
                                <UpdateAlertButton
                                    alert={alertDetails}
                                    isUpdate={alertModal.isUpdate}
                                />
                            ) : (
                                ''
                            )}
                            {buttonsToShow.review && (
                                <ReviewAlertButton
                                    alert={alertDetails}
                                    modalCallback={alertModal.modalCallback}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
    if (!alertModal.id) {
        return null;
    }
    return (
        <div>
            <Modal
                isOpen={alertModal.id !== false}
                contentLabel="Alert Details"
                ariaHideApp={false}
                onAfterOpen={clearModal}
                modalCallback={alertModal.modalCallback}
            >
                {displayAlertDetailsHeader()}
                {alertDetails === false
                    ? displayLoadingStatus()
                    : displayAlertDetails()}
            </Modal>
        </div>
    );
}
