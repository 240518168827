import _ from 'lodash';

import { MAP, ROLES } from '../../../config/constants';

const { RISK_OPTIONS } = MAP;
const { ANALYST, ADMINISTRATOR, LEAD_ANALYST } = ROLES;

// This is meant to be used for the select dropdowns
export const extractRiskOption = (value) => {
    let risk = null;
    RISK_OPTIONS.forEach((option) => {
        if (value === option.value) {
            risk = option;
        }
    });
    return risk;
};

// This is meant to be used any time you need to display a risk
export const translateRisk = (risk) => {
    return _.get(_.find(RISK_OPTIONS, ['value', risk]), 'label', 'N/A');
};

export const canDeleteMap = (role) => {
    return role === ADMINISTRATOR;
};

export const canDeletePendingMap = (role) => {
    return _.includes([ANALYST, LEAD_ANALYST, ADMINISTRATOR], role);
};
