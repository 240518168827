const COLUMN_VIEWED_CONFIG_STORAGE = 'td-column-viewed-config';
const COLUMN_UNVIEWED_CONFIG_STORAGE = 'td-column-unviewed-config';
const viewedItemConfig =
    JSON.parse(localStorage.getItem(COLUMN_VIEWED_CONFIG_STORAGE)) || {};
const unviewedItemConfig =
    JSON.parse(localStorage.getItem(COLUMN_UNVIEWED_CONFIG_STORAGE)) || {};

let userId = 'default';

let viewedConfigurations = new Set();
let unviewedConfigurations = new Set();

// the default configuration for a column will be everything
// so if a column is NOT in viewed/unviewed, that means it's in the default state
export function checkColumnConfig(id) {
    const showViewed = !viewedConfigurations.has(id);
    const showUnviewed = !unviewedConfigurations.has(id);

    return { showViewed, showUnviewed };
}
export function initColumnConfig(id) {
    userId = id;

    viewedConfigurations = new Set(viewedItemConfig[userId]);
    unviewedConfigurations = new Set(unviewedItemConfig[userId]);
}

export function toggleViewedConfig(id) {
    // if it is in the list that means they are filtering out those items
    if (viewedConfigurations.has(id)) {
        viewedConfigurations.delete(id);
    } else {
        viewedConfigurations.add(id);
        // don't let the user filter out all feed items
        if (unviewedConfigurations.has(id)) {
            unviewedConfigurations.delete(id);
        }
    }

    saveColumnConfig();
}

export function toggleUnviewedConfig(id) {
    // if it is in the list that means they are filtering out those items
    if (unviewedConfigurations.has(id)) {
        unviewedConfigurations.delete(id);
    } else {
        unviewedConfigurations.add(id);
        // don't let the user filter out all feed items
        if (viewedConfigurations.has(id)) {
            viewedConfigurations.delete(id);
        }
    }
    saveColumnConfig();
}

// save readItemsColumnConfig
function saveColumnConfig() {
    // because toggling one can affect the other, we need to save both
    viewedItemConfig[userId] = [...viewedConfigurations];
    localStorage.setItem(
        COLUMN_VIEWED_CONFIG_STORAGE,
        JSON.stringify(viewedItemConfig)
    );

    unviewedItemConfig[userId] = [...unviewedConfigurations];
    localStorage.setItem(
        COLUMN_UNVIEWED_CONFIG_STORAGE,
        JSON.stringify(unviewedItemConfig)
    );
}
