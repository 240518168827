import { useEffect, useRef, useState } from 'react';

/*
  This function is primarily for cases where you need a variable that forces re-
    renders but you also need to access its current value inside an event listener.
    If updates to a value do NOT necessitate re-renders, it would be preferable
    to simply useRef() instead of creating a state variable.
*/
export function useRefState(initialValue) {
    const [val, setVal] = useState(initialValue);
    const ref = useRef(initialValue);
    ref.current = val;
    return [val, ref, setVal];
}

/*
  This function is primarily for cases where you need to access the previous
    value of a state var in a context other than when it is being set (for instance,
    checking if a state var has changed since last render during a useEffect).
*/
export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
