import find from 'lodash/find';
import { BsRssFill, BsTwitter } from 'react-icons/bs';

import GATORLogo from '../assets/logo_gator.svg';
import AlertPage from '../assets/td_source_alertpage.png';
import NewsPush from '../assets/td_source_newsapp.png';

const { GOOGLE_MAPS_KEY } = require('../../src/config/google.config');

export const REGIONS = [
    { label: 'Africa', value: 5 },
    { label: 'APAC', value: 4 },
    { label: 'Europe', value: 3 },
    { label: 'Middle East', value: 6 },
    { label: 'South & Central America', value: 7 },
    { label: 'US East', value: 1 },
    { label: 'US West', value: 2 }
];

export const URGENCIES = [
    { label: 'LOW', value: 1 },
    { label: 'MEDIUM', value: 2 },
    { label: 'HIGH', value: 3 }
];

export const VIEW_MY_DRAFTS = 'VIEW_MY_DRAFTS';
export const VIEW_ALL_DRAFTS = 'VIEW_ALL_DRAFTS';
export const DRAFT_AUTHOR_DISPLAY_OPTIONS = [
    { label: 'View My Drafts', value: VIEW_MY_DRAFTS },
    { label: 'View All Drafts', value: VIEW_ALL_DRAFTS }
];

export const UPDATETYPES = [
    { label: "Update - Don't send alert", value: 'EDIT' },
    { label: 'Update - Additional information', value: 'UPDATE' },
    {
        label: 'Correction - Previous information was incorrect',
        value: 'CORRECTION'
    }
];

export const ROLES = {
    ADMINISTRATOR: 'Administrator',
    ANALYST: 'Analyst',
    LEAD_ANALYST: 'Lead-Analyst',
    READ_ONLY: 'Read-Only',
    LABELER: 'Labeler'
};

export const MODALS = {
    // IC
    ALERT_FILTERS: 'ALERT_FILTERS',
    // TD
    FEED_ITEM_REVIEW: 'FEED_ITEM_REVIEW',
    EVENT_DETAILS: 'EVENT_DETAILS',
    EVENT_FILTERS: 'EVENT_FILTERS'
};

export const USER_ROLES = [
    { label: 'Lead Analyst', value: ROLES.LEAD_ANALYST },
    { label: 'Analyst', value: ROLES.ANALYST },
    { label: 'Read Only', value: ROLES.READ_ONLY },
    { label: 'Administrator', value: ROLES.ADMINISTRATOR },
    { label: 'Labeler', value: ROLES.LABELER }
];

export const MAP_DEFAULTS = {
    bootstrapURLKeys: {
        key: GOOGLE_MAPS_KEY,
        v: '3',
        libraries: ['places', 'drawing', 'visualization']
    },
    defaultCenter: {
        lat: 30.30768,
        lng: -97.89348
    },
    defaultZoom: 6,
    options: {
        gestureHandling: 'greedy',
        fullscreenControl: false
    }
};

export const MAP_DARK_STYLE = {
    styles: [
        {
            featureType: 'all',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    saturation: 36
                },
                {
                    color: '#000000'
                },
                {
                    lightness: 40
                }
            ]
        },
        {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    visibility: 'on'
                },
                {
                    color: '#000000'
                },
                {
                    lightness: 16
                }
            ]
        },
        // {
        //     featureType: 'all',
        //     elementType: 'labels.icon',
        //     stylers: [
        //         {
        //             visibility: 'off'
        //         }
        //     ]
        // },
        {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 20
                }
            ]
        },
        {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 17
                },
                {
                    weight: 1.2
                }
            ]
        },
        // {
        //     featureType: 'administrative',
        //     elementType: 'labels',
        //     stylers: [
        //         {
        //             visibility: 'off'
        //         }
        //     ]
        // },
        // {
        //     featureType: 'administrative.country',
        //     elementType: 'all',
        //     stylers: [
        //         {
        //             visibility: 'simplified'
        //         }
        //     ]
        // },
        // {
        //     featureType: 'administrative.country',
        //     elementType: 'geometry',
        //     stylers: [
        //         {
        //             visibility: 'simplified'
        //         }
        //     ]
        // },
        // {
        //     featureType: 'administrative.country',
        //     elementType: 'labels.text',
        //     stylers: [
        //         {
        //             visibility: 'simplified'
        //         }
        //     ]
        // },
        // {
        //     featureType: 'administrative.province',
        //     elementType: 'all',
        //     stylers: [
        //         {
        //             visibility: 'off'
        //         }
        //     ]
        // },
        {
            featureType: 'administrative.locality',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified'
                },
                {
                    saturation: '-100'
                },
                {
                    lightness: '30'
                }
            ]
        },
        // {
        //     featureType: 'administrative.neighborhood',
        //     elementType: 'all',
        //     stylers: [
        //         {
        //             visibility: 'off'
        //         }
        //     ]
        // },
        // {
        //     featureType: 'administrative.land_parcel',
        //     elementType: 'all',
        //     stylers: [
        //         {
        //             visibility: 'off'
        //         }
        //     ]
        // },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified'
                },
                {
                    gamma: '0.00'
                },
                {
                    lightness: '74'
                }
            ]
        },
        {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 20
                }
            ]
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'all',
            stylers: [
                {
                    lightness: '3'
                }
            ]
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 21
                }
            ]
        },
        // {
        //     featureType: 'road',
        //     elementType: 'geometry',
        //     stylers: [
        //         {
        //             visibility: 'simplified'
        //         }
        //     ]
        // },
        {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 17
                }
            ]
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 29
                },
                {
                    weight: 0.2
                }
            ]
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 18
                }
            ]
        },
        {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 16
                }
            ]
        },
        {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 19
                }
            ]
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#000000'
                },
                {
                    lightness: 17
                }
            ]
        }
    ]
};

export const MAP = {
    RISK_OPTIONS: [
        { value: null, label: 'N/A' },
        { value: 1, label: '1 - Normal' },
        { value: 2, label: '2 - Caution' },
        { value: 3, label: '3 - Avoid' },
        { value: 4, label: '4 - Suspend' }
    ]
};

export const ALERT_STATUS = {
    DRAFT: 'DRAFT',
    DRAFT_REJECTED: 'DRAFT_REJECTED',
    PENDING: 'PENDING',
    PUBLISHED: 'PUBLISHED',
    ARCHIVED: 'ARCHIVED'
};

export const MULTISELECT_COMPONENT_STYLES = {
    inputField: { color: 'white' },
    searchBox: {
        height: '10px',
        cursor: 'pointer',
        backgroundColor: '#687B81',
        border: '1px solid #687B81',
        color: 'white',
        margin: '5px'
    },
    chips: { padding: 0, color: 'white', fontSize: 'inherit' }
};

export const THREAT_DECK_MULTISELECT_COMPONENT_STYLES = {
    inputField: { color: 'black' },
    searchBox: {
        height: '10px',
        cursor: 'pointer',
        backgroundColor: 'white',
        border: '1px solid #687B81',
        borderRadius: '10px',
        color: 'black',
        margin: '5px 0'
    },
    optionContainer: {
        color: 'black',
        backgroundColor: 'white'
    },
    chips: { padding: 0, color: 'black', fontSize: 'inherit' }
};

export const NAMESPACES = {
    BRIEFS_NAMESPACE: '/travel_briefs',
    CHAT_NAMESPACE: '/chat',
    SITUATIONS_NAMESPACE: '/situation_reports',
    THREATDECK_NAMESPACE: '/threat_deck',
    THREATS_NAMESPACE: '/threats',
    USERS_NAMESPACE: '/users'
};

export const VIEWS = {
    CREATE_VIEW: 'createView',
    DRAFTS_VIEW: 'draftsView',
    CHAT_VIEW: 'chatView',
    READ_ONLY: 'readOnlyView'
};

export const CONTENT_MANAGEMENT = {
    STATUS_ACTIVE: 'ACTIVE',
    STATUS_INACTIVE: 'INACTIVE',
    STATUS_DRAFT: 'DRAFT',
    STATUS_PENDING: 'PENDING',
    STATUS_ALL: 'ALL'
};

export const TRAVEL_BRIEFS = {
    SECTION_TYPES: [
        'Travel Grade',
        'Safety',
        'Culture',
        'Important Things to Know',
        'Contacts and Locations'
    ]
};

export const CONTENT_MANAGEMENT_STATUS_OPTIONS = {
    ['Situation Reports']: [
        { label: 'All', value: null },
        { label: 'Active', value: CONTENT_MANAGEMENT.STATUS_ACTIVE },
        { label: 'Inactive', value: CONTENT_MANAGEMENT.STATUS_INACTIVE },
        { label: 'Draft', value: CONTENT_MANAGEMENT.STATUS_DRAFT },
        { label: 'Pending', value: CONTENT_MANAGEMENT.STATUS_PENDING }
    ],
    ['Travel Briefs']: [
        { label: 'All', value: null },
        { label: 'Active', value: CONTENT_MANAGEMENT.STATUS_ACTIVE },
        { label: 'Draft', value: CONTENT_MANAGEMENT.STATUS_DRAFT },
        { label: 'Pending', value: CONTENT_MANAGEMENT.STATUS_PENDING }
    ]
};

export const APPS = {
    TD: {
        name: 'TD',
        label: 'GATOR',
        logo: GATORLogo
    },
    IC: {
        name: 'IC',
        label: 'Intelligence Center'
    }
};

export const TWITTER_RULES = {
    FOLLOWED_ACCOUNTS: 'followed accounts',
    FOLLOWED_ACCOUNTS_PSEUDO_RULE: {
        tag: 'followed accounts',
        active: true
    }
};

// Icons for Threat Deck sources
export const THREAT_DECK_SOURCE_ICONS = {
    ALERTPAGE: {
        icon: (
            <img
                src={AlertPage}
                className="ThreatDeckCard-source-icon-alertpage"
            />
        ),
        label: 'ALERTPAGE'
    },
    NAP: {
        icon: (
            <img
                src={NewsPush}
                className="ThreatDeckCard-source-icon-newsapp"
            />
        ),
        label: 'NAP'
    },
    RSS: {
        icon: <BsRssFill className="ThreatDeckCard-source-icon-rss" />,
        label: 'RSS'
    },
    TWITTER: {
        icon: <BsTwitter className="ThreatDeckCard-source-icon-twitter" />,
        label: 'TWITTER'
    }
};

export const MINUTES_IN_MS = (minutes) => minutes * 60 * 1000;

export const CAPABILITIES = {
    ANALYST_CHAT: 'analyst_chat',
    CUSTOM_MAPS: 'custom_maps',
    EVENT_GROUPING: 'event_grouping',
    SITUATION_REPORTS: 'situation_reports',
    TRAVEL_BRIEFS: 'travel_briefs'
};

export const COLUMNS = {
    CONTENT_HEIGHT: {
        EVENTS: 132,
        FEED_ITEMS: 340
    },
    CONTENT_TYPE: {
        EVENTS: 'EVENTS',
        FEED_ITEMS: 'FEED_ITEMS'
    },
    FILTERS: {
        FEED_ITEMS: {
            CATEGORY: (item, column) => {
                return !find(column.categories, {
                    label:
                        item.annotation.ui_category ||
                        item.annotation.ml_category
                });
            },
            REGION: (item, column) => {
                return !find(column.regions, {
                    id:
                        item.annotation.ui_region ||
                        item.annotation.ml_region ||
                        1 // "Unknown" region
                });
            }
        },
        EVENTS: {
            CATEGORY: (event, column) => {
                return !find(column.categories, { label: event.category });
            },
            REGION: (event, column) => {
                return !find(column.regions, { id: event.regionId });
            }
        }
    },
    TYPE: {
        USER: 'USER',
        GROUP: 'GROUP'
    },
    VALIDATION: {
        CONSTRAINTS: {
            name: {
                presence: {
                    allowEmpty: false,
                    message: '^Columns must have a title.'
                }
            },
            regions: {
                presence: {
                    allowEmpty: false,
                    message: '^Columns must have at least one region.'
                }
            },
            categories: {
                presence: {
                    allowEmpty: false,
                    message: '^Columns must have at least one category.'
                }
            },
            confidenceFloor: {
                presence: {
                    allowEmpty: false,
                    message: '^Columns must have a minimum confidence.'
                },
                equality: {
                    attribute: 'confidenceCeiling',
                    message:
                        '^Minimum confidence must be less than or equal to the maximum confidence.',
                    comparator: function (v1, v2) {
                        return parseInt(v1) <= parseInt(v2);
                    }
                },
                type: 'integer'
            },
            confidenceCeiling: {
                presence: {
                    allowEmpty: false,
                    message: '^Columns must have a maximum confidence.'
                },
                equality: {
                    attribute: 'confidenceFloor',
                    message:
                        '^Maximum confidence must be greater than or equal to the minimum confidence.',
                    comparator: function (v1, v2) {
                        return parseInt(v1) >= parseInt(v2);
                    }
                },
                type: 'integer'
            },
            contentType: {
                presence: {
                    allowEmpty: false,
                    message: '^Columns must have a content type.'
                }
            },
            sources: {
                presence: {
                    allowEmpty: false,
                    message: '^Columns must have at least one source.'
                }
            }
            // TODO: make this validation conditional on sources including TWITTER
            // twitterRules: {
            //     presence: {
            //         allowEmpty: false,
            //         message:
            //             '^Columns must have at least one rule if TWITTER is source.'
            //     }
            // }
        },
        FIELDS: {
            EVENTS: [
                'name',
                'categories',
                'contentType',
                'order',
                'regions',
                'type'
            ],
            FEED_ITEMS: [
                'name',
                'severity',
                'categories',
                'confidenceFloor',
                'confidenceCeiling',
                'contentType',
                'order',
                'regions',
                'sources',
                'type'
            ]
        }
    }
};

export const COLUMN_CONTENT_TYPES = [
    {
        label: 'Feed Items',
        value: 'FEED_ITEMS'
    },
    {
        label: 'Events',
        value: 'EVENTS'
    }
];
