import differenceBy from 'lodash/differenceBy';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reject from 'lodash/reject';
import snakeCase from 'lodash/snakeCase';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
// import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getCategoriesList } from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';

export default function ThreatDeckColumnFormCategories({
    onChange,
    selectedCategories = []
}) {
    const allCategories = useSelector(getCategoriesList);
    const [currentlySelectedCategories, setCurrentlySelectedCategories] =
        useState(selectedCategories);

    function selectCategory(index) {
        const category = differenceBy(
            allCategories,
            currentlySelectedCategories,
            'label'
        )[index];

        let newCats;
        if (includes(currentlySelectedCategories, category)) {
            newCats = sortBy(
                currentlySelectedCategories.filter((c) => c !== category),
                'label'
            );
        } else {
            newCats = sortBy(
                [...currentlySelectedCategories, category],
                'label'
            );
        }

        setCurrentlySelectedCategories(newCats);
        onChange(newCats);
    }

    function deSelectCategory(index) {
        let category = currentlySelectedCategories[index];
        setCurrentlySelectedCategories(
            reject(currentlySelectedCategories, (c) => c === category)
        );
        onChange(reject(currentlySelectedCategories, (c) => c === category));
    }

    function selectAllCategories() {
        setCurrentlySelectedCategories(allCategories);
        onChange(allCategories);
    }

    function removeAllCategories() {
        setCurrentlySelectedCategories([]);
        onChange([]);
    }

    return (
        <div className="ThreatDeck-input-container">
            <div className="ThreatDeck-row ThreatDeck-categories-row">
                <div className="ThreatDeck-column">
                    <div className={'ThreatDeck-categories-header'}>
                        Available Categories:
                    </div>
                    <div className="ThreatDeck-categories-container">
                        {differenceBy(
                            allCategories,
                            currentlySelectedCategories,
                            'label'
                        ).map((category, index) => {
                            return (
                                <div
                                    className="ThreatDeck-category"
                                    data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN_CONFIG.CATEGORIES.AVAILABLE}-${category?.label}`}
                                    key={`${snakeCase(
                                        category?.label
                                    )}-${index}`}
                                    onClick={() => {
                                        selectCategory(index);
                                    }}
                                >
                                    {category?.label}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="ThreatDeck-column ThreatDeck-carets">
                    <IoChevronForward />
                    <IoChevronBack />
                </div>
                <div className="ThreatDeck-column">
                    <div className={'ThreatDeck-categories-header'}>
                        Selected Categories:
                    </div>
                    <div className="ThreatDeck-categories-container">
                        {map(currentlySelectedCategories, (category, index) => {
                            return (
                                <div
                                    className="ThreatDeck-category"
                                    data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN_CONFIG.CATEGORIES.SELECTED}-${category?.label}`}
                                    key={`${snakeCase(
                                        category.label
                                    )}-${index}`}
                                    onClick={() => {
                                        deSelectCategory(index);
                                    }}
                                >
                                    {category.label}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="ThreatDeck-categories-buttons">
                <ICButton
                    color="white"
                    onClick={selectAllCategories}
                    className="ThreatDeck-categories-button"
                    data-testid={
                        TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN_CONFIG
                            .CATEGORIES_BUTTONS.SELECT_ALL
                    }
                >
                    Select All
                </ICButton>
                <ICButton
                    color="white"
                    onClick={removeAllCategories}
                    className="ThreatDeck-categories-button"
                    data-testid={
                        TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN_CONFIG
                            .CATEGORIES_BUTTONS.REMOVE_ALL
                    }
                >
                    Remove All
                </ICButton>
            </div>
        </div>
    );
}
