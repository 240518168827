import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
    CookieStorage
} from 'amazon-cognito-identity-js';

import { AuthConfig } from './config/auth.config';

const AuthCallbacks = (resolve, reject) => ({
    onFailure: (error) => {
        console.log('reject error: ', { error });
        reject(error);
    },
    onSuccess: (session, userConfirmationNecessary) => {
        resolve(session);
    },
    newPasswordRequired: (attributesGiven, attributesRequired) => {
        console.log('New password required.');

        // we resolve instead of reject, because we will handle the new password flow in the UI
        resolve({
            challengeName: 'NEW_PASSWORD_REQUIRED',
            attributesGiven,
            attributesRequired
        });
    }
});

// Preserving the user pool for reuse for other actions.
let userPool;
const getCognitoUserPool = async () => {
    if (!userPool) {
        const { userPoolId, userPoolWebClientId, endpoint } =
            await AuthConfig();
        const cognitoUserPool = new CognitoUserPool({
            UserPoolId: userPoolId,
            ClientId: userPoolWebClientId,
            endpoint
        });

        userPool = cognitoUserPool;
    }

    return userPool;
};

export const getCookieStorage = async () => {
    const { cookieStorage } = await AuthConfig();
    const storage = new CookieStorage(cookieStorage);
    console.log('storage: ', storage);
    return storage;
};

// Only the first user retrieved in this manner has Session in the payload.
// a user WITH Session is needed for various other actions, such as resetting
// passwords during the NEW_PASSWORD_REQUIRED flow.
// We'll hold onto the the user here unless the username changes.
let user;
const getCognitoUser = async (userPool, username) => {
    if (!user || user.username !== username) {
        const { authenticationFlowType } = await AuthConfig();
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool
        });
        cognitoUser.setAuthenticationFlowType(authenticationFlowType);
        user = cognitoUser;
    }

    return user;
};

export const signIn = async (username, password) => {
    const { authenticationFlowType } = await AuthConfig();

    const userPool = await getCognitoUserPool();
    const cognitoUser = await getCognitoUser(userPool, username);

    try {
        let cognitoUserSession = await new Promise((resolve, reject) =>
            cognitoUser.authenticateUser(
                new AuthenticationDetails({
                    authenticationFlowType,
                    Username: username,
                    Password: password
                }),
                AuthCallbacks(resolve, reject)
            )
        );

        return cognitoUserSession;
    } catch (error) {
        console.log('Failed to authenticate user: ', error);
        throw error;
    }
};

export const getCurrentSession = async () => {
    const userPool = await getCognitoUserPool();
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        try {
            let cognitoUserSession = await new Promise((resolve, reject) =>
                cognitoUser.getSession((error, session) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(session);
                    }
                })
            );

            return cognitoUserSession;
        } catch (error) {
            console.log('Failed to get current session: ', error);
            throw error;
        }
    }
};

export const refreshCurrentSession = async () => {
    const userPool = await getCognitoUserPool();

    const cognitoUser = userPool.getCurrentUser();
    const currentSession = cognitoUser.getSession();

    if (cognitoUser) {
        try {
            let cognitoUserSession = await new Promise((resolve, reject) =>
                cognitoUser.refreshSession(
                    { refreshToken: currentSession.refreshToken.token },
                    (error, session) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(session);
                        }
                    }
                )
            );

            return cognitoUserSession;
        } catch (error) {
            console.log('Failed to refresh session: ', error);
            throw error;
        }
    }
};

export const signOut = async () => {
    const userPool = await getCognitoUserPool();

    const cognitoUser = userPool.getCurrentUser();
    console.log('signing out cognitoUser: ', cognitoUser);

    if (cognitoUser) {
        cognitoUser.signOut();
    }
};

export const resetPassword = async (userEmail, newPassword) => {
    try {
        const userPool = await getCognitoUserPool();
        const cognitoUser = await getCognitoUser(userPool, userEmail);

        await new Promise((resolve, reject) =>
            cognitoUser.completeNewPasswordChallenge(
                newPassword,
                null,
                AuthCallbacks(resolve, reject)
            )
        );
        console.log('Completed new password challenge');
    } catch (error) {
        console.log('Failed to reset password: ', error);
        throw error;
    }
};

export const forgotPassword = async (user) => {
    const userPool = await getCognitoUserPool();
    const cognitoUser = await getCognitoUser(userPool, user);

    try {
        await new Promise((resolve, reject) =>
            cognitoUser.forgotPassword(AuthCallbacks(resolve, reject))
        );
    } catch (error) {
        console.log('Failed to handle forgotten password: ', error);
        throw error;
    }
};

export const forgotPasswordVerificationSubmit = async (
    user,
    verificationCode,
    newPassword
) => {
    const userPool = await getCognitoUserPool();
    const cognitoUser = await getCognitoUser(userPool, user);

    try {
        await new Promise((resolve, reject) =>
            cognitoUser.confirmPassword(
                verificationCode,
                newPassword,
                AuthCallbacks(resolve, reject)
            )
        );
    } catch (error) {
        console.log('Failed to reset password: ', error);
        throw error;
    }
};
