import React from 'react';
import { Outlet } from 'react-router-dom';

import { MODALS } from '../config/constants';
import AlertDetailsModal from './Modals/AlertDetailsModal';
import FiltersModal from './Modals/FiltersModal';
import Header from './shared/Header';

export default function IntelCenter() {
    return (
        <div className="IntelCenter-container">
            <AlertDetailsModal />
            <FiltersModal modalType="alert" modalName={MODALS.ALERT_FILTERS} />
            <Header />
            <Outlet />
        </div>
    );
}
