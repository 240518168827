const READ_ITEMS_STORAGE = 'td-read-items';
const readItems = JSON.parse(localStorage.getItem(READ_ITEMS_STORAGE)) || {};
let userId = 'default';
let userReadItems = new Set();

// Remove any ids from read state storage that
// aren't in the array of activeIds
export function cleanReadItems(activeIds = []) {
    userReadItems.forEach((readId) => {
        if (!activeIds.includes(readId)) {
            userReadItems.delete(readId);
        }
    });
    saveReadItems();
}

// Completely clear read state storage
export function clearReadItems() {
    userReadItems.clear();
    saveReadItems();
}

// Initialize read state storage
export function initReadItems(id) {
    // Defensive check to prevent all of this from becoming more complicated,
    // but there shouldn't ever be a case where there's no id
    userId = id;

    userReadItems = new Set(readItems[userId]);
}

// Add the id to read state storage, marking it as read
export function markItemAsRead(id) {
    userReadItems.add(id);
    saveReadItems();
}

// Remove the id from read state storage, marking it as unread
export function markItemAsUnread(id) {
    userReadItems.delete(id);
    saveReadItems();
}

// Check to see if the given id is in read state storage
export function isItemRead(id) {
    return userReadItems.has(id);
}

// Save readItems
function saveReadItems() {
    readItems[userId] = [...userReadItems];
    localStorage.setItem(READ_ITEMS_STORAGE, JSON.stringify(readItems));
}
