exports.TEST_ATTRIBUTES = {
    ACTIVE_ALERT: {
        ACTIVE_ALERT_ARCHIVE: 'activeList-button-archive'
    },
    EXPIRING_ALERT: {
        EXPIRING_ALERT_ARCHIVE: 'expiringList-button-archive'
    },
    ALERT_DETAILS: {
        CATEGORY: 'alert-details-category',
        SUB_CATEGORY: 'alert-details-sub-category',
        MAP: 'alert-details-map',
        MESSAGE: 'alert-details-message',
        MODAL: 'alert-details-modal',
        PUBLISHED_DATE: 'alert-details-published-date',
        TITLE: 'alert-details-title',
        URGENCY: 'alert-details-urgency',
        WORLDWIDE: 'alert-details-worldwide'
    },
    ALERT_FORM: {
        DISCARD: 'alert-form-discard',
        HEADLINE: 'alert-form-headline',
        MAP_DELETE_POLYGON: 'alert-form-map-delete-polygon',
        MAP_CREATE_BOX_POLYGON: 'alert-form-map-create-box-polygon',
        MAP_CREATE_CIRCLE_POLYGON: 'alert-form-map-create-circle-polygon',
        MAP_SAVED_POLYGON: 'alert-form-map-saved-polygon',
        MAP_SAVED_POLYGON_SEARCH: 'alert-form-map-saved-polygon-search',
        MAP_SAVED_POLYGON_SEARCH_CLOSE:
            'alert-form-map-saved-polygon-search-close',
        MAP_SEARCH_BOX: 'alert-form-map-search-box',
        RADIUS_MILES_INPUT: 'alert-form-map-radius-miles-input',
        SAVE_DRAFT: 'alert-form-save-draft',
        SEND_FOR_APPROVAL: 'alert-form-send-for-approval',
        WORLDWIDE: 'alert-form-worldwide-checkbox',
        MAP_IS_VISIBLE: 'alert-form-map-presence'
    },
    ALERT_LIST: 'alert-list',
    ALERT_PANEL: {
        ANALYST_NOTES: 'alert-form-analyst-notes',
        AUTHOR: 'alert-panel-author',
        BOX: 'alert-panel-box',
        BUTTONS: 'alert-panel-buttons',
        LABELS: 'alert-panel-labels',
        TIMESTAMP: 'alert-panel-timestamp',
        TITLE: 'alert-panel-title',
        URGENCY: 'alert-panel-urgency',
        WORLDWIDE: 'alert-panel-worldwide'
    },
    ANALYST_NOTES: 'alert-analyst-notes',
    BUTTONS: {
        ADD_TO_CONTENT: 'button-add-alert-to-content',
        ALERT_FILTER: 'button-alert-filter',
        FILTER_SAVE: 'button-filter-save',
        ARCHIVE: 'button-archive',
        CLOSE_ALERT_DETAILS: 'button-close-alert-details',
        CLOSE_ALERT_FILTERS: 'button-close-alert-filters',
        CLOSE_SYSTEM_MODAL: 'button-close-system-modal',
        CLOSE_USER_DETAILS: 'button-close-user-details',
        DELETE_DRAFT: 'button-delete-draft',
        EDIT_USER: 'button-edit-user',
        EDIT_USER_MANAGEMENT: 'button-edit-user-user',
        LOGOUT: 'button-logout',
        MAP_CREATE: 'button-map-create',
        MAP_DELETE: 'button-map-delete',
        MAP_DELETE_PENDING: 'button-map-delete-pending',
        MAP_DISABLE: 'button-map-disable',
        MAP_ENABLE: 'button-map-enable',
        MAP_EDIT: 'button-map-edit',
        MAP_EDIT_PENDING: 'button-map-edit-pending',
        MAP_MODAL_CANCEL: 'button-map-modal-cancel',
        MAP_MODAL_DISABLE: 'button-map-modal-disable',
        MAP_MODAL_ENABLE: 'button-map-modal-enable',
        MAP_MODAL_EDIT: 'button-map-modal-edit',
        MAP_MODAL_DELETE: 'button-map-modal-delete',
        MAP_MODAL_SAVE: 'button-map-modal-save',
        MAP_MODAL_PUBLISH: 'button-map-modal-publish',
        MOVE_REGION: 'button-move-region',
        OPEN_SYSTEM_MODAL: 'button-open-system-modal',
        OPEN_USER_MODAL: 'button-open-user-modal',
        PASS: 'button-pass',
        PUBLISH: 'button-publish',
        REJECT: 'button-reject',
        REMOVE_FROM_SITUATION: 'button-remove-alert-from-content',
        REMOVE_USER_FROM_REGION: 'button-remove-user-from-region',
        REVIEW: 'button-review',
        SAVE_USER_DETAILS: 'button-save-user-details',
        UPDATE: 'button-update',
        SAVE_ANALYST_NOTES: 'button-save-analyst-notes'
    },
    CREATE_USER_FORM: {
        NAME: 'create-user-form-name',
        EMAIL: 'create-user-form-email',
        ROLE: 'create-user-form-role'
    },
    DRAFTS_VIEW: {
        BUTTONS: {
            VIEW_BUTTON: 'draft-view-button',
            VIEW_BUTTON_COUNT: 'draft-view-button-count'
        },
        FILTERS: {
            AUTHOR_FILTER: 'draft-author-filter'
        }
    },
    CHAT_VIEW: {
        BUTTONS: {
            CHAT_BOX: 'chat-box',
            CHAT_ATTACH_IMAGE: 'chat-attach-image',
            CHAT_ATTACH_VIDEO: 'chat-attach-video',
            CHAT_LOAD_MORE_MESSAGES: 'chat-load-more-messages',
            CHAT_NO_MORE_MESSAGES: 'chat-no-more-messages',
            CHAT_SEND: 'chat-send',
            VIEW_BUTTON: 'chat-view-button',
            VIEW_BUTTON_COUNT: 'chat-view-button-count'
        },
        CHAT_MESSAGE: {
            CHAT_BADGE: 'chat-badge',
            CHAT_MESSAGE: 'chat-message',
            CHAT_MESSAGE_AUTHOR: 'chat-message-author',
            CHAT_MESSAGE_COMPANY: 'chat-message-company',
            CHAT_MESSAGE_ICON: 'chat-message-icon',
            CHAT_MESSAGE_READ_BY: 'chat-message-read-by',
            CHAT_MESSAGE_REPLY_COUNT: 'chat-message-reply-count',
            CHAT_MESSAGE_TIME: 'chat-message-time'
        },
        CHAT_OPTIONS: {
            ALL_CHAT: 'chat-all-chat',
            UNREAD_CHAT: 'chat-unread-chat',
            DELETE_MESSAGE: 'chat-delete',
            MARK_AS_READ: 'chat-mark-as-read',
            OPEN_THREAD: 'chat-open-thread'
        },
        CHAT_REPLIES: {
            THREAD_BACK_BUTTON: 'thread-back-button'
        },
        CHAT_SECTION: {
            CHAT_SECTION_DATE: 'chat-section-date'
        }
    },
    CONTENT_MANAGEMENT: {
        ADD_NEW_SOURCE: 'content-form-add-new-source',
        ADD_NEW_ARTICLE: 'content-form-add-new-article',
        ALERT_SEARCH: 'content-form-alert-search',
        ADD_ALERT_TO_CONTENT: 'add-alert-to-content',
        ANALYST_NOTES: 'content-form-analyst-notes',
        ARTICLE_CONTENT: 'content-form-article-content',
        ARTICLE_DATE: 'content-form-article-date',
        ARTICLE_TITLE: 'content-form-article-title',
        ARTICLE_PINNED: 'content-form-article-pinned',
        ARTICLE_MEDIA: 'content-form-article-media',
        ARTICLE_MEDIA_ERROR: 'content-form-article-media-error',
        ARTICLE_MEDIA_SUCCESS: 'content-form-article-media-success',
        ARTICLE_MEDIA_THUMBNAIL: 'content-form-article-media-thumbnail',
        ARTICLE_MEDIA_REMOVE_THUMBNAIL:
            'content-form-article-media-remove-thumbnail',
        ARTICLE_MEDIA_LOADING: 'content-form-article-media-loading',
        UPDATE_DATE: 'content-form-update-date',
        CLOSE_ALERT_MODAL: 'content-form-close-alert-modal',
        CLOSE_SOURCE_MODAL: 'content-form-close-source-modal',
        CLOSE_ARTICLE_MODAL: 'content-form-close-article-modal',
        CLOSE_SECTION_MODAL: 'content-form-close-section-modal',
        CONTENT_TITLE: 'content-form-content-title',
        CONTENT_REMOVE_ALERT: 'remove-alert-from-content',
        DELETE_ARTICLE: 'content-form-delete-article',
        DISCARD: 'content-form-button-discard',
        EDIT_ARTICLE: 'content-form-edit-article',
        LIST_SELECTOR: {
            DESELECT: 'list-selector-deselect',
            SELECT: 'list-selector-select',
            SELECT_ALL: 'list-selector-select-all',
            REMOVE_ALL: 'list-selector-remove-all'
        },
        MARK_INACTIVE: 'content-form-button-inactive',
        PUBLISH: 'content-form-button-publish',
        REJECT: 'content-form-button-reject',
        REPORTS_LANDING_PAGE: {
            CREATE_NEW_BUTTON: 'content-management-create-new',
            STATUS_FILTER_DROPDOWN: 'content-management-status-filter-dropdown'
        },
        REPORTS_TABLE: {
            ROW: 'content-management-row',
            ROW_AUTHOR: 'content-management-row-author',
            ROW_DELETE_BUTTON: 'content-management-row-delete-button',
            ROW_EDIT_BUTTON: 'content-management-row-edit-button',
            ROW_STATUS: 'content-management-row-status',
            ROW_TITLE: 'content-management-row-title'
        },
        SAVE_DRAFT: 'content-form-save-draft',
        SECTION_FORM: {
            SECTION_TITLE: 'content-form-section-title'
        },
        SEND_FOR_APPROVAL: 'content-form-send-for-approval',
        SOURCE_OPTIONS: {
            DELETE_SOURCE: 'content-source-delete',
            EDIT_SOURCE: 'content-source-edit'
        },
        SOURCE_TITLE: 'content-form-source-title',
        SOURCE_URL: 'content-form-source-url'
    },
    CREATE_VIEW: {
        BUTTONS: {
            VIEW_BUTTON: 'create-view-button'
        }
    },
    ERROR_PAGE: 'error-page',
    LINKS: {
        MAP_VIEW: 'link-map-view',
        MAP_VIEW_PENDING: 'link-map-view-pending'
    },
    LOGIN_FORM: {
        EMAIL: 'login-form-email',
        CONFIRM_PASSWORD: 'login-form-confirm-password',
        PASSWORD: 'login-form-password',
        PASSWORD_RESET_CONFIRMATION_CODE:
            'login-form-password-reset-confirmation-code',
        RESET_PASSWORD_PASSWORD_FIELD: 'login-form-reset-password-password',
        RESET_PASSWORD_CONFIRM_PASSWORD_FIELD:
            'login-form-reset-password-confirm-password',
        SUBMIT: 'login-form-submit-button'
    },
    MAP: 'alert-map-loaded',
    MODALS: {
        MODAL: (modalName) => `modal-${modalName}`,
        CLOSE_MODAL: (modalName) => `modal-close-${modalName}`,
        TOGGLE_MODAL: (modalName) => `modal-toggle-${modalName}`,
        FILTERS_MODAL: {
            CATEGORY: (category) => `filters-modal-category-${category}`,
            REGIONS: (region) => `filters-modal-regions-${region}`
        }
    },
    RSS_SETTINGS: {
        BUTTONS: {
            CANCEL_RSS: 'td-rss-cancel',
            CREATE_RSS: 'td-rss-create',
            DELETE_RSS: 'td-rss-delete',
            EDIT_RSS: 'td-rss-edit',
            OPEN_RSS: 'td-rss-open',
            SAVE_RSS: 'td-rss-save',
            STATUS_RSS: 'td-rss-status'
        },
        FORM: {
            RSS_ERROR_MESSAGE: 'td-rss-error-message',
            RSS_REGION: 'td-rss-region',
            RSS_STATUS: 'td-rss-follow',
            RSS_TITLE: 'td-rss-handle',
            RSS_URL: 'td-rss-url'
        }
    },
    THREAT_DECK: {
        BUTTONS: {
            CREATE_NEW_COLUMN: 'td-create-new-column',
            REORDER_COLUMNS: 'td-reorder-columns',
            NEW_GROUP: 'td-new-group',
            VIEW_GROUP: 'td-view-group'
        },
        COLUMNS: {
            COLUMN: {
                TD_COLUMN: 'td-column',
                CONFIG: 'td-column-config',
                VIEWED: 'td-column-viewed',
                UNVIEWED: 'td-column-unviewed',
                NAME: 'td-column-name'
            },
            COLUMN_CONFIG: {
                BUTTONS: {
                    CANCEL: 'td-column-config-cancel',
                    CREATE: 'td-column-config-create',
                    DELETE: 'td-column-config-delete',
                    SAVE: 'td-column-config-save'
                },
                CATEGORIES: {
                    AVAILABLE: 'td-column-config-available-categories',
                    SELECTED: 'td-column-config-selected-categories'
                },
                CATEGORIES_BUTTONS: {
                    SELECT_ALL: 'td-column-config-categories-select-all',
                    REMOVE_ALL: 'td-column-config-categories-remove-all'
                },
                CLOSE_MODAL: 'td-column-config-close-modal',
                CONFIDENCE_FLOOR: 'td-column-config-confidence-floor',
                CONFIDENCE_CEILING: 'td-column-config-confidence-ceiling',
                COLUMN_NAME: 'td-column-config-column-name',
                CONTENT_TYPE: 'td-column-config-content-type',
                REGIONS: 'td-column-config-regions',
                REGIONS_BUTTONS: {
                    SELECT_ALL: 'td-column-config-regions-select-all',
                    REMOVE_ALL: 'td-column-config-regions-remove-all'
                },
                SEVERITY: {
                    LOW: 'td-column-config-severity-low',
                    MEDIUM: 'td-column-config-severity-medium',
                    HIGH: 'td-column-config-severity-high'
                },
                SOURCES: {
                    RSS: 'td-column-config-rss',
                    TWITTER: 'td-column-config-twitter',
                    NAP: 'td-column-config-nap',
                    ALERTPAGE: 'td-column-config-alertpage'
                },
                TWITTER_RULES: {
                    AVAILABLE: 'td-column-config-available-twitter-rules',
                    SELECTED: 'td-column-config-selected-twitter-rules'
                },
                GROUP: 'td-column-config-group'
            },
            TD_COLUMN: 'td-column'
        },
        CARDS: {
            CARD: 'td-card-card',
            STATUS: 'td-card-status',
            CATEGORY: 'td-card-category',
            CONFIDENCE: 'td-card-confidence',
            CONTENT: 'td-card-content',
            SWITCH: 'td-card-switch',
            BUTTONS: {
                REVIEW: 'td-card-review'
            },
            SOURCE: 'td-card-source',
            SOURCE_NAME: 'td-card-source-name',
            TIMESTAMP: 'td-card-timestamp',
            UNREAD: 'td-card-unread'
        },
        EVENTS: {
            EVENT: {
                TD_EVENT: 'td-event',
                TITLE: 'td-event-title',
                CATEGORY: 'td-event-category',
                FEED_ITEMS_COUNT: 'td-event-feed-items-count'
            }
        },
        GROUPS: {
            BUTTONS: {
                CREATE: 'td-group-create',
                SAVE: 'td-group-save',
                DELETE: 'td-group-delete',
                CANCEL: 'td-group-cancel',
                CREATE_COLUMN: 'td-group-create-column'
            },
            COLUMNS: {
                REMOVE_ALL: 'td-group-columns-remove-all',
                SELECT_ALL: 'td-group-columns-select-all'
            },
            GROUP: 'td-group',
            NAME: 'td-group-name'
        },
        USER_GROUPS: {
            BUTTONS: {
                CANCEL: 'td-user-groups-cancel',
                CLOSE_MODAL: 'td-user-groups-close-modal',
                SAVE: 'td-user-groups-save'
            },
            GROUP: 'td-user-group'
        },
        REVIEW: {
            BUTTONS: {
                CLOSE: 'td-review-close',
                CANCEL: 'td-review-cancel',
                CREATE_THREAT: 'td-review-create-threat',
                SAVE: 'td-review-save',
                SKIP: 'td-review-skip'
            },
            LOCATION: 'td-review-location',
            DETAILS: 'td-review-details'
        },
        TRI_SWITCH: {
            SWITCH: 'td-tri-switch',
            BUTTONS: {
                UNSET_LEFT: 'td-switch-unset-left',
                UNSET_RIGHT: 'td-switch-unset-right',
                LEFT: 'td-switch-left',
                RIGHT: 'td-switch-right'
            }
        }
    },
    TWITTER_SETTINGS: {
        BUTTONS: {
            CANCEL_ACCOUNT: 'td-twitter-account-cancel',
            CANCEL_RULE: 'td-twitter-rule-cancel',
            CREATE_ACCOUNT: 'td-twitter-account-create',
            CREATE_RULE: 'td-twitter-rule-create',
            DELETE_ACCOUNT: 'td-twitter-account-delete',
            DELETE_RULE: 'td-twitter-rule-delete',
            EDIT_ACCOUNT: 'td-twitter-account-edit',
            EDIT_RULE: 'td-twitter-rule-edit',
            OPEN_ACCOUNT: 'td-twitter-account-open',
            SAVE_ACCOUNT: 'td-twitter-account-save',
            SAVE_RULE: 'td-twitter-rule-save',
            STATUS_ACCOUNT: 'td-twitter-account-status',
            STATUS_RULE: 'td-twitter-account-status'
        },
        FORM: {
            ACCOUNT_ERROR_MESSAGE: 'td-twitter-account-error-message',
            ACCOUNT_FOLLOW: 'td-twitter-account-follow',
            ACCOUNT_HANDLE: 'td-twitter-account-handle',
            ACCOUNT_REGION: 'td-twitter-account-region',
            RULE_ACTIVE: 'td-twitter-rule-active',
            RULE_ERROR_MESSAGE: 'td-twitter-rule-error-message',
            RULE_NAME: 'td-twitter-rule-name',
            RULE_VALUE: 'td-twitter-rule-value'
        }
    },
    UNAUTHORIZED: 'unauthorized',
    USER_SETTINGS: {
        TOGGLE_ALLOW_CHAT_NOTIFICATIONS: 'toggle-allow-chat-notifications',
        TOGGLE_ALLOW_APPROVAL_NOTIFICATIONS: 'toggle-allow-chat-notifications',
        TOGGLE_ALLOW_REJECTION_NOTIFICATIONS:
            'toggle-allow-rejection-notifications'
    }
};
