import get from 'lodash/get';
import moment from 'moment-timezone';
import { Multiselect } from 'multiselect-react-dropdown';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    DRAFT_AUTHOR_DISPLAY_OPTIONS,
    MULTISELECT_COMPONENT_STYLES,
    VIEW_MY_DRAFTS
} from '../config/constants';
import { TEST_ATTRIBUTES } from '../config/testConstants';
import { setDraftAuthorContentFilter } from '../redux/actions';
import {
    getAuthInfo,
    getDraftAuthorContentFilter,
    getFilters
} from '../redux/selectors';
import AlertPanel from './AlertPanel';

export default function AlertList({
    alerts,
    dateField,
    isLoading,
    title,
    type
}) {
    const alertFilters = useSelector(getFilters('alert'));
    const draftAuthorContentFilter = useSelector(getDraftAuthorContentFilter);
    const dispatch = useDispatch();
    const authInfo = useSelector(getAuthInfo);

    function normalizeApprovalToAlert(alert) {
        let normalized = {
            end_date: parseInt(alert.endDate),
            start_date: parseInt(alert.startDate),
            published_date: false,
            created_date: moment(alert.createdAt).unix(),
            source: 'IC',
            creator_name: alert.creator.name
        };
        return { ...alert, ...normalized };
    }

    function renderAlertPanel(alert) {
        if (
            alert.category !== null &&
            !get(alertFilters, ['category', alert.category])
        ) {
            if (type === 'draftsList') {
                console.log(
                    'category mismatch, skipping render for alert: ',
                    alert
                );
            }
            return;
        }

        //Check for draft author filter
        if (
            type === 'draftsList' &&
            draftAuthorContentFilter === VIEW_MY_DRAFTS &&
            alert.creatorId !== authInfo.user.id
        ) {
            console.log('creator mismatch, skipping render for alert: ', alert);
            return;
        }
        return (
            <AlertPanel
                key={`${alert.id}-${alert.startDate}`}
                type={type}
                title={title}
                alert={
                    type === 'approvalList' || type === 'draftsList'
                        ? normalizeApprovalToAlert(alert)
                        : alert
                }
                dateField={dateField}
            />
        );
    }

    function updateDraftAuthorContentFilter(selectedList, item) {
        dispatch(setDraftAuthorContentFilter(item.value));
    }

    return (
        <div
            className={`AlertList AlertList-type-${type}`}
            data-testid={`${TEST_ATTRIBUTES.ALERT_LIST}-${type}`}
        >
            {type !== 'draftsList' && (
                <div className="AlertList-title">
                    {isLoading && (
                        <div className="AlertList-loading">
                            <i className="fa fa-spinner fa-spin" />
                        </div>
                    )}
                    <span>{title}</span>
                </div>
            )}
            {type === 'draftsList' && (
                <div className="AuthorDraft-toggle">
                    <Multiselect
                        id={'multiselect-draft-author-display-options'}
                        options={DRAFT_AUTHOR_DISPLAY_OPTIONS}
                        onSelect={updateDraftAuthorContentFilter}
                        displayValue="label"
                        singleSelect={true}
                        avoidHighlightFirstOption={true}
                        style={MULTISELECT_COMPONENT_STYLES}
                        selectedValues={[DRAFT_AUTHOR_DISPLAY_OPTIONS[1]]}
                        data-testid={
                            TEST_ATTRIBUTES.DRAFTS_VIEW.FILTERS.AUTHOR_FILTER
                        }
                    />
                </div>
            )}
            {alerts.map((alert, index) => renderAlertPanel(alert, index))}
        </div>
    );
}
