import each from 'lodash/each';
import find from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import { confirmAlert } from 'react-confirm-alert';

import { COLUMNS, TWITTER_RULES } from './config/constants';
import { isItemRead } from './localStorage/readItemsStorage';

export const getLocationFromOverlay = (overlay) => {
    if (overlay.type === 'circle') {
        return getCircleFromOverlay(overlay);
    } else if (overlay.type === 'polygon') {
        return getPolygonFromOverlay(overlay);
    }
    return false;
};

export const getCircleFromOverlay = (overlay) => {
    let center = overlay.value.getCenter().toJSON();
    let radius = overlay.value.radius;
    return {
        // Saved borders should not be editable, by default let
        // overlays be editable
        isSavedBorder: overlay.isSavedBorder || false,
        type: 'circle',
        radius: parseInt(radius),
        center: center
    };
};

export const getPolygonFromOverlay = (overlay) => {
    let path = [];
    overlay.value
        .getPath()
        .getArray()
        .forEach(function (point) {
            path.push([point.toJSON().lng, point.toJSON().lat]);
        });

    return {
        // Saved borders should not be editable, by default let
        // overlays be editable
        isSavedBorder: overlay.isSavedBorder || false,
        type: 'polygon',
        coordinates: [path]
    };
};

export const stripTags = (html, ...args) => {
    return html
        .replace(
            /<(\/?)(\w+) ([^>]*)[^>]*\/?>/g,
            (_, endMark, tag, attributes) => {
                return args.includes(tag)
                    ? '<' +
                          endMark +
                          tag +
                          (endMark ? '' : ' ' + attributes) +
                          '>'
                    : '';
            }
        )
        .replace(/<!--.*?-->/g, '');
};

export function auditsFailedModal(validationMessages) {
    confirmAlert({
        title: 'Missing Information',
        message:
            'Please make sure you have filled in all the required information:',
        buttons: [
            {
                label: 'OK',
                onClick: () => {}
            }
        ],
        childrenElement: () => (
            <ul>
                {map(Object.keys(validationMessages), (key) => (
                    <li key={`${key}ValidationMessage`}>
                        {validationMessages[key]}
                    </li>
                ))}
            </ul>
        )
    });
}

export const extractCategoryOption = (value, categories) => {
    return find(categories, { label: value });
};

export const extractUrgencyOption = (value, urgencies) => {
    let urgency = false;
    each(urgencies, (option) => {
        if (`${option.value}` === `${value}`) {
            urgency = option;
        }
    });
    return urgency;
};

export const extractRegionOption = (value, regionList) => {
    let region = false;
    each(regionList, (option) => {
        if (`${option.id}` === `${value}`) {
            region = option;
        }
    });
    return region;
};

/* Threat Deck Column Filters */

export const doesItemMatchAnyColumn = (item, columns = []) => {
    return reduce(
        columns,
        (matches, col) => {
            return matches || doesItemMatchTDColumnFilters(item, col);
        },
        false
    );
};

// This function is used to determine if an item should be displayed in a column
// we fast fail based on the first failed filter, therefore the order of the filters
// is important, and likelier filters should be placed first
export const doesItemMatchTDColumnFilters = (
    item,
    column,
    localColumnConfig = {
        showViewed: true,
        showUnviewed: true
    }
) => {
    if (column.contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS) {
        const isRead = isItemRead(item.id);
        // column doesn't show viewed items
        if (isRead && !localColumnConfig.showViewed) {
            return false;
        }

        // column doesn't show unviewed items
        if (!isRead && !localColumnConfig.showUnviewed) {
            return false;
        }

        // has column source
        if (!includes(column.sources, item.type)) {
            return false;
        }

        const confidenceFloor = `${column.confidenceFloor}`.padStart(2, '0');
        // meets confidence floor
        if (
            parseFloat(item.annotation.ml_confidence) <
            parseFloat(`0.${confidenceFloor}`)
        ) {
            return false;
        }

        const confidenceCeiling = `${column.confidenceCeiling}`.padStart(
            2,
            '0'
        );
        // and confidence ceiling
        if (
            parseFloat(item.annotation.ml_confidence) >
            (Number(confidenceCeiling) === 100
                ? 1.0
                : parseFloat(`0.${confidenceCeiling}`))
        ) {
            return false;
        }

        // specific rule for Twitter items
        if (
            item.type === 'TWITTER' &&
            !doesItemMatchTDColumnTwitterRules(item, column)
        ) {
            return false;
        }
    }

    // The following filters apply to all content types
    // has a column category
    if (COLUMNS.FILTERS[column.contentType].CATEGORY(item, column)) {
        return false;
    }

    // matches column regions
    if (COLUMNS.FILTERS[column.contentType].REGION(item, column)) {
        return false;
    }

    return true;
};

export const doesItemMatchTDColumnTwitterRules = (item, column) => {
    const ruleSet = [...column.twitterRules];

    if (column.followedAccountsFilter) {
        ruleSet.push(TWITTER_RULES.FOLLOWED_ACCOUNTS_PSEUDO_RULE);
    }

    return some(ruleSet, (rule) => {
        return includes(item.matched_rules, rule?.tag);
    });
};
