import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import RedDot from '../../../assets/td_event_marker.svg';
import {
    MAP_DARK_STYLE,
    MAP_DEFAULTS,
    MODALS,
    NAMESPACES
} from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { useRefState } from '../../../hooks/shared';
import { toggleModal } from '../../../redux/actions';
import { getNamespace } from '../../../redux/selectors';

export default function ThreatDeckEventsMap({ events, singleEvent }) {
    const dispatch = useDispatch();
    const [currentMap, currentMapRef, setCurrentMap] = useRefState(false);
    const [googleMaps, googleMapsRef, setGoogleMaps] = useRefState(false);
    const [searchBox, searchBoxRef, setSearchBox] = useRefState(false);
    const [markerList, setMarkerList] = useState([]);
    const [mapMarkers, setMapMarkers] = useState({});
    const [isRelocating, setIsRelocating] = useState(false);
    const [singleEventMarker, setSingleEventMarker] = useState(null);
    const [singleEventClickListener, setSingleEventClickListener] =
        useState(null);
    const pinControls = useRef();
    const searchInput = useRef();
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const image = RedDot;

    function isDifferentMarkerPosition(marker, lat, lng) {
        return marker.position.lat() !== lat || marker.position.lng() !== lng;
    }

    useEffect(() => {
        if (events && currentMap && googleMaps && !singleEvent) {
            events.forEach((e) => {
                let markerIndex = _.indexOf(markerList, e.id);
                if (markerIndex < 0) {
                    // This is so we don't keep adding duplicates when new events come in
                    let marker = new googleMaps.Marker({
                        position: e.locations,
                        map: currentMap,
                        title: e.title,
                        icon: image
                    });
                    mapMarkers[e.id] = marker;
                    marker.addListener('click', () => {
                        openDetailsModal(e);
                    });
                    setMarkerList(_.union(markerList, [e.id]));
                } else if (
                    isDifferentMarkerPosition(
                        mapMarkers[e.id],
                        e.locations.lat,
                        e.locations.lng
                    )
                ) {
                    // This is so we make sure we are properly moving the pins when necessary
                    mapMarkers[e.id].setPosition(e.locations);
                }
                // TODO: Clean out old markers when they expire out the list
            });
            setMapMarkers(mapMarkers);
        }
    }, [events, currentMap, googleMaps]);

    useEffect(() => {
        if (singleEvent && currentMap && googleMaps && !singleEventMarker) {
            let marker = new googleMaps.Marker({
                position: singleEvent.locations,
                map: currentMap,
                title: singleEvent.title,
                icon: image
            });
            setSingleEventMarker(marker);
            let bounds = new googleMaps.LatLngBounds();
            bounds.extend(marker.position);
            currentMap.fitBounds(bounds);
            currentMap.setZoom(9);
        }
    }, [singleEvent, currentMap, googleMaps]);

    function openDetailsModal(event) {
        dispatch(
            toggleModal({
                data: event,
                modalName: MODALS.EVENT_DETAILS,
                value: true
            })
        );
    }

    function handleBoundsChanged() {
        // searchBoxRef.current.setBounds(currentMapRef.current.getBounds());
    }

    function handleSearchPlaceSelected() {
        let places = searchBoxRef.current.getPlaces();
        if (places.length === 0) {
            return;
        }
        // For each place, get the icon, name and location.
        const bounds = new googleMapsRef.current.LatLngBounds();
        places.forEach((place) => {
            if (!place.geometry) {
                console.log('Returned place contains no geometry');
                return;
            }
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });
        currentMapRef.current.fitBounds(bounds);
    }

    function handleRelocationPoint(event) {
        let clickLocation = event.latLng;
        if (singleEventMarker) {
            singleEventMarker.setPosition(clickLocation);
        }
        if (singleEvent) {
            singleEvent.locations = {
                lat: clickLocation.lat(),
                lng: clickLocation.lng()
            };
        }
    }

    function handleApiLoaded(map, maps) {
        let searchBox = false;
        if (singleEvent) {
            searchBox = new maps.places.SearchBox(searchInput.current);
            map.controls[maps.ControlPosition.TOP_LEFT].push(
                searchInput.current
            );
            map.controls[maps.ControlPosition.TOP_RIGHT].push(
                pinControls.current
            );
            searchBox.addListener('places_changed', handleSearchPlaceSelected);
            map.addListener('bounds_changed', handleBoundsChanged);
        }
        // Set to state
        setCurrentMap(map);
        setGoogleMaps(maps);
        setSearchBox(searchBox);
    }

    function enterRelocatingMode() {
        setIsRelocating(true);
        if (searchInput.current) {
            searchInput.current.style.display = 'block';
        }
        let listener = currentMapRef.current.addListener(
            'click',
            handleRelocationPoint
        );
        setSingleEventClickListener(listener);
    }

    function saveRelocation() {
        setIsRelocating(false);
        if (searchInput.current) {
            searchInput.current.style.display = 'none';
        }
        if (singleEventClickListener) {
            googleMapsRef.current.event.removeListener(
                singleEventClickListener
            );
            setSingleEventClickListener(null);
        }
        threatDeckNamespace.emit('relocateEvent', {
            id: singleEvent.id,
            locations: singleEvent.locations
        });
    }

    return (
        <div className={`EventMapContainer${singleEvent ? '-single' : ''}`}>
            {singleEvent && (
                <>
                    <div ref={pinControls} className="EventMap-pinControls">
                        {!isRelocating && (
                            <FiMapPin
                                onClick={enterRelocatingMode}
                                className={'btn-pincontrol'}
                                size={'24'}
                                data-testid={'TBD'}
                                title={'Relocate Event Pin'}
                            />
                        )}
                        {isRelocating && (
                            <AiOutlineSave
                                onClick={saveRelocation}
                                className={'btn-pincontrol'}
                                size={'24'}
                                data-testid={'TBD'}
                                title={'Save New Pin Location'}
                            />
                        )}
                    </div>
                    <input
                        ref={searchInput}
                        className="controls EventMap-search"
                        type="text"
                        placeholder="Search for location on map"
                        data-testid={TEST_ATTRIBUTES.ALERT_FORM.MAP_SEARCH_BOX}
                    />
                </>
            )}
            <GoogleMapReact
                bootstrapURLKeys={MAP_DEFAULTS.bootstrapURLKeys}
                defaultCenter={MAP_DEFAULTS.defaultCenter}
                defaultZoom={4}
                yesIWantToUseGoogleMapApiInternals={true}
                options={{ ...MAP_DEFAULTS.options, ...MAP_DARK_STYLE }}
                onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                }
            />
        </div>
    );
}
