import every from 'lodash/every';
import isEqual from 'lodash/isEqual';

import {
    LOGOUT_USER,
    SET_COGNITO_SESSION,
    SET_IS_LOGGED_IN,
    SET_USER,
    SET_USER_REGIONS,
    SET_USER_ROLE,
    SET_USER_SESSION,
    SOCKET_MANAGER,
    SOCKET_NAMESPACE,
    SOCKETS_CONNECTED,
    SOCKETS_DISCONNECTED,
    SOCKETS_RECONNECTED,
    SOCKETS_RECONNECTING
} from '../actionTypes';

export const initialState = {
    areSocketsConnected: false,
    areSocketsReconnecting: false,
    cognitoSession: null,
    isLoggedIn: false,
    regions: [],
    role: false,
    socketManager: false,
    sockets: {},
    user: {},
    userSession: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER: {
            if (!isEqual(state.user, action.payload)) {
                return {
                    ...state,
                    user: action.payload
                };
            }
            return {
                ...state
            };
        }
        case SET_COGNITO_SESSION: {
            return {
                ...state,
                cognitoSession: action.payload
            };
        }
        case SET_IS_LOGGED_IN: {
            return {
                ...state,
                isLoggedIn: action.payload
            };
        }
        case SET_USER_SESSION: {
            if (!isEqual(state.userSession, action.payload)) {
                return {
                    ...state,
                    userSession: action.payload
                };
            }
            return {
                ...state
            };
        }
        case LOGOUT_USER: {
            return {
                ...state,
                areSocketsConnected: false,
                areSocketsReconnecting: false,
                cognitoSession: null,
                isLoggedIn: false,
                user: {},
                regions: [],
                role: false,
                sockets: {},
                socketManager: false,
                userSession: null
            };
        }
        case SET_USER_ROLE: {
            const { role } = action.payload;
            return {
                ...state,
                role: role
            };
        }
        case SET_USER_REGIONS: {
            const { regions } = action.payload;
            return {
                ...state,
                regions: regions
            };
        }
        case SOCKETS_CONNECTED: {
            const areSocketsConnected = every(
                state.socketManager.nsps,
                'connected'
            );

            return {
                ...state,
                areSocketsConnected,
                areSocketsReconnecting: !areSocketsConnected
            };
        }
        case SOCKETS_DISCONNECTED: {
            return {
                ...state,
                areSocketsConnected: false,
                areSocketsReconnecting: false
            };
        }
        case SOCKET_MANAGER: {
            return {
                ...state,
                socketManager: action.payload.SocketManager
            };
        }
        case SOCKET_NAMESPACE: {
            const { namespace, socket } = action.payload;
            return {
                ...state,
                sockets: {
                    ...state.sockets,
                    [namespace]: socket
                }
            };
        }
        case SOCKETS_RECONNECTED: {
            // We have multiple sockets, we don't want to mark things connected/reconnected until we know that
            // all sockets are connected. These things should happen in concert, but this shouldn't hurt.
            return {
                ...state,
                areSocketsConnected: every(
                    state.socketManager.nsps,
                    'connected'
                )
            };
        }
        case SOCKETS_RECONNECTING: {
            return {
                ...state,
                areSocketsConnected: false,
                areSocketsReconnecting: true
            };
        }
        default:
            return state;
    }
}
