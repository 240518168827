import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import validate from 'validate.js';

import { NAMESPACES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getNamespace } from '../../../../redux/selectors';
import { auditsFailedModal } from '../../../../utils';
import ICButton from '../../../Buttons/presentational/ICButton';

const { CONTENT_MANAGEMENT } = TEST_ATTRIBUTES;

export default function TravelBriefSourceForm({
    closeSourceModal,
    travelBriefId,
    source
}) {
    const [title, setTitle] = useState(source?.title || '');
    const [url, setUrl] = useState(source?.url || '');
    const briefsNamespace = useSelector(
        getNamespace(NAMESPACES.BRIEFS_NAMESPACE)
    );

    useEffect(() => {
        if (briefsNamespace) {
            briefsNamespace.on('briefSourceSaveFailed', () => {
                confirmAlert({
                    title: 'Save Failed',
                    message: 'There was an error.',
                    buttons: [
                        {
                            label: 'No',
                            onClick: () => {}
                        }
                    ]
                });
            });
        }

        return () => {
            briefsNamespace.off('briefSourceSaveFailed');
        };
    }, []);

    function auditData() {
        return validate(
            { title, url },
            {
                title: {
                    presence: {
                        allowEmpty: false,
                        message: '^Sources must have a title.'
                    }
                },
                url: {
                    presence: {
                        allowEmpty: false,
                        message: '^Sources must have a url.'
                    },
                    url: true
                }
            },
            { format: 'flat' }
        );
    }

    function clearAndCloseModal() {
        clearData();
        closeSourceModal();
    }

    function clearData() {
        setTitle('');
        setUrl('');
    }

    function confirmCancel() {
        if (!title && !url) {
            // If the user hasn't updated anything, no confirmation needed
            clearAndCloseModal();
            return;
        }
        confirmAlert({
            title: 'Confirm Cancel',
            message: `Are you sure you want to discard your edits?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: clearAndCloseModal
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function confirmSave() {
        const validationMessages = auditData();
        if (_.isEmpty(validationMessages)) {
            confirmAlert({
                title: 'Confirm Save',
                message: `Are you sure you want to save your changes?`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: saveSource
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function saveSource() {
        briefsNamespace.emit('saveBriefSource', {
            id: source?.id,
            travelBriefId,
            title,
            url
        });
    }

    function updateTitle(e) {
        setTitle(e.target.value);
    }

    function updateUrl(e) {
        setUrl(e.target.value);
    }

    return (
        <div>
            <div className="ContentManagementModal-header">
                <h2>Add Source</h2>
                <button
                    className="ReactModal__CloseButton"
                    onClick={confirmCancel}
                    data-testid={`${CONTENT_MANAGEMENT.CLOSE_SOURCE_MODAL}${
                        source?.id ? `-${source.id}` : ''
                    }`}
                >
                    <i className="fa fa-window-close" />
                </button>
            </div>

            <div className="ContentManagementModal-body">
                <div className="ContentForm-input-container">
                    <label htmlFor="source-title">Title</label>
                    <div className="input">
                        <input
                            id="source-title"
                            className="ContentForm-form-control"
                            type="text"
                            value={title}
                            onChange={updateTitle}
                            data-testid={
                                TEST_ATTRIBUTES.CONTENT_MANAGEMENT.SOURCE_TITLE
                            }
                        />
                    </div>
                </div>

                <div className="ContentForm-input-container">
                    <label htmlFor="source-url">URL</label>
                    <div className="input">
                        <input
                            id="source-url"
                            className="ContentForm-form-control"
                            type="text"
                            value={url}
                            onChange={updateUrl}
                            data-testid={CONTENT_MANAGEMENT.SOURCE_URL}
                        />
                    </div>
                </div>
            </div>

            <div className="ContentManagementModal-footer">
                <ICButton color="white" onClick={confirmCancel}>
                    Cancel
                </ICButton>
                <ICButton color="blue" onClick={confirmSave}>
                    Save
                </ICButton>
            </div>
        </div>
    );
}
