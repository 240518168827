import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import {
    MULTISELECT_COMPONENT_STYLES,
    NAMESPACES,
    USER_ROLES
} from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getNamespace } from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';

const _ = require('lodash');

export default function UserManagementForm({
    setCreateUserFormActive,
    setUserToEdit,
    userToEdit,
    setHideTabs
}) {
    //redux
    const usersNamespace = useSelector(
        getNamespace(NAMESPACES.USERS_NAMESPACE)
    );

    // state
    const [userEmail, setUserEmail] = useState(userToEdit?.email);
    const [userRealName, setUserRealName] = useState(userToEdit?.name);
    const [userDisabled, setUserDisabled] = useState(userToEdit?.disabled);
    const [userRole, setUserRole] = useState(
        userToEdit ? _.find(USER_ROLES, ['value', userToEdit.role]) : null
    );
    const [errorMessage, setErrorMessage] = useState(false);

    setHideTabs(true);

    useEffect(() => {
        if (usersNamespace) {
            usersNamespace.on('adminCreateUserResponse', (data) => {
                adminCreateUserResponse(data);
            });
            usersNamespace.on('adminResetPasswordResponse', (data) => {
                adminResetPasswordResponse(data);
            });
            usersNamespace.on('adminEnableUserResponse', (data) => {
                adminEnableUserResponse(data);
            });
            usersNamespace.on('adminDisableUserResponse', (data) => {
                adminDisableUserResponse(data);
            });
        }

        return () => {
            usersNamespace.off('adminCreateUserResponse');
            usersNamespace.off('adminResetPasswordResponse');
            usersNamespace.off('adminEnableUserResponse');
            usersNamespace.off('adminDisableUserResponse');
        };
    }, [usersNamespace]);

    function validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validateFormFields() {
        if (userEmail) {
            const emailIsValid = validateEmail(userEmail);
            if (!emailIsValid) {
                setErrorMessage('Please enter a valid email address');
                return false;
            }
        }
        if (!userEmail) {
            setErrorMessage('Please enter an email address');
            return false;
        }
        if (!userRealName) {
            setErrorMessage(`Please enter the user's name`);
            return false;
        }
        if (!userRole) {
            setErrorMessage('Please enter a user role');
            return false;
        }
        return true;
    }

    function saveCreateUser() {
        if (validateFormFields()) {
            usersNamespace.emit('adminCreateUser', {
                email: userEmail,
                role: userRole.value,
                name: userRealName
            });
        }
    }

    function saveEditUser() {
        if (validateFormFields()) {
            usersNamespace.emit('adminEditUser', {
                email: userEmail,
                role: userRole.value,
                name: userRealName
            });
        }
    }

    function resetUserPassword() {
        validateFormFields();
        usersNamespace.emit('adminResetPassword', {
            email: userEmail
        });
    }

    function disableUser() {
        usersNamespace.emit('adminDisableUser', {
            email: userEmail
        });
    }

    function enableUser() {
        usersNamespace.emit('adminEnableUser', {
            email: userEmail
        });
    }

    function adminEnableUserResponse(data) {
        if (data.status === 'ERROR') {
            setErrorMessage(`Error reenabling the user: ${data.response.name}`);
            return;
        } else if (data.status === 'OK') {
            setUserDisabled(false);
        }
    }

    function adminDisableUserResponse(data) {
        if (data.status === 'ERROR') {
            setErrorMessage(`Error disabling the user: ${data.response.name}`);
            return;
        } else if (data.status === 'OK') {
            setUserDisabled(true);
        }
    }

    function adminCreateUserResponse(data) {
        if (data.status === 'ERROR') {
            setErrorMessage(`Error saving changes: ${data.response.name}`);
            return;
        } else if (data.status === 'OK') {
            cancelCreateUser();
        }
    }

    function adminResetPasswordResponse(data) {
        if (data.status === 'ERROR') {
            setErrorMessage(
                `Error resetting user password: ${data.response.name}`
            );
            return;
        } else if (data.status === 'OK') {
            confirmAlert({
                title: 'Password Reset Successful',
                message: `An email was sent to this user with password reset instructions`,
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
        }
    }

    function cancelCreateUser() {
        setUserEmail('');
        setUserRole(false);
        setErrorMessage(false);
        setUserToEdit(null);
        setCreateUserFormActive(false);
        setHideTabs(false);
    }

    return (
        <Container className="ConfigureSystem-CreateUser-Container">
            <span>
                <Row>
                    <Col>
                        <h1>{userToEdit ? 'Edit' : 'Create New'} User</h1>
                    </Col>
                </Row>
                <Row>Email:</Row>
                <Row>
                    {userToEdit && <span>{userEmail}</span>}
                    {!userToEdit && (
                        <input
                            id="ICCreateUserName"
                            className="CreateUser-form-control"
                            onChange={(e) => setUserEmail(e.target.value)}
                            data-testid={TEST_ATTRIBUTES.CREATE_USER_FORM.EMAIL}
                            value={userEmail}
                        />
                    )}
                </Row>
                <Row>Full Name:</Row>
                <Row>
                    <input
                        id="ICCreateUserName"
                        className="CreateUser-form-control"
                        onChange={(e) => setUserRealName(e.target.value)}
                        data-testid={TEST_ATTRIBUTES.CREATE_USER_FORM.NAME}
                        value={userRealName}
                    />
                </Row>
                <Row>Role:</Row>
                <Row>
                    <Multiselect
                        id="updateTypeMultiselect"
                        selectionLimit={1}
                        options={USER_ROLES}
                        onSelect={(selectedList, item) => setUserRole(item)}
                        displayValue="label"
                        singleSelect={true}
                        style={MULTISELECT_COMPONENT_STYLES}
                        selectedValues={
                            userToEdit
                                ? [
                                      _.find(USER_ROLES, [
                                          'value',
                                          userToEdit.role
                                      ])
                                  ]
                                : userRole
                                ? [userRole]
                                : []
                        }
                    />
                </Row>
                {errorMessage && (
                    <div className="Login-form-error">{errorMessage}</div>
                )}
                {userToEdit && (
                    <span>
                        <Row>
                            <ICButton
                                color="blue"
                                onClick={() => {
                                    saveEditUser();
                                }}
                            >
                                Save Edits
                            </ICButton>
                        </Row>
                        <Row>
                            <ICButton
                                color="yellow"
                                onClick={() => {
                                    confirmAlert({
                                        title: 'Confirm Reset Password',
                                        message: `Are you sure you want to reset this users password?`,
                                        buttons: [
                                            {
                                                label: 'Yes',
                                                onClick: () =>
                                                    resetUserPassword()
                                            },
                                            {
                                                label: 'No',
                                                onClick: () => {} // Do Nothing
                                            }
                                        ]
                                    });
                                }}
                            >
                                Reset Password
                            </ICButton>
                        </Row>
                        {userDisabled && (
                            <Row>
                                <ICButton
                                    color="green"
                                    onClick={() => {
                                        enableUser();
                                    }}
                                >
                                    Re-Enable User
                                </ICButton>
                            </Row>
                        )}
                        {!userDisabled && (
                            <Row>
                                <ICButton
                                    color="red"
                                    onClick={() => {
                                        confirmAlert({
                                            title: 'Confirm Disable User',
                                            message: `Are you sure you want to disable this user? They can be re-enabled later.`,
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => disableUser()
                                                },
                                                {
                                                    label: 'No',
                                                    onClick: () => {} // Do Nothing
                                                }
                                            ]
                                        });
                                    }}
                                >
                                    Disable User
                                </ICButton>
                            </Row>
                        )}
                    </span>
                )}

                {!userToEdit && (
                    <Row>
                        <ICButton
                            color="blue"
                            onClick={() => {
                                saveCreateUser();
                            }}
                        >
                            Save
                        </ICButton>
                    </Row>
                )}
                <Row>
                    <ICButton
                        color="white"
                        onClick={() => {
                            cancelCreateUser();
                        }}
                    >
                        Cancel
                    </ICButton>
                </Row>
            </span>
        </Container>
    );
}
