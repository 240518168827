import { useEffect, useState } from 'react';

/**
 * Uploads a file to S3
 */
function uploadToS3(file, situationReportId, options = {}) {
    const { socketNamespace } = options;
    let { name } = file;

    socketNamespace.emit('articleMediaUpload', {
        situationReportId,
        name,
        file
    });
}

function _filenameValidation(filename) {
    // Certain characters are not allowed in S3 object keys,
    // as such we will only allow alphanumeric characters, dashes, underscores and periods.
    // Multiple dashes and multiple periods do not break things.
    // https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
    // Take the docs with a grain of salt, as they are not 100% accurate some safelisted
    // characters still broke things.
    const alphanumericRegex = /[^a-z0-9\-._$]/gi;

    // null if there is no match
    return filename.match(alphanumericRegex);
}

/**
 * Using this specifically for tinyMCE image uplaods right now since it
 * requires a promise to resolve.
 * TODO: We can probably end up replacing uploadToS3 with this and DRY up
 * the validation logic in the useS3 hook.
 * @param {File} file an image file being uploaded to s3 sent from tinyMCE
 * @param {string} situationReportId the id of the situation report that the related article belongs to
 * @param {object} options socketNamespace
 * @returns A promise that resolves with the tag set returned by s3.
 */
async function s3Promise({ file, situationReportId, options = {} }) {
    const { socketNamespace } = options;
    return new Promise(function (resolve, reject) {
        let invalidCharacters = _filenameValidation(file.name);
        if (invalidCharacters) {
            reject(
                `File name contains invalid special characters or spaces. Please rename the file with only a-z, 0-9, -, _, . and try again.`
            );
        }

        socketNamespace.once('s3UploadSuccess', (s3Response) => {
            console.log('s3UploadSuccess', s3Response);
            socketNamespace.off('s3UploadError');
            resolve(s3Response.data);
        });
        socketNamespace.once('s3UploadError', (s3Response) => {
            console.log('s3UploadError', s3Response);
            socketNamespace.off('s3UploadSuccess');
            reject(s3Response.error);
        });
        uploadToS3(file, situationReportId, options);
    });
}

/**
 * Hook to run S3 actions
 */
function useS3({ file, situationReportId, options = {}, dependencies }) {
    const [s3Response, setS3Response] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const { socketNamespace, s3Action } = options;

    useEffect(() => {
        switch (s3Action) {
            case 'putObject':
                if (file) {
                    let { name } = file;
                    let invalidCharacters = _filenameValidation(name);

                    if (invalidCharacters) {
                        setS3Response({
                            error: `File name contains invalid special characters or spaces. Please rename the file with only a-z, 0-9, -, _, . and try again.`
                        });
                        setIsLoading(false);
                        console.log(
                            'uploadToS3: Invalid characters detected in name',
                            invalidCharacters
                        );
                        break;
                    }

                    socketNamespace.once('s3UploadSuccess', (data) => {
                        console.log('s3UploadSuccess', data);
                        socketNamespace.off('s3UploadError');
                        setS3Response(data);
                        setIsLoading(false);
                    });
                    // TODO: see if we can get at the S3 error messages during retries
                    // and fail out of the retries loop if the error is not 404 not found
                    socketNamespace.once('s3UploadError', (error) => {
                        console.log('s3UploadError', error);
                        socketNamespace.off('s3UploadSuccess');
                        setS3Response(error);
                        setIsLoading(false);
                    });
                    setIsLoading(true);
                    uploadToS3(file, situationReportId, {
                        socketNamespace,
                        s3Action,
                        setIsLoading,
                        setS3Response
                    });
                } else {
                    setIsLoading(false);
                    setS3Response({});
                }
                break;
            default:
                break;
        }

        // component cleanup, cancel fetcher requests
        // this prevents setting state on unmounted components
        return () => {
            // remove listeners
            socketNamespace.off('s3UploadSuccess');
            socketNamespace.off('s3UploadError');
        };
    }, dependencies);

    // more values can be returned as needed.
    return [isLoading, s3Response, setS3Response];
}

export { s3Promise, useS3, uploadToS3 };
