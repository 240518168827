import '../../../styling/ThreatDeckCard.css';

import React from 'react';

import TriStateSwitch from '../TriStateSwitch';

export default function ThreatDeckCardAcceptReject({ onSwitchChange, state }) {
    return (
        <div className="ThreatDeckCard-accept-reject">
            <TriStateSwitch state={state} onChange={onSwitchChange} />
        </div>
    );
}
