//https://www.npmjs.com/package/multiselect-react-dropdown
import get from 'lodash/get';
import size from 'lodash/size';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { getAuthInfo } from '../../redux/selectors';
import ICButton from '../Buttons/presentational/ICButton';

export default function RegionSelector({
    regions,
    onSelect,
    onRemove,
    baseClassName = 'RegionSelector',
    app,
    itemRegions = []
}) {
    const authInfo = useSelector(getAuthInfo);
    const [selectedRegions, setSelectedRegions] = useState(itemRegions);
    const selectRef = useRef();
    function regionSelect(selectedList, selectedItem) {
        setSelectedRegions(selectedList);
        onSelect(selectedList, selectedItem);
    }

    function regionRemove(selectedList, removedItem) {
        setSelectedRegions(selectedList);
        onRemove(selectedList, removedItem);
    }

    function regionSelectAll() {
        setSelectedRegions(regions);
        onSelect(regions);
    }

    function regionRemoveAll() {
        if (selectRef.current) {
            selectRef.current.resetSelectedValues();
            setSelectedRegions([]);
            onRemove([]);
        }
    }

    return (
        <div className={baseClassName}>
            <label
                htmlFor={`RegionSelector-${app}`}
                className={`${baseClassName}-label`}
            >
                Regions:
            </label>
            <div
                id={`RegionSelector-${app}`}
                className={`${baseClassName}-dropdown`}
            >
                <Multiselect
                    id="regionSelectorMultiselect"
                    options={regions}
                    displayValue="name"
                    placeholder={
                        size(get(authInfo, 'regions')) > 0 ||
                        size(selectedRegions) > 0
                            ? ''
                            : 'Select At Least One Region'
                    }
                    selectedValues={
                        app === 'ic'
                            ? get(authInfo, 'regions')
                            : selectedRegions
                    }
                    closeIcon="close"
                    onSelect={regionSelect}
                    onRemove={regionRemove}
                    ref={selectRef}
                />
            </div>
            {app === 'td' && (
                <div className={`${baseClassName}-buttons`}>
                    <ICButton
                        color="white"
                        onClick={regionSelectAll}
                        className="ThreatDeck-region-button"
                        data-testid={
                            TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN_CONFIG
                                .REGIONS_BUTTONS.SELECT_ALL
                        }
                    >
                        Select All
                    </ICButton>
                    <ICButton
                        color="white"
                        onClick={regionRemoveAll}
                        className="ThreatDeck-region-button"
                        data-testid={
                            TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN_CONFIG
                                .REGIONS_BUTTONS.REMOVE_ALL
                        }
                    >
                        Remove All
                    </ICButton>
                </div>
            )}
        </div>
    );
}
