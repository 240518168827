import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getNamespace } from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';

export default function TwitterFiltersForm({
    setCreateTwitterRuleActive,
    twitterRuleToEdit,
    setTwitterRuleToEdit
}) {
    //redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const [twitterRuleTag, setTwitterRuleTag] = useState(
        twitterRuleToEdit?.tag
    );
    const [twitterRuleRule, setTwitterRuleRule] = useState(
        twitterRuleToEdit?.rule
    );
    const [twitterRuleActive, setTwitterRuleActive] = useState(
        twitterRuleToEdit?.active
    );
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('twitterRuleCreateResponse', (data) => {
                if (data.error) {
                    setErrorMessage(data.error);
                    return;
                }
                closeTheForm();
            });
            threatDeckNamespace.on('twitterRuleEditResponse', (data) => {
                if (data.error) {
                    setErrorMessage(data.error);
                    return;
                }
                closeTheForm();
            });
        }
    }, [threatDeckNamespace]);

    function closeTheForm() {
        threatDeckNamespace.emit('getTwitterRules');
        setTwitterRuleToEdit(false);
        setCreateTwitterRuleActive(false);
    }

    function saveTwitterRule() {
        let socketEvent = twitterRuleToEdit
            ? 'updateTwitterRule'
            : 'createTwitterRule';
        let args = {
            tag: twitterRuleTag,
            rule: twitterRuleRule,
            active: twitterRuleActive
        };
        if (twitterRuleToEdit) {
            args['id'] = twitterRuleToEdit.id;
        }
        threatDeckNamespace.emit(socketEvent, args);
    }

    return (
        <Container className="ConfigureSystem-ThreatDeckSourceForm-Container">
            <span>
                <Row>
                    <Col>
                        <h1>
                            {twitterRuleToEdit ? 'Edit' : 'Create'} Filter Rule
                        </h1>
                    </Col>
                </Row>
                <Row className="ThreatDeckSource-form-row">Rule Name</Row>
                <Row>
                    <input
                        id="TDRegisterTwitterRuleName"
                        className="ThreatDeckSource-form-control"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.RULE_NAME}`}
                        onChange={(e) => setTwitterRuleTag(e.target.value)}
                        value={twitterRuleTag || ''}
                    />
                </Row>
                <Row className="ThreatDeckSource-form-row">Rule Query</Row>
                <Row>
                    <textarea
                        id="TDRegisterTwitterRuleRule"
                        className="ThreatDeckSource-form-textarea"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.RULE_VALUE}`}
                        onChange={(e) => setTwitterRuleRule(e.target.value)}
                    >
                        {twitterRuleRule || ''}
                    </textarea>
                </Row>
                <Row className="ThreatDeckSource-form-row">
                    <span className="TDRegisterTwitterRuleActive">
                        <input
                            type="checkbox"
                            id="updateTwitterRuleActive"
                            data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.RULE_ACTIVE}`}
                            checked={twitterRuleActive}
                            onChange={() =>
                                setTwitterRuleActive(!twitterRuleActive)
                            }
                        />
                        <label htmlFor="updateTwitterRuleActive">Active</label>
                    </span>
                </Row>
                {errorMessage && (
                    <div
                        className="Login-form-error"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.RULE_ERROR_MESSAGE}`}
                    >
                        {errorMessage}
                    </div>
                )}

                <Row className="ThreatDeckSource-form-row">
                    <ICButton
                        color="blue"
                        className="ThreatDeckSource-form-button"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.SAVE_RULE}`}
                        onClick={() => {
                            saveTwitterRule();
                        }}
                    >
                        Save {twitterRuleToEdit ? 'Edits' : ''}
                    </ICButton>
                </Row>
                <Row className="ThreatDeckSource-form-row">
                    <ICButton
                        color="white"
                        className="ThreatDeckSource-form-button"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.CANCEL_RULE}`}
                        onClick={() => {
                            closeTheForm();
                        }}
                    >
                        Cancel
                    </ICButton>
                </Row>
            </span>
        </Container>
    );
}
