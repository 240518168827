import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { getEnabledCapabilities, getNamespace } from '../../../redux/selectors';
import ReportsLandingPage from '../shared/ReportsLandingPage';

export default function TravelBriefs() {
    // State
    const [briefs, setBriefs] = useState([]);
    const [statusFilter, setStatusFilter] = useState();
    const [sort, setSort] = useState({});
    // Redux
    const briefsNamespace = useSelector(
        getNamespace(NAMESPACES.BRIEFS_NAMESPACE)
    );
    const enabledCapabilities = useSelector(getEnabledCapabilities);

    useEffect(() => {
        if (briefsNamespace) {
            // listeners
            briefsNamespace.on('travelBriefsList', (briefsList) =>
                setBriefs(briefsList)
            );
            briefsNamespace.on('reloadAllBriefs', fetchBriefs);

            // emitters
            briefsNamespace.emit('getTravelBriefs');
        }
        return () => {
            briefsNamespace.off('travelBriefsList');
        };
    }, [briefsNamespace]);

    // When the sort or filter change, refetch results
    useEffect(() => {
        if (briefsNamespace) {
            fetchBriefs();
        }
    }, [statusFilter, sort]);

    function fetchBriefs() {
        const message = {};
        if (!_.isEmpty(sort)) {
            const sortToSend = {};
            sortToSend.name = Object.keys(sort)[0];
            sortToSend.order = sort[sortToSend.name];
            message.sort = sortToSend;
        }
        if (statusFilter) {
            message.filter = { status: statusFilter };
        }
        briefsNamespace.emit('getTravelBriefs', message);
    }

    function onFilterChanged(selectedList, item) {
        setStatusFilter(item.value);
    }

    function onSortChanged(fieldToSortBy) {
        // We will follow the traditional sort order found on most sites:
        //    neutral -> desc -> asc -> neutral
        setSort((currentSort = {}) => {
            let newSort = {};
            // If the fieldToSortBy is already in the sort, adjust the order accordingly
            if (fieldToSortBy in currentSort) {
                if (currentSort[fieldToSortBy] === 'DESC') {
                    newSort[fieldToSortBy] = 'ASC';
                } else if (currentSort[fieldToSortBy] === 'ASC') {
                    // Do nothing; removes the sort
                }
            } else {
                newSort[fieldToSortBy] = 'DESC';
            }
            return newSort;
        });
    }

    if (!enabledCapabilities.includes('travel_briefs')) {
        return null;
    }

    return (
        <ReportsLandingPage
            newButtonText="Add New Travel Brief"
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            reports={briefs}
            sort={sort}
            title="Travel Briefs"
        />
    );
}
