import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import {
    MULTISELECT_COMPONENT_STYLES,
    NAMESPACES
} from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getNamespace } from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';

export default function RSSFeedsForm({
    setCreateRssFeedActive,
    regions,
    rssFeedToEdit,
    setRssFeedToEdit
}) {
    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const [rssFeedTitle, setRssFeedTitle] = useState(rssFeedToEdit?.title);
    const [rssFeedUrl, setRssFeedUrl] = useState(rssFeedToEdit?.url);
    const [rssFeedActive, setRssFeedActive] = useState(rssFeedToEdit?.active);
    const [rssFeedRegion, setRssFeedRegion] = useState(
        rssFeedToEdit
            ? rssFeedToEdit.region
            : _.find(regions, ['name', 'Unknown'])
    );
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('rssFeedCreateResponse', (data) => {
                if (data.error) {
                    setErrorMessage(data.error);
                    return;
                }
                closeTheForm();
            });
            threatDeckNamespace.on('rssFeedEditResponse', (data) => {
                if (data.error) {
                    setErrorMessage(data.error);
                    return;
                }
                closeTheForm();
            });
        }
    }, [threatDeckNamespace]);

    function closeTheForm() {
        threatDeckNamespace.emit('getRssFeedsList');
        setRssFeedToEdit(false);
        setCreateRssFeedActive(false);
    }

    function validateRssFeed() {
        threatDeckNamespace.once('rssFeedValidationResponse', (data) => {
            if (data.error) {
                setErrorMessage(data.error);
                return;
            }
            saveRssFeed();
        });

        threatDeckNamespace.emit('validateRssFeed', { feed: rssFeedUrl });
    }

    function saveRssFeed() {
        let socketEvent = rssFeedToEdit ? 'updateRssFeed' : 'createRssFeed';
        let args = {
            title: rssFeedTitle,
            url: rssFeedUrl,
            active: rssFeedActive,
            region: rssFeedRegion
        };
        if (rssFeedToEdit) {
            args['id'] = rssFeedToEdit.id;
        }
        threatDeckNamespace.emit(socketEvent, args);
    }

    return (
        <Container className="ConfigureSystem-ThreatDeckSourceForm-Container">
            <span>
                <Row>
                    <Col>
                        <h1>{rssFeedToEdit ? 'Edit' : 'Register'} RSS Feed</h1>
                    </Col>
                </Row>
                <Row className="ThreatDeckSource-form-row">Feed Name</Row>
                <Row>
                    <input
                        id="TDRegisterRssFeedTitle"
                        className="ThreatDeckSource-form-control"
                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.FORM.RSS_TITLE}`}
                        onChange={(e) => setRssFeedTitle(e.target.value)}
                        value={rssFeedTitle || ''}
                    />
                </Row>
                <Row className="ThreatDeckSource-form-row">URL</Row>
                <Row>
                    <input
                        id="TDRegisterRssFeedUrl"
                        className="ThreatDeckSource-form-control"
                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.FORM.RSS_URL}`}
                        onChange={(e) => setRssFeedUrl(e.target.value)}
                        value={rssFeedUrl || ''}
                    />
                </Row>
                <Row className="ThreatDeckSource-form-row">
                    <span className="TDRegisterRssFeedActive">
                        <input
                            type="checkbox"
                            id="updateActiveRssFeed"
                            data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.FORM.RSS_STATUS}`}
                            checked={rssFeedActive}
                            onChange={() => setRssFeedActive(!rssFeedActive)}
                        />
                        <label htmlFor="updateActiveRssFeed">Active</label>
                    </span>
                </Row>
                <Row className="ThreatDeckSource-form-row">Region</Row>
                <Row>
                    <Multiselect
                        id="updateRegionMultiselect"
                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.FORM.RSS_REGION}`}
                        selectionLimit={1}
                        options={regions}
                        onSelect={(selectedList, item) =>
                            setRssFeedRegion(item)
                        }
                        displayValue="name"
                        singleSelect={true}
                        style={MULTISELECT_COMPONENT_STYLES}
                        selectedValues={
                            rssFeedToEdit
                                ? [
                                      _.find(regions, [
                                          'id',
                                          rssFeedToEdit.region.id
                                      ])
                                  ]
                                : rssFeedRegion
                                ? [rssFeedRegion]
                                : []
                        }
                    />
                </Row>
                {errorMessage && (
                    <div
                        className="Login-form-error"
                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.FORM.RSS_ERROR_MESSAGE}`}
                    >
                        {errorMessage}
                    </div>
                )}

                <Row className="ThreatDeckSource-form-row">
                    <ICButton
                        color="blue"
                        className="ThreatDeckSource-form-button"
                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.BUTTONS.SAVE_RSS}`}
                        onClick={() => {
                            validateRssFeed();
                        }}
                    >
                        Save {rssFeedToEdit ? 'Edits' : ''}
                    </ICButton>
                </Row>
                <Row className="ThreatDeckSource-form-row">
                    <ICButton
                        color="white"
                        className="ThreatDeckSource-form-button"
                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.BUTTONS.CANCEL_RSS}`}
                        onClick={() => {
                            closeTheForm();
                        }}
                    >
                        Cancel
                    </ICButton>
                </Row>
            </span>
        </Container>
    );
}
