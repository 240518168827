import _, { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, setConfiguration } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';

import { MODALS, NAMESPACES, ROLES } from '../config/constants';
import { TEST_ATTRIBUTES } from '../config/testConstants';
import {
    setCategoriesList,
    setFilters,
    setUserRegions,
    toggleModal
} from '../redux/actions';
import {
    getApplicationRegions,
    getAuthInfo,
    getCategoriesList,
    getNamespace
} from '../redux/selectors';
import AlertList from './AlertList';
import ApprovalAudio from './ApprovalAudio';
import ICButton from './Buttons/presentational/ICButton';
import MultiView from './MultiView';
import ReadOnlyAlertList from './ReadOnlyAlertListComponent/ReadOnlyAlertList';
import RegionSelector from './shared/RegionSelector';

const { ADMINISTRATOR, ANALYST, LEAD_ANALYST, READ_ONLY } = ROLES;

export default function ThreatsHomePage(props) {
    const [loading, setLoading] = useState({
        activeAlerts: true,
        expiringAlerts: true,
        approvalAlerts: true,
        approvalUpdates: true,
        draftAlerts: true,
        draftUpdates: true
    });
    const [activeAlerts, setActiveAlerts] = useState([]);
    const [expiringAlerts, setExpiringAlerts] = useState([]);
    const [approvalAlerts, setApprovalAlerts] = useState([]);
    const [approvalUpdates, setApprovalUpdates] = useState([]);
    const [draftAlerts, setDraftAlerts] = useState([]);
    const [draftUpdates, setDraftUpdates] = useState([]);
    // Redux
    const authInfo = useSelector(getAuthInfo);
    const regions = useSelector(getApplicationRegions);
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );
    const usersNamespace = useSelector(
        getNamespace(NAMESPACES.USERS_NAMESPACE)
    );
    const categoriesList = useSelector(getCategoriesList);
    const alertFilters = useSelector((state) => state.app.alertFilters);
    const dispatch = useDispatch();
    // Refs
    const regionsRef = useRef();

    regionsRef.current = authInfo.regions;

    useEffect(() => {
        // To fetch the initial list of alerts we will wait for the application regions to be available.
        // this technically depends on user regions, but they both get frontloaded at socket connection time.
        if (!isEmpty(regions)) {
            if (threatsNamespace) {
                threatsNamespace.emit('getAlertLists');
            }
        }
    }, [regions, threatsNamespace]);

    useEffect(() => {
        if (!isEmpty(categoriesList) && isEmpty(alertFilters)) {
            let initialFilters = {
                category: {}
            };
            categoriesList.forEach((category) => {
                initialFilters.category[category.value] = true;
            });
            dispatch(setFilters('alert', initialFilters));
        }
    }, [categoriesList]);

    useEffect(() => {
        setConfiguration({
            gutterWidth: 5,
            gridColumns:
                authInfo.role === LEAD_ANALYST ||
                authInfo.role === ADMINISTRATOR
                    ? 14
                    : 12
        });

        if (threatsNamespace) {
            // listeners
            threatsNamespace.on('activeAlerts', didLoadActiveAlerts);
            threatsNamespace.on('expiringAlerts', didLoadExpiringAlerts);
            threatsNamespace.on('approvalAlerts', didLoadApprovalAlerts);
            threatsNamespace.on('approvalUpdates', didLoadApprovalUpdates);
            threatsNamespace.on('draftAlerts', didLoadDraftAlerts);
            threatsNamespace.on('draftUpdates', didLoadDraftUpdates);

            threatsNamespace.on('myRegionList', loadedMyRegions);
            threatsNamespace.on('categoriesList', (categories) => {
                didLoadCategoriesList(categories);
            });

            // emits
            threatsNamespace.emit('myRegionList');
            threatsNamespace.emit('getRegionList');
            threatsNamespace.emit('getCategories');
        }

        return () => {
            // I don't see IntelCenter unmounting much, but just for consistency we should drop related listeners
            // threatsNamespace listeners
            if (threatsNamespace) {
                threatsNamespace.off('activeAlerts');
                threatsNamespace.off('expiringAlerts');
                threatsNamespace.off('approvalAlerts');
                threatsNamespace.off('approvalUpdates');
                threatsNamespace.off('draftAlerts');
                threatsNamespace.off('draftUpdates');
                threatsNamespace.off('myRegionList');
                threatsNamespace.off('categoriesList');
                threatsNamespace.off('capabilitiesList');
            }
        };
    }, [threatsNamespace]);

    useEffect(() => {
        if (usersNamespace) {
            usersNamespace.on('myRegionList', loadedMyRegions);
        }
    }, [authInfo, usersNamespace]);

    function loadedMyRegions(regions) {
        dispatch(setUserRegions(regions));
    }

    function didLoadCategoriesList(categories) {
        dispatch(setCategoriesList(categories));
    }

    // Other functions
    function filterRegionsNotCovered(alertList, includeUnknownRegion = false) {
        let regionList = [];
        _.forEach(regionsRef.current, (region) => {
            regionList.push(region.external_id);
        });
        let filteredList = [];
        _.forEach(alertList, (alert) => {
            if (
                (includeUnknownRegion && alert.region.id === 1) ||
                (alert.region &&
                    _.includes(regionList, alert.region.external_id))
            ) {
                filteredList.push(alert);
            }
        });
        return filteredList;
    }

    async function didLoadActiveAlerts(alerts) {
        alerts = await filterRegionsNotCovered(alerts);
        if (alerts !== undefined && alerts !== null) {
            setActiveAlerts(alerts);
            setLoading((loading) => ({ ...loading, activeAlerts: false }));
        }
    }

    async function didLoadExpiringAlerts(alerts) {
        alerts = await filterRegionsNotCovered(alerts);
        if (alerts !== undefined && alerts !== null) {
            setExpiringAlerts(alerts);
            setLoading((loading) => ({ ...loading, expiringAlerts: false }));
        }
    }

    async function didLoadApprovalAlerts(alerts) {
        try {
            alerts = await filterRegionsNotCovered(alerts);
            if (alerts !== undefined && alerts !== null) {
                setApprovalAlerts(alerts);
                setLoading((loading) => ({
                    ...loading,
                    approvalAlerts: false
                }));
            }
        } catch (error) {
            console.log('did NOT load approval alerts');
        }
    }

    function markUpdatesAsUpdates(updates) {
        let alerts = [];
        updates.forEach((update) => {
            update.isUpdate = true;
            alerts.push(update);
        });
        return alerts;
    }

    async function didLoadApprovalUpdates(updates) {
        updates = await filterRegionsNotCovered(updates);
        // Let's make sure we mark these as "Updates" so we treat them differently
        if (updates !== undefined && updates !== null) {
            setApprovalUpdates(markUpdatesAsUpdates(updates));
            setLoading((loading) => ({ ...loading, approvalUpdates: false }));
        }
    }

    async function didLoadDraftAlerts(alerts) {
        alerts = await filterRegionsNotCovered(alerts, true);
        if (alerts !== undefined && alerts !== null) {
            setDraftAlerts(alerts);
            setLoading((loading) => ({ ...loading, draftAlerts: false }));
        }
    }

    async function didLoadDraftUpdates(updates) {
        updates = await filterRegionsNotCovered(updates);

        if (updates !== undefined && updates !== null) {
            setDraftUpdates(markUpdatesAsUpdates(updates));
            setLoading((loading) => ({ ...loading, draftUpdates: false }));
        }
    }

    function regionSelect(selectedList, selectedItem) {
        threatsNamespace.emit('assignToRegion', selectedItem);
    }

    function regionRemove(selectedList, removedItem) {
        threatsNamespace.emit('removeFromRegion', removedItem);
    }

    return (
        <div>
            <div className="IntelCenter-panel-options">
                <RegionSelector
                    regions={regions}
                    onSelect={regionSelect}
                    onRemove={regionRemove}
                    baseClassName={'RegionSelector'}
                    app="ic"
                />
                {authInfo.role !== READ_ONLY && (
                    <ICButton
                        key={`threat_filter_button`}
                        color="grey"
                        className="Filter-button"
                        data-testid={TEST_ATTRIBUTES.BUTTONS.ALERT_FILTER}
                        onClick={() => {
                            dispatch(
                                toggleModal({
                                    data: {},
                                    modalName: MODALS.ALERT_FILTERS,
                                    value: true
                                })
                            );
                        }}
                    >
                        <i className="fa fa-filter" /> Filters
                    </ICButton>
                )}
            </div>
            <div className="IntelCenter-panels">
                <Container fluid>
                    <Row>
                        {_.includes(
                            [LEAD_ANALYST, ANALYST, ADMINISTRATOR],
                            authInfo.role
                        ) &&
                            authInfo.regions &&
                            authInfo.regions.length > 0 && (
                                <Col sm={4}>
                                    {_.includes(
                                        [LEAD_ANALYST, ANALYST, ADMINISTRATOR],
                                        authInfo.role
                                    ) && (
                                        <MultiView
                                            draftAlerts={draftAlerts}
                                            draftUpdates={draftUpdates}
                                            loading={loading}
                                        />
                                    )}
                                </Col>
                            )}
                        {_.includes(
                            [LEAD_ANALYST, ANALYST, READ_ONLY, ADMINISTRATOR],
                            authInfo.role
                        ) && (
                            <Col>
                                <AlertList
                                    title="Approval"
                                    type="approvalList"
                                    allowedRoles={[
                                        LEAD_ANALYST,
                                        ANALYST,
                                        READ_ONLY,
                                        ADMINISTRATOR
                                    ]}
                                    alerts={[
                                        ...approvalAlerts,
                                        ...approvalUpdates
                                    ]}
                                    isLoading={
                                        loading.approvalAlerts ||
                                        loading.approvalUpdates
                                    }
                                    dateField="created_date"
                                />
                                <ApprovalAudio
                                    alerts={[
                                        ...approvalAlerts,
                                        ...approvalUpdates,
                                        ...draftAlerts,
                                        ...draftUpdates
                                    ]}
                                    isLoading={
                                        loading.approvalAlerts ||
                                        loading.approvalUpdates ||
                                        loading.draftAlerts ||
                                        loading.draftUpdates
                                    }
                                />
                            </Col>
                        )}
                        {_.includes(
                            [LEAD_ANALYST, ANALYST, READ_ONLY, ADMINISTRATOR],
                            authInfo.role
                        ) && (
                            <Col>
                                <AlertList
                                    title="Active"
                                    type="activeList"
                                    allowedRoles={[
                                        LEAD_ANALYST,
                                        ANALYST,
                                        READ_ONLY,
                                        ADMINISTRATOR
                                    ]}
                                    alerts={activeAlerts}
                                    isLoading={loading.activeAlerts}
                                    dateField="published_date"
                                />
                            </Col>
                        )}
                        {_.includes(
                            [LEAD_ANALYST, ANALYST, READ_ONLY, ADMINISTRATOR],
                            authInfo.role
                        ) && (
                            <Col>
                                <AlertList
                                    title="Expiring"
                                    type="expiringList"
                                    alerts={expiringAlerts}
                                    isLoading={loading.expiringAlerts}
                                    dateField="end_date"
                                />
                            </Col>
                        )}
                        {authInfo.role === READ_ONLY &&
                            !_.isEmpty(activeAlerts) &&
                            !_.isEmpty(authInfo.regions) && (
                                <Col sm={6}>
                                    <ReadOnlyAlertList
                                        key="readOnlyAlertList"
                                        alerts={activeAlerts}
                                        isLoading={loading.activeAlerts}
                                    />
                                </Col>
                            )}
                    </Row>
                </Container>
            </div>
        </div>
    );
}
