import '../../styling/Login.css';

import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    forgotPassword,
    forgotPasswordVerificationSubmit,
    resetPassword,
    signIn
} from '../../awsAmplifyUtils';
import { getRuntimeValue } from '../../config/runtimeConfig';
import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { setUserSession } from '../../redux/actions';
import ICButton from '../Buttons/presentational/ICButton';
import Header from './Header';

export default function Login() {
    const dispatch = useDispatch();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [mustResetPassword, setMustResetPassword] = useState(false);
    const [hasForgottenPassword, setHasForgottenPassword] = useState(false);
    const [forgotPasswordConfirmationCode, setForgotPasswordConfirmationCode] =
        useState('');
    const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [userResettingPassword, setUserResettingPassword] = useState(false);

    const loginPage = useRef();

    const emailRef = useRef();
    const passwordRef = useRef();

    const isTestMode = async () => {
        // TODO: This is temporary to get by connectivity issues with
        //  pipeline cypress to Cognito
        let appEnv = await getRuntimeValue('APP_ENV');
        return appEnv === 'cypress';
    };

    const handleLogin = async () => {
        setError('');
        setLoading(true);
        try {
            let userSession = {};
            if (mustResetPassword) {
                if (newPassword !== newPasswordConfirm) {
                    setError('Passwords do not match');
                    setLoading(false);
                    return;
                }
                userSession = await resetPassword(
                    userResettingPassword,
                    newPassword
                );
            } else {
                if (!emailRef.current || !passwordRef.current) {
                    // These are not defined, just return instead of throwing error
                    // that the value is null
                    return;
                }
                const refEmail = emailRef.current.value;
                const refPassword = passwordRef.current.value;
                if (await isTestMode()) {
                    userSession = {
                        email: refEmail,
                        pws: refPassword
                    };
                } else {
                    /**
                     * When a new password is required user will take the form of
                     * {
                     *   challengeName: 'NEW_PASSWORD_REQUIRED',
                     *   attributesRequired: [],
                     *   attributesGiven: { email: 'some.user.email@aplaceofwork.com' }
                     * }
                     * */
                    userSession = await signIn(refEmail, refPassword);
                    console.log('Login: user from signin', userSession);
                    if (
                        userSession.challengeName &&
                        userSession.challengeName === 'NEW_PASSWORD_REQUIRED'
                    ) {
                        setUserResettingPassword(
                            userSession.attributesGiven.email
                        );
                        setMustResetPassword(true);
                        setLoading(false);
                        setError(
                            'You have entered a temporary password.  Please reset it.'
                        );
                        return;
                    }
                }
            }
            let sessionEmail;
            let token;
            let group = 'Read-Only';
            if (await isTestMode()) {
                sessionEmail = userSession.email;
                window.localStorage.setItem('sessionEmail', sessionEmail);
            } else {
                try {
                    group = userSession.idToken.payload['cognito:groups'][0];
                } catch (e) {
                    console.log(
                        'User does not have a group assigned default to Read-Only'
                    );
                }
                // User may have logged in with User-Name instead of Email - get it from the session token
                sessionEmail = userSession.idToken.payload['email'];
                setLoading(false);
                token = userSession.idToken.jwtToken;

                dispatch(setUserSession({ sessionEmail, group, token }));
            }
        } catch (e) {
            // The purpose of this conditional is AWS returns 2 different error states for an
            // invalid username and an invalid password. Thats not great security practice so
            // I am setting the message to be the same between the two
            if (
                e.code === 'UserNotFoundException' ||
                e.event === 'signIn_failure'
            ) {
                setError('Invalid username or password');
            } else if (e.code === 'PasswordResetRequiredException') {
                // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/adminresetuserpasswordcommand.html
                // Admin has reset this user's password, they are directed to the "forgot password" flow to reset it
                setForgotPasswordFlow(
                    'The Administrator has reset your password, please check your email for a verification code'
                );
                setLoading(false);
                setError(false);
                handleForgotPassword();
                return;
            } else if (
                // Not sure why we get this error during the new user password reset flow but a refressh will fix it.
                error === 'InvalidParameterException: Invalid device key given.'
            ) {
                setLoading(false);
                setMustResetPassword(false);
                setError(
                    'Password reset successfully, Please login or refresh page.'
                );
            } else {
                // We have a really odd scenario where sometimes users log in for the first time
                // And Cognito claims "Invalid device key given".  A refresh just clears this and it's not clear
                // why we're even getting here.  So - force a refresh and move on
                if (e.message.startsWith('Invalid device key given')) {
                    window.location.reload();
                    return;
                }
                setError(e.message);
            }
            setLoading(false);
        }
    };

    const handleForgotPasswordVerificationSubmit = async () => {
        const email = emailRef.current.value;

        try {
            setLoading(true);
            await forgotPasswordVerificationSubmit(
                email,
                forgotPasswordConfirmationCode,
                newPassword
            );
        } catch (e) {
            setError(`Error resetting password: ${e.message}`);
            setLoading(false);
            return;
        }
        setForgotPasswordConfirmationCode('');
        setNewPasswordConfirm('');
        setNewPassword('');
        setError(false);
        setLoading(false);
        setHasForgottenPassword(false);
        setForgotPasswordFlow('Password reset successfully, please login');
    };

    const handleForgotPasswordSubmit = async () => {
        const email = emailRef.current.value;

        try {
            setLoading(true);
            await forgotPassword(email);
        } catch (e) {
            setError(`Error resetting password: ${e.message}`);
            setLoading(false);
            return;
        }
        setError(false);
        setLoading(false);
        setForgotPasswordFlow(
            'Please check your email for a verification code'
        );
    };

    const handleForgotPassword = () => {
        const email = emailRef.current.value;
        if (!email) {
            setError('Please enter an email');
            return;
        }

        setHasForgottenPassword(true);
        setError('');
    };

    const handleCancelForgotPassword = () => {
        setHasForgottenPassword(false);
        setError('');
    };

    const renderForgotPasswordInteractions = () => {
        if (!forgotPasswordFlow) {
            return (
                <span>
                    <div className="Login-forgot-password-link">
                        <span
                            className="Login-forgot-password"
                            onClick={handleCancelForgotPassword}
                        >
                            Cancel Forgot Password
                        </span>
                    </div>
                    <button
                        type="submit"
                        onClick={handleForgotPasswordSubmit}
                        className="Login-button"
                        data-testid={TEST_ATTRIBUTES.ALERT_FORM.SUBMIT}
                    >
                        Forgot Password
                    </button>
                </span>
            );
        }
        return (
            <div className={'Login-form-group'}>
                <span className="Login-password-reset-flow">
                    {forgotPasswordFlow}
                </span>
                <input
                    id="passwordResetConfirmationCode"
                    className="Login-form-control"
                    onChange={(e) =>
                        setForgotPasswordConfirmationCode(e.target.value)
                    }
                    value={forgotPasswordConfirmationCode}
                    placeholder="Verification Code"
                    data-testid={TEST_ATTRIBUTES.ALERT_FORM.HEADLINE}
                />

                {renderResetPasswordInputs()}
                <button
                    type="submit"
                    onClick={handleForgotPasswordVerificationSubmit}
                    className="Login-button"
                    data-testid={TEST_ATTRIBUTES.ALERT_FORM.SUBMIT}
                >
                    {loading ? (
                        <i className="fa fa-spinner fa-spin" />
                    ) : (
                        'Reset Password'
                    )}
                </button>
            </div>
        );
    };

    const renderResetPasswordInputs = () => {
        return (
            <span>
                <label htmlFor="ICLoginNewPwd">New Password</label>
                <input
                    type="password"
                    id="ICLoginNewPwd"
                    className="Login-form-control"
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    autoComplete="new-password"
                    data-testid={
                        TEST_ATTRIBUTES.ALERT_FORM.RESET_PASSWORD_PASSWORD_FIELD
                    }
                />
                <label htmlFor="ICLoginConfPwd">Confirm Password</label>
                <input
                    type="password"
                    id="ICLoginConfPwd"
                    className="Login-form-control"
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    data-testid={
                        TEST_ATTRIBUTES.ALERT_FORM
                            .RESET_PASSWORD_CONFIRM_PASSWORD_FIELD
                    }
                />
            </span>
        );
    };

    return (
        <div ref={loginPage} className="Login-page">
            <div className="Login-card">
                <Header login={true} />
                <div className="Login-form-group">
                    <input
                        ref={emailRef}
                        type="email"
                        id="ICLoginEmail"
                        className="Login-form-control"
                        placeholder="Email Address"
                        data-testid={TEST_ATTRIBUTES.ALERT_FORM.EMAIL}
                    />
                </div>
                {!mustResetPassword && !hasForgottenPassword && (
                    <span>
                        <div className="Login-form-group">
                            <input
                                ref={passwordRef}
                                type="password"
                                id="ICLoginPwd"
                                className="Login-form-control"
                                placeholder="Password"
                                data-testid={
                                    TEST_ATTRIBUTES.ALERT_FORM.PASSWORD
                                }
                            />
                        </div>
                        <div className="Login-forgot-password-link">
                            <span
                                className="Login-forgot-password"
                                onClick={handleForgotPassword}
                            >
                                Forgot Password
                            </span>
                        </div>
                    </span>
                )}
                <div className="Login-form-error">{error}</div>
                {!hasForgottenPassword && forgotPasswordFlow && (
                    <div className="Login-password-reset-flow">
                        {forgotPasswordFlow}
                    </div>
                )}

                {mustResetPassword && (
                    <div className="Login-form-group">
                        {renderResetPasswordInputs()}
                    </div>
                )}
                {hasForgottenPassword ? (
                    renderForgotPasswordInteractions()
                ) : (
                    <ICButton
                        color="blue"
                        onClick={handleLogin}
                        className="Login-button"
                        data-testid={TEST_ATTRIBUTES.ALERT_FORM.SUBMIT}
                    >
                        {loading ? (
                            <i className="fa fa-spinner fa-spin" />
                        ) : mustResetPassword ? (
                            'Set New Password'
                        ) : (
                            'Login'
                        )}
                    </ICButton>
                )}
            </div>
        </div>
    );
}
