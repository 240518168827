import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import {
    getGroups,
    getNamespace,
    getUserGroups
} from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';
import ListSelector from '../../ContentManagement/shared/ListSelector';

const {
    THREAT_DECK: { USER_GROUPS }
} = TEST_ATTRIBUTES;

export default function ThreatDeckGroupForm({ closeModal, group = {} }) {
    // REDUX
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );
    const allGroups = useSelector(getGroups);
    const userGroups = useSelector(getUserGroups);

    // STATE
    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        if (userGroups) {
            setSelectedGroups(userGroups);
        }
    }, [userGroups]);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('saveUserGroupsFailed', () => {
                confirmAlert({
                    title: 'Save Failed',
                    message: 'There was an error.',
                    buttons: [
                        {
                            label: 'No',
                            onClick: () => {}
                        }
                    ]
                });
            });
        }
        return () => {
            threatDeckNamespace.off('saveUserGroupsFailed');
        };
    }, []);

    function clearAndCloseModal() {
        clearData();
        closeModal();
    }

    function clearData() {
        setSelectedGroups([]);
    }

    function saveUserGroups() {
        threatDeckNamespace.emit('saveUserGroups', map(selectedGroups, 'id'));
        closeModal();
    }

    function updateGroups(selectedGroups) {
        setSelectedGroups(selectedGroups);
    }

    return (
        <div>
            <div className="ThreatDeckGroupForm-header">
                <h2>{`View Groups`}</h2>
                <button
                    className="ReactModal__CloseButton"
                    onClick={clearAndCloseModal}
                    data-testid={`${USER_GROUPS.BUTTONS.CLOSE_MODAL}`}
                >
                    <i className="fa fa-window-close" />
                </button>
            </div>

            <div className="ContentManagementModal-body">
                <ListSelector
                    all={allGroups}
                    available={allGroups}
                    selected={selectedGroups}
                    displayLabel={'name'}
                    onChange={updateGroups}
                />

                <div className="ThreatDeck-group-form-footer row">
                    <div className="group-footer-right">
                        <ICButton
                            color="white"
                            onClick={clearAndCloseModal}
                            data-testid={`${USER_GROUPS.BUTTONS.CANCEL}`}
                        >
                            Cancel
                        </ICButton>
                        <ICButton
                            color="blue"
                            onClick={saveUserGroups}
                            data-testid={USER_GROUPS.BUTTONS.SAVE}
                        >
                            Save
                        </ICButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
