import { Multiselect } from 'multiselect-react-dropdown';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import {
    MULTISELECT_COMPONENT_STYLES,
    NAMESPACES
} from '../../config/constants';
import { getNamespace } from '../../redux/selectors';
import ICButton from '../Buttons/presentational/ICButton';

export default function EventMergeFields({ event, mergeList, setMergeList }) {
    // state
    const [selectedEventToMerge, setSelectedEventToMerge] = useState(null);

    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const handleMergeEvents = () => {
        if (!selectedEventToMerge) {
            return;
        }
        confirmAlert({
            title: 'Confirm Merge',
            message: `Are you sure you want to merge these events?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        threatDeckNamespace.emit('mergeEvents', {
                            destinationId: event?.id,
                            mergedId: selectedEventToMerge.id
                        });
                        setMergeList(undefined);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };

    const onSelectedEventToMerge = (selectedList, item) => {
        setSelectedEventToMerge(item);
    };

    return (
        <div className="ThreatDeckEventDetails-merge">
            <Row>
                <Col>
                    <hr />
                    <div>
                        Select an event to merge <strong>into</strong> this
                        event:
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <Multiselect
                            id={`eventMergeMultiselect${event?.id}`}
                            selectionLimit={1}
                            options={mergeList}
                            onSelect={onSelectedEventToMerge}
                            displayValue="title"
                            singleSelect={true}
                            placeholder="Events"
                            avoidHighlightFirstOption={true}
                            style={MULTISELECT_COMPONENT_STYLES}
                            selectedValues={[]}
                        />
                    </div>
                </Col>
                <Col xs={3}>
                    <div className={'ThreatDeckEventDetails-merge-buttons'}>
                        <ICButton onClick={handleMergeEvents} color="green">
                            Save
                        </ICButton>
                        <ICButton
                            onClick={() => setMergeList(undefined)}
                            color="white"
                        >
                            Cancel
                        </ICButton>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
