import '../../../styling/ThreatDeckCard.css';

import moment from 'moment-timezone';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../../config/testConstants';

export default function ThreatDeckCardTimestamp({ item }) {
    const reported = item?.published_date
        ? moment(item.published_date).format('MMM Do, hh:mm A')
        : 'N/A';

    return (
        <>
            {reported && (
                <div
                    className="ThreatDeckCard-timestamp"
                    data-testid={TEST_ATTRIBUTES.THREAT_DECK.CARDS.TIMESTAMP}
                >
                    Reported: {reported}
                </div>
            )}
        </>
    );
}
