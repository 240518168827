import '../../../styling/ThreatDeckSources.css';

import React, { useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { NAMESPACES, ROLES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import {
    getApplicationRegions,
    getAuthInfo,
    getNamespace
} from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';
import ICTable from '../../ICTable';
import TwitterKnownAccountForm from './TwitterKnownAccountForm';

const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;

export default function TwitterKnownAccounts() {
    const authInfo = useSelector(getAuthInfo);
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );
    const regionOptions = useSelector(getApplicationRegions);

    const [twitterAccounts, setTwitterAccounts] = useState([]);
    const [createTwitterAccountActive, setCreateTwitterAccountActive] =
        useState(false);
    const [twitterAccountToEdit, setTwitterAccountToEdit] = useState(null);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('knownTwitterAccounts', setTwitterAccounts);
            threatDeckNamespace.emit('getKnownTwitterAccountsList');
            threatDeckNamespace.emit('getRegionList');
        }

        // component cleanup, cancel socket listeners
        // this prevents setting state on unmounted components
        return () => {
            threatDeckNamespace.off('knownTwitterAccounts');
        };
    }, [threatDeckNamespace]);

    function editTwitterAccount(item) {
        if (authInfo.role === LEAD_ANALYST || authInfo.role === ADMINISTRATOR) {
            console.log({ item });
            // Let's make sure region is not null
            if (!item.region) {
                item.region = { id: 1, name: 'Unknown' };
            }
            setTwitterAccountToEdit(item);
            setCreateTwitterAccountActive(!createTwitterAccountActive);
        }
    }

    function deleteTwitterAccount(item) {
        if (authInfo.role === ADMINISTRATOR) {
            threatDeckNamespace.emit('deleteKnownTwitterAccount', {
                id: item.id
            });
            threatDeckNamespace.emit('getKnownTwitterAccountsList');
        }
    }

    function toggleTwitterAccountFollowing(item) {
        if (authInfo.role === LEAD_ANALYST || authInfo.role === ADMINISTRATOR) {
            threatDeckNamespace.emit('setKnownTwitterAccountFollowed', {
                id: item.id,
                follow: !item.follow
            });
        }
    }

    const twitterAccountColumns = useMemo(() => {
        return [
            {
                Header: 'Account Handle',
                accessor: 'handle',
                customColumnRender: {
                    render: ({ item }) => {
                        return (
                            <span>
                                {item.handle}
                                <ICButton
                                    key={`twitter_account_${item.handle}`}
                                    color="white"
                                    className="ThreatDeckSource-edit-button"
                                    data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.OPEN_ACCOUNT}-${item.id}`}
                                    onClick={() =>
                                        window.open(
                                            `https://twitter.com/${item.handle}`,
                                            '_new'
                                        )
                                    }
                                >
                                    <span title={item.handle}>
                                        Open Twitter
                                    </span>
                                </ICButton>

                                {authInfo.role === ADMINISTRATOR && (
                                    <ICButton
                                        key={`twitter_account_delete_${item.id}`}
                                        color="red"
                                        className="ThreatDeckSource-delete-button"
                                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.DELETE_ACCOUNT}-${item.id}`}
                                        onClick={() => {
                                            confirmAlert({
                                                title: 'Confirm Delete',
                                                message: `Are you sure you want to delete this known account?`,
                                                buttons: [
                                                    {
                                                        label: 'Yes',
                                                        onClick: () =>
                                                            deleteTwitterAccount(
                                                                item
                                                            )
                                                    },
                                                    {
                                                        label: 'No',
                                                        onClick: () => {}
                                                    }
                                                ]
                                            });
                                        }}
                                    >
                                        Delete
                                    </ICButton>
                                )}
                                {(authInfo.role === LEAD_ANALYST ||
                                    authInfo.role === ADMINISTRATOR) && (
                                    <ICButton
                                        key={`twitter_account_edit_${item.id}`}
                                        color="blue"
                                        className="ThreatDeckSource-edit-button"
                                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.EDIT_ACCOUNT}-${item.id}`}
                                        onClick={() => {
                                            editTwitterAccount(item);
                                        }}
                                    >
                                        Edit
                                    </ICButton>
                                )}
                            </span>
                        );
                    },
                    args: { item: 'row.original' }
                }
            },
            { Header: 'Region', accessor: 'region.name' },
            {
                Header: 'Status',
                accessor: 'follow',
                Filter: false,
                customColumnRender: {
                    render: ({ item }) => {
                        let text = item.follow ? 'Following' : 'Not Following';
                        let color = item.follow ? 'green' : 'red';
                        return (
                            <ICButton
                                className="ThreatDeckSource-status-button"
                                key={`twitter_account_${item.id}_status`}
                                data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.STATUS_ACCOUNT}-${item.id}`}
                                disabled={
                                    authInfo.role !== LEAD_ANALYST &&
                                    authInfo.role !== ADMINISTRATOR
                                }
                                color={color}
                                onClick={() => {
                                    toggleTwitterAccountFollowing(item);
                                }}
                            >
                                {text}
                            </ICButton>
                        );
                    },
                    args: { item: 'row.original' }
                }
            }
        ];
    }, []);

    return (
        <Container className="ConfigureSystem-Container">
            {!createTwitterAccountActive && (
                <span>
                    <Row>
                        <Col>
                            <h1>Known Twitter Account List</h1>
                        </Col>
                        {(authInfo.role === LEAD_ANALYST ||
                            authInfo.role === ADMINISTRATOR) && (
                            <Col>
                                <ICButton
                                    color="green"
                                    className="ThreatDeckSource-create-button"
                                    data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.CREATE_ACCOUNT}`}
                                    onClick={() => {
                                        console.log({
                                            createTwitterAccountActive
                                        });
                                        setCreateTwitterAccountActive(
                                            !createTwitterAccountActive
                                        );
                                    }}
                                >
                                    Enter Known Account
                                </ICButton>
                            </Col>
                        )}
                    </Row>
                    <ICTable
                        columns={twitterAccountColumns}
                        data={twitterAccounts}
                        reference={'KnownTwitterAccounts'}
                    />
                </span>
            )}
            {createTwitterAccountActive && (
                <TwitterKnownAccountForm
                    setCreateTwitterAccountActive={
                        setCreateTwitterAccountActive
                    }
                    twitterAccountToEdit={twitterAccountToEdit}
                    setTwitterAccountToEdit={setTwitterAccountToEdit}
                    regions={regionOptions}
                />
            )}
        </Container>
    );
}
