import '../../../styling/ThreatDeckCard.css';

import React from 'react';

import { TEST_ATTRIBUTES } from '../../../config/testConstants';

export default function ThreatDeckCardConfidence({ confidence }) {
    // Currently not changing highlight based on confidence level
    function getConfidenceClass() {
        return 'default';
    }

    const cLevel = !isNaN(confidence)
        ? `${(confidence * 100).toFixed()}%`
        : 'N/A';

    const cClass = getConfidenceClass();

    return (
        <div
            className={`ThreatDeckCard-confidence highlight-${cClass}`}
            data-testid={TEST_ATTRIBUTES.THREAT_DECK.CARDS.CONFIDENCE}
        >
            {cLevel}
        </div>
    );
}
