import { map } from 'lodash';
import React from 'react';

import AlertPanel from '../../AlertPanel';

export default function SituationReportRelatedThreats({
    alerts = [],
    allowEdit = true,
    onRemoveFromSituation,
    openAlertsModal
}) {
    return (
        <div className="ContentForm-area">
            <div className="ContentForm-area-header">
                <h3>Related Threats</h3>
                {allowEdit && (
                    <div className="actions">
                        <a href="#" onClick={openAlertsModal}>
                            Add New
                        </a>
                    </div>
                )}
            </div>
            <div className="ContentForm-area-body">
                {alerts.length > 0 ? (
                    map(alerts, (alert) => {
                        return (
                            <AlertPanel
                                key={alert.id}
                                alert={alert}
                                dateField="published_date"
                                type={
                                    allowEdit
                                        ? 'contentManagementRemove'
                                        : 'presentational'
                                }
                                onRemoveFromSituation={onRemoveFromSituation}
                            />
                        );
                    })
                ) : (
                    <p>No threats have been added yet.</p>
                )}
            </div>
        </div>
    );
}
