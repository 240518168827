import React from 'react';

import ICButton from './ICButton';

export default function TriStateWhiteButton({
    dataTestId,
    onClick,
    inactive = false
}) {
    return (
        <ICButton
            color="white"
            onClick={inactive ? undefined : onClick}
            className={`${
                inactive ? 'TriStateWhiteButtonInactive' : 'TriStateWhiteButton'
            }`}
            data-testid={dataTestId}
        ></ICButton>
    );
}
