import React from 'react';
import { FiTrash2 } from 'react-icons/fi';

import ICButton from '../../Buttons/presentational/ICButton';

export default function ICTrashIcon({ 'data-testid': dataTestId, onClick }) {
    return (
        <ICButton
            color="red"
            onClick={onClick}
            className="ContentManagement-action-icon"
            data-testid={dataTestId}
        >
            <FiTrash2 size={20} />
        </ICButton>
    );
}
