import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { setAlertModal } from '../../../redux/actions';
import { getNamespace } from '../../../redux/selectors';
import ICButton from '../presentational/ICButton';

export default function DeleteAlertDraftButton({
    alert,
    onAfterClick,
    isUpdate
}) {
    // redux
    const dispatch = useDispatch();
    function displayAlertModal({ alert }) {
        dispatch(setAlertModal({ alert }));
    }
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    function deleteDraft() {
        confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure you want to delete draft of ${alert.title}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let socketEmit =
                            alert.isUpdate || isUpdate
                                ? 'deleteDraftUpdate'
                                : 'deleteDraftAlert';
                        displayAlertModal({ alert: false });
                        if (onAfterClick) {
                            onAfterClick();
                        }
                        threatsNamespace.emit(socketEmit, alert.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    return (
        <ICButton
            onClick={deleteDraft}
            color="dark-red"
            data-testid={`${TEST_ATTRIBUTES.BUTTONS.DELETE_DRAFT}-${alert.id}`}
        >
            Delete Draft
        </ICButton>
    );
}
