import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// import ic_rejected_approval from '../assets/ic_rejected_long.mp3'
import ic_rejected_approval from '../assets/ic_rejected_short.mp3';
// import ic_requested_approval from '../assets/ic_requested_long.mp3'
import ic_requested_approval from '../assets/ic_requested_short.mp3';
import { ROLES } from '../config/constants';
import { getAuthInfo } from '../redux/selectors';

const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;

export default function ApprovalAudio({ alerts, isLoading }) {
    const rejectedApprovalSound = useRef();
    const requestedApprovalSound = useRef();
    const authInfo = useSelector(getAuthInfo);
    const { user } = authInfo;

    const pendingStorageName = 'PENDING_APPROVAL';
    const rejectedStorageName = 'REJECTED_APPROVAL';

    const [pendingStorage, setPendingStorage] = useState();
    const [rejectedStorage, setRejectedStorage] = useState();

    useEffect(() => {
        rejectedApprovalSound.current = new Audio(ic_rejected_approval);
        requestedApprovalSound.current = new Audio(ic_requested_approval);

        setPendingStorage(sessionStorage.getItem(pendingStorageName));
        setRejectedStorage(sessionStorage.getItem(rejectedStorageName));
    }, []);

    function playSounds(audioSourceArray) {
        if (_.isEmpty(audioSourceArray)) {
            return;
        }
        // volume defaults to 1.0 and is too loud
        const audioSource = audioSourceArray.pop();

        audioSource.volume = 0.5;
        audioSource.muted = false;
        audioSource.onended = () => {
            if (audioSourceArray.length) {
                this.playSounds(audioSourceArray);
            }
        };
        try {
            // This will sometimes fail because the user has not interacted with the page yet.
            audioSource.play();
        } catch (error) {
            // This can be pretty noisy, but it's better to have insight into why the sound isn't playing.
            // There's also not a lot that can be done about autoplay being disabled by the browser.
            console.log(`Failed to play sound: ${error.message}`);
        }
    }

    function getPendingAlerts(alerts, user) {
        return _(alerts)
            .filter((alert) => {
                // filter for alerts not created by the user and status 'PENDING'
                return (
                    _.includes([ADMINISTRATOR, LEAD_ANALYST], user.role) &&
                    alert.creator.email !== user.email &&
                    alert.status === 'PENDING'
                );
            })
            .orderBy('createdAt', 'desc')
            .value();
    }

    function getRejectedAlerts(alerts, user) {
        // filter for alerts created by the user and status 'DRAFT_REJECTED'
        return _(alerts)
            .filter((alert) => {
                return (
                    alert.creator.email === user.email &&
                    alert.status === 'DRAFT_REJECTED'
                );
            })
            .orderBy('createdAt', 'desc')
            .value();
    }

    function shouldPlaySound(storageName, incomingData = []) {
        let storageItem;
        try {
            storageItem = JSON.parse(sessionStorage.getItem(storageName));
        } catch (error) {
            console.log(`Failed to parse Session Storage, ${storageName}`);
        }
        const storageData = storageItem || [];

        // compare incoming data to what exists in session storage
        return _.size(_.differenceBy(incomingData, storageData, 'id'));
    }

    function setSessionStorage(storageName, data) {
        sessionStorage.setItem(
            storageName,
            JSON.stringify(
                _.map(data, (value) => {
                    return { id: value.id };
                })
            )
        );
    }

    useEffect(() => {
        if (!isLoading) {
            const pendingAlerts = getPendingAlerts(alerts, user);
            const rejectedAlerts = getRejectedAlerts(alerts, user);
            let allowRejectionNotifications =
                user.settings.allowRejectionNotifications;
            const soundsToPlay = [];
            if (_.size(rejectedAlerts)) {
                if (
                    shouldPlaySound(rejectedStorageName, rejectedAlerts) &&
                    allowRejectionNotifications
                ) {
                    soundsToPlay.push(rejectedApprovalSound.current);
                }
                // update session storage with the new rejected alerts
                setSessionStorage(rejectedStorageName, rejectedAlerts);
            }

            if (_.size(pendingAlerts)) {
                let playSound = shouldPlaySound(
                    pendingStorageName,
                    pendingAlerts
                );
                let allowApprovalNotifications =
                    user.settings.allowApprovalNotifications;

                if (playSound && allowApprovalNotifications) {
                    soundsToPlay.push(requestedApprovalSound.current);
                }
                // update session storage with the new pending alerts
                setSessionStorage(pendingStorageName, pendingAlerts);
            }

            // play the sounds in series
            playSounds(soundsToPlay);
        }
    }, [isLoading, alerts]);

    // We don't need any actual ui for this. Might actually be able to be converted to a hook.
    return <></>;
}
