import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reject from 'lodash/reject';
import snakeCase from 'lodash/snakeCase';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { TWITTER_RULES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getTwitterRules } from '../../../redux/selectors';

const {
    THREAT_DECK: {
        COLUMNS: { COLUMN_CONFIG }
    }
} = TEST_ATTRIBUTES;

export default function ThreatDeckColumnFormTwitterRules({
    onChange,
    followedAccountsFilter = false,
    selectedRules = []
}) {
    const twitterRules = useSelector(getTwitterRules);
    const [allRules, setAllRules] = useState([
        TWITTER_RULES.FOLLOWED_ACCOUNTS_PSEUDO_RULE,
        ...twitterRules
    ]);

    const [currentlySelectedRules, setCurrentlySelectedRules] = useState(
        followedAccountsFilter
            ? uniqBy(
                  // TODO use a better implementation that does not require uniqBy here
                  [
                      TWITTER_RULES.FOLLOWED_ACCOUNTS_PSEUDO_RULE,
                      ...selectedRules
                  ],
                  'tag'
              )
            : selectedRules
    );

    useEffect(() => {
        setAllRules([
            TWITTER_RULES.FOLLOWED_ACCOUNTS_PSEUDO_RULE,
            ...twitterRules
        ]);
    }, [twitterRules]);

    function selectRule(index) {
        const rule = differenceBy(
            filter(allRules, 'active'),
            currentlySelectedRules,
            'tag'
        )[index];

        setCurrentlySelectedRules([...currentlySelectedRules, rule]);
        onChange([...currentlySelectedRules, rule]);
    }

    function deSelectRule(index) {
        let rule = currentlySelectedRules[index];
        setCurrentlySelectedRules(
            reject(currentlySelectedRules, (r) => r.tag === rule.tag)
        );
        onChange(reject(currentlySelectedRules, (r) => r.tag === rule.tag));
    }

    return (
        <div className="ThreatDeck-input-container">
            <div className="ThreatDeck-row">
                <div className="ThreatDeck-column">
                    <div className={'ThreatDeck-twitter-rules-header'}>
                        Available Rules:
                    </div>
                    <div className="ThreatDeck-twitter-rules-container">
                        {differenceBy(allRules, currentlySelectedRules, 'tag')
                            .filter((rule) => rule.active)
                            .map((rule, index) => {
                                return (
                                    <div
                                        className="ThreatDeck-twitter-rules"
                                        data-testid={`${COLUMN_CONFIG.TWITTER_RULES.AVAILABLE}-${rule.tag}`}
                                        key={`${snakeCase(rule?.tag)}-${index}`}
                                        onClick={() => {
                                            selectRule(index);
                                        }}
                                    >
                                        {rule?.tag}
                                        {rule?.active ? '' : ' (inactive)'}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="ThreatDeck-column ThreatDeck-carets">
                    <IoChevronForward />
                    <IoChevronBack />
                </div>
                <div className="ThreatDeck-column">
                    <div className={'ThreatDeck-categories-header'}>
                        Selected Rules:
                    </div>
                    <div className="ThreatDeck-twitter-rules-container">
                        {map(currentlySelectedRules, (rule, index) => {
                            return (
                                <div
                                    className="ThreatDeck-twitter-rules"
                                    data-testid={`${COLUMN_CONFIG.TWITTER_RULES.SELECTED}-${rule.tag}`}
                                    key={`${snakeCase(rule.tag)}-${index}`}
                                    onClick={() => {
                                        deSelectRule(index);
                                    }}
                                >
                                    {rule.tag}
                                    {rule.active ? '' : ' (inactive)'}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
