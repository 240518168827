import { forEach, get, isEmpty, toString } from 'lodash';
import { matchSorter } from 'match-sorter';
import React from 'react';
import { useExpanded, useFilters, useTable } from 'react-table';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// pull the values out of cell that will be used for rendering the column
const getCustomColumnRenderArgs = ({ cell, args }) => {
    let renderArgs = {};
    forEach(Object.keys(args), (arg) => {
        renderArgs[arg] = get(cell, args[arg]);
    });
    return renderArgs;
};

export const renderCell = (cell) => {
    if (isEmpty(cell.value)) {
        if (cell.column.customColumnRender) {
            return cell.column.customColumnRender.render(
                getCustomColumnRenderArgs({
                    cell,
                    args: cell.column.customColumnRender.args
                })
            );
        }
        return '';
    }
    if (cell.column.customColumnRender) {
        return cell.column.customColumnRender.render(
            getCustomColumnRenderArgs({
                cell,
                args: cell.column.customColumnRender.args
            })
        );
    }
    return toString(cell.value);
};

export default function ({ columns, data, reference, renderRowSubComponents }) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
        // state // table state
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes
        },
        useFilters,
        useExpanded
    );

    return (
        <>
            <table className={'ICTable'} {...getTableProps()}>
                <thead className={'ICTable-head'}>
                    {headerGroups.map((headerGroup, headerIndex) => (
                        <tr
                            className={'ICTable-row'}
                            key={`${reference}:header:${headerIndex}`}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column, columnIndex) => (
                                <th
                                    className={'ICTable-th'}
                                    key={`${reference}:column:${columnIndex}`}
                                    {...column.getHeaderProps()}
                                >
                                    <div className={'ICTable-header'}>
                                        {column.render('Header')}
                                    </div>
                                    <div className={'ICTable-filter'}>
                                        {column.canFilter
                                            ? column.render('Filter')
                                            : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className={'ICTable-body'}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <React.Fragment
                                key={`ICTable-fragment-${rowIndex}`}
                            >
                                <tr
                                    className={'ICTable-row'}
                                    key={`${reference}:row:${rowIndex}`}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell, cellIndex) => {
                                        return (
                                            <td
                                                className={'ICTable-td'}
                                                key={`${reference}:cell:${cellIndex}`}
                                                {...cell.getCellProps(
                                                    (props) => {
                                                        return props;
                                                    }
                                                )}
                                            >
                                                {cell.column.accessor
                                                    ? cell.render(renderCell)
                                                    : cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {row.isExpanded &&
                                    renderRowSubComponents({
                                        row,
                                        rowProps: row.getRowProps()
                                    })}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
