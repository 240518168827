import _ from 'lodash';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../../../config/testConstants';

export default function MediaInput({
    media,
    handleFileUpload,
    allowEdit,
    isLoadingS3Response,
    s3Response
}) {
    return (
        <div className="ContentForm-media-container">
            <input
                type="file"
                disabled={!allowEdit}
                data-testid={TEST_ATTRIBUTES.CONTENT_MANAGEMENT.ARTICLE_MEDIA}
                id="content-article-media"
                onChange={handleFileUpload}
                accept="image/*"
            />
            {!_.isEmpty(_.get(media, ['tagSet', '30x30'])) && (
                <img
                    height={30}
                    width={30}
                    className="ContentForm-media-thumbnail"
                    data-testid={
                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                            .ARTICLE_MEDIA_THUMBNAIL
                    }
                    src={_.get(media, ['tagSet', '30x30'])}
                />
            )}
            {isLoadingS3Response && (
                <div>
                    <i
                        data-testid={
                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                .ARTICLE_MEDIA_LOADING
                        }
                        className="fa fa-spinner fa-spin"
                    />
                </div>
            )}
            {s3Response.error && (
                <span
                    className="content-article-media-tooltip"
                    data-testid={
                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT.ARTICLE_MEDIA_ERROR
                    }
                >
                    Failed: {s3Response.error}
                </span>
            )}
        </div>
    );
}
