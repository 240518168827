import './styling/IntelCenterApp.css';

import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IntelCenter from './components/IntelCenter';
import Login from './components/shared/Login';
import { APPS, NAMESPACES } from './config/constants';
import { useCognitoSession } from './hooks/useCognitoSession';
import { useSocketManager } from './hooks/useSocketManager';
import {
    setApplication,
    setApplicationRegions,
    setApplicationUsers,
    setCapabilitiesList,
    setCategoriesList,
    setUser,
    setUserRegions,
    setUserRole,
    socketsConnected
} from './redux/actions';
import {
    checkSocketConnections,
    checkSocketsReconnecting,
    getAuthInfo,
    getLoggedInUser,
    getNamespace,
    getSocketManager,
    getUserSession
} from './redux/selectors';

const {
    BRIEFS_NAMESPACE,
    CHAT_NAMESPACE,
    SITUATIONS_NAMESPACE,
    THREATS_NAMESPACE,
    USERS_NAMESPACE
} = NAMESPACES;

export default function IntelCenterApp() {
    // Redux
    const authInfo = useSelector(getAuthInfo);
    const areSocketsConnected = useSelector(checkSocketConnections);
    const areSocketsReconnecting = useSelector(checkSocketsReconnecting);
    const user = useSelector(getLoggedInUser);
    const dispatch = useDispatch();
    // React Router
    const navigate = useNavigate();
    useEffect(() => {
        if (authInfo?.role === 'Labeler' && userIsLoggedIn(authInfo?.user)) {
            navigate('/401');
        }
    }, [authInfo, navigate]);

    const userSession = useSelector(getUserSession);
    const { isCheckingSession } = useCognitoSession({
        dependencies: []
    });

    const socketManager = useSelector(getSocketManager);
    useSocketManager({
        url: process.env.REACT_APP_SOCKET_ENDPOINT,
        namespaces: [
            {
                namespace: USERS_NAMESPACE,
                onConnect: (socket) => {
                    console.log(
                        `${socket.id} successfully connected to ${USERS_NAMESPACE}`
                    );

                    // It's good to know this exist if we need it
                    // but we currently watch when a socket changes state and get fresh data then.
                    if (socket.recovered) {
                        // any event missed during the disconnection period will be received now
                    } else {
                        // new or unrecoverable session
                        // get fresh data when a socket reconnects
                        socket.emit('getUsersList');
                    }
                }
            },
            {
                namespace: THREATS_NAMESPACE,
                onConnect: (socket) => {
                    console.log(
                        `${socket.id} successfully connected to ${THREATS_NAMESPACE}`
                    );

                    // It's good to know this exist if we need it
                    // but we currently watch when a socket changes state and get fresh data then.
                    if (socket.recovered) {
                        // any event missed during the disconnection period will be received now
                    } else {
                        // new or unrecoverable session
                        // get fresh data when a socket reconnects
                    }
                }
            },
            { namespace: CHAT_NAMESPACE },
            { namespace: BRIEFS_NAMESPACE },
            { namespace: SITUATIONS_NAMESPACE }
        ],
        options: {},
        dependencies: []
    });

    useEffect(() => {
        if (!areSocketsConnected) {
            console.log('One or more sockets not connected: ', {
                socketManager
            });

            // check namespace socket connections
        } else {
            console.log('All sockets connected');
        }
    }, [areSocketsConnected, socketManager]);

    const usersNamespace = useSelector(getNamespace(USERS_NAMESPACE));
    useEffect(() => {
        if (usersNamespace && areSocketsConnected) {
            usersNamespace.on('usersList', (userList) => {
                dispatch(setApplicationUsers(userList));
            });
            usersNamespace.on(
                'applicationData',
                ({
                    userInfo,
                    userRegions,
                    applicationRegions,
                    applicationCategories,
                    applicationCapabilities
                }) => {
                    // Load user-dependent data needed throughout the whole app
                    dispatch(setUser(userInfo));
                    dispatch(setUserRole(userInfo.role));

                    // no need to update anything that doesn't have any data
                    if (!isEmpty(userRegions)) {
                        dispatch(setUserRegions(userRegions));
                    }
                    if (!isEmpty(applicationRegions)) {
                        dispatch(setApplicationRegions(applicationRegions));
                    }
                    if (!isEmpty(applicationCategories)) {
                        dispatch(setCategoriesList(applicationCategories));
                    }
                    if (!isEmpty(applicationCapabilities)) {
                        dispatch(setCapabilitiesList(applicationCapabilities));
                    }
                }
            );
            usersNamespace.on('userInfo', (userInfo) => {
                dispatch(setUser(userInfo));
            });

            usersNamespace.emit('getApplicationData');
            usersNamespace.emit('getUsersList');
        }

        return () => {
            if (usersNamespace) {
                usersNamespace.off('getApplicationData');
                usersNamespace.off('usersList');
                usersNamespace.off('userInfo');
            }
        };
    }, [usersNamespace, areSocketsConnected]);

    const threatsNamespace = useSelector(getNamespace(THREATS_NAMESPACE));
    useEffect(() => {
        if (threatsNamespace) {
            threatsNamespace.on('connect', () => {
                console.log(
                    `${threatsNamespace.id} successfully connected to ${THREATS_NAMESPACE}`
                );
            });

            // these all get sent back in the applicationData event
            // threatsNamespace.on('capabilitiesList', (capabilities) => {
            //     dispatch(setCapabilitiesList(capabilities));
            // });
            // threatsNamespace.on('regionList', (regions) => {
            //     dispatch(setApplicationRegions(regions));
            // });
            // threatsNamespace.on('categoriesList', (categories) => {
            //     dispatch(setCategoriesList(categories));
            // });
            // threatsNamespace.emit('getCapabilities');
            // threatsNamespace.emit('getRegionList');
            // threatsNamespace.emit('getCategories');
        }

        return () => {
            if (threatsNamespace) {
                // threatsNamespace.off('capabilitiesList');
                // threatsNamespace.off('regionList');
                // threatsNamespace.off('categoriesList');
            }
        };
    }, [threatsNamespace]);

    const situationsNamespace = useSelector(getNamespace(SITUATIONS_NAMESPACE));
    useEffect(() => {
        if (situationsNamespace) {
            situationsNamespace.on('connect', () => {
                console.log(
                    `${situationsNamespace.id} successfully connected to ${SITUATIONS_NAMESPACE}`
                );
            });
        }
    }, [situationsNamespace]);

    const briefsNamespace = useSelector(getNamespace(BRIEFS_NAMESPACE));
    useEffect(() => {
        if (briefsNamespace) {
            briefsNamespace.on('connect', () => {
                console.log(
                    `${briefsNamespace.id} successfully connected to ${BRIEFS_NAMESPACE}`
                );
            });
        }
    }, [briefsNamespace]);

    const chatNamespace = useSelector(getNamespace(CHAT_NAMESPACE));
    useEffect(() => {
        if (chatNamespace) {
            chatNamespace.on('connect', () => {
                console.log(
                    `${chatNamespace.id} successfully connected to ${CHAT_NAMESPACE}`
                );
            });
        }
    }, [chatNamespace]);

    useEffect(() => {
        if (socketManager) {
            dispatch(
                socketsConnected(
                    !isEmpty(socketManager.nsps) &&
                        every(socketManager.nsps, 'connected')
                )
            );
        } else {
            dispatch(socketsConnected(false));
        }
    }, [
        socketManager,
        usersNamespace,
        threatsNamespace,
        situationsNamespace,
        chatNamespace,
        briefsNamespace
    ]);

    useEffect(() => {
        async function setUp() {
            try {
                // TODO: Remove this down the road - it's pretty useful right now
                window.LOG_LEVEL = 'DEBUG';

                // Set application info
                dispatch(setApplication(APPS.IC));

                // Make sure title matches application
                document.title = APPS.IC.label;
            } catch (e) {
                console.log(e);
            }
        }
        setUp();
    }, []);

    function userIsLoggedIn(user) {
        return !isEmpty(user) && !!user?.id;
    }

    return (
        <div
            className={`IntelCenter${
                areSocketsConnected
                    ? ' socket-connected'
                    : ' socket-disconnected'
            }`}
        >
            {isCheckingSession ? (
                <div className="IntelCenter-loader">
                    <i className="fa fa-spinner fa-spin" />
                </div>
            ) : (userSession &&
                  userIsLoggedIn(user) &&
                  areSocketsReconnecting) ||
              (userSession && userIsLoggedIn(user) && areSocketsConnected) ? (
                <IntelCenter />
            ) : (
                <Login />
            )}
        </div>
    );
}
