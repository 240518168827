import React from 'react';
import { useDispatch } from 'react-redux';

import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { setAlertModal } from '../../../redux/actions';

/**
 * ReviewAlertButton component
 * when clicked, triggers a modal to open with the alert's info
 * @param {object} alert item being reviewed
 * @param {function} displayAlertModal opens the alert modal
 * @param {function} modalCallback used to hide the alert panel from the list when reviewed
 * @returns ReviewAlertButton component
 */
export default function ReviewAlertButton({ alert, modalCallback }) {
    // redux
    const dispatch = useDispatch();
    function displayAlertModal({ alert, editMode, isUpdate, modalCallback }) {
        dispatch(setAlertModal({ alert, editMode, isUpdate, modalCallback }));
    }

    const reviewAlert = () => {
        displayAlertModal({
            alert: alert.id,
            editMode: true,
            isUpdate: false,
            modalCallback
        });
    };

    return (
        <button
            onClick={reviewAlert}
            className={`Alert-button blue-button`}
            data-testid={`${TEST_ATTRIBUTES.BUTTONS.REVIEW}-${alert.id}`}
        >
            Review
        </button>
    );
}
