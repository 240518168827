import reverse from 'lodash/reverse';

import { COLUMNS, NAMESPACES } from '../config/constants';

const {
    BRIEFS_NAMESPACE,
    CHAT_NAMESPACE,
    SITUATIONS_NAMESPACE,
    THREATDECK_NAMESPACE,
    THREATS_NAMESPACE,
    USERS_NAMESPACE
} = NAMESPACES;

// AUTH selectors
// user selectors
export const getCognitoSession = (store) => store.auth.cognitoSession;
export const getUserSession = (store) => store.auth.userSession;
export const getLoggedInRole = (store) => store.auth.role;
export const getLoggedInUser = (store) => store.auth.user;
export const getLoggedInRegions = (store) => store.auth.regions;
export const getIsLoggedIn = (store) => store.auth.isLoggedIn;
export const getAuthInfo = (store) => {
    return {
        user: getLoggedInUser(store),
        role: getLoggedInRole(store),
        regions: getLoggedInRegions(store)
    };
};

// application selectors
export const getApplication = (store) => store.app.application;
export const getApplicationRegions = (store) => store.app.applicationRegions;
export const getApplicationUsers = (store) => store.app.users;
export const getModalState = (modalName) => (store) =>
    store.app.modals[modalName]; // currying to allow for a single selector to be used for multiple modals

// namespace selectors
export const getNamespace = (namespace) => (store) =>
    store.auth.sockets[namespace];
export const getChatNamespace = getNamespace(CHAT_NAMESPACE);
export const getBriefsNamespace = getNamespace(BRIEFS_NAMESPACE);
export const getSituationsNamespace = getNamespace(SITUATIONS_NAMESPACE);
export const getThreatDeckNamespace = getNamespace(THREATDECK_NAMESPACE);
export const getThreatsNamespace = getNamespace(THREATS_NAMESPACE);
export const getUsersNamespace = getNamespace(USERS_NAMESPACE);

// socket selectors
export const getSocketConnection = (store) => store.auth.socket;
export const checkSocketConnections = (store) => store.auth.areSocketsConnected;
export const getSocketManager = (store) => store.auth.socketManager;
export const checkSocketsReconnecting = (store) =>
    store.auth.areSocketsReconnecting;

export const getAlertModal = (store) => store.app.alertModal;
export const isAlertModalInEditMode = (store) => store.app.editMode;
export const getDraftAuthorContentFilter = (store) =>
    store.app.draftAuthorContentFilter;

export const getFilters = (modalType) => (store) => {
    if (!store.app[`${modalType}Filters`]?.categories) {
        let filters = {
            categories: {}
        };
        // Look for the data in local storage
        if (window.localStorage.getItem(`${modalType}Filters`)) {
            filters = JSON.parse(
                window.localStorage.getItem(`${modalType}Filters`)
            );
        }
        return filters;
    }
    return store.app.alertFilters;
};

export const isSavedBoundarySearchOpen = (store) =>
    store.app.savedBoundarySearch.active;
export const isSavedBoundarySearchEditMode = (store) =>
    store.app.savedBoundarySearch.editMode;

export const getCategoriesList = (store) => store.app.categories;
export const getEnabledCapabilities = (store) => store.app.enabledCapabilities;
export const getCapabilities = (store) => store.app.capabilities;
export const getCurrentView = (store) => store.app.currentView;
export const getChatMessages = (store) => store.app.chatMessages;

//Threat Deck
export const getAvailableGroupColumns = (store) =>
    store.app.availableGroupColumns;
export const getGroups = (store) => store.app.groups;
export const getUserGroupColumns = (store) => store.app.userGroupColumns;
export const getUserGroups = (store) => store.app.userGroups;
export const getThreatDeckFeedItems = (store) => store.app.threatdeckFeedItems;
export const getColumnContent = (contentType) => (store) => {
    if (contentType === COLUMNS.CONTENT_TYPE.EVENTS) {
        return reverse(Array.from(store.app.threatDeckEvents.values()));
    } else if (contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS) {
        return store.app.threatdeckFeedItems;
    }
};
export const getTwitterRules = (store) => store.app.twitterRules;
