import includes from 'lodash/includes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Manager } from 'socket.io-client';

import {
    logoutUser,
    setNamespaceSocket,
    setSocketManager,
    socketsConnected,
    socketsDisconnected,
    socketsReconnecting
} from '../redux/actions';
import {
    getCognitoSession,
    getSocketManager,
    getUserSession
} from '../redux/selectors';

async function initializeSocket(
    manager,
    dispatch,
    { namespace, onConnect, onUserInfo }
) {
    console.log(`Initializing socket namespace:`, { namespace });
    // available events: connect, disconnect, connect_error
    // useful attributes: socket.id, socket.connected, socket.disconnected, socket.recovered
    const namespaceSocket = await manager.socket(namespace);

    if (onUserInfo) {
        onUserInfo(namespaceSocket);
    }

    namespaceSocket.on('connect', () => {
        if (namespaceSocket.recovered) {
            console.log(`Namespace socket recovered: ${namespace}`, {
                namespaceSocket
            });
        }

        console.log(`Socket connected to namespace: ${namespace}`, {
            connected: namespaceSocket.connected
        });
        dispatch(setNamespaceSocket(namespace, namespaceSocket));
        dispatch(socketsConnected());

        if (onConnect) {
            onConnect(namespaceSocket);
        }
    });

    namespaceSocket.on('disconnect', (reason, details) => {
        console.log(`Socket disconnected from namespace: ${namespace}`, {
            reason,
            details
        });
        dispatch(socketsDisconnected());
        dispatch(socketsReconnecting());
    });

    namespaceSocket.on('connect_error', (error) => {
        if (namespaceSocket.active) {
            console.log(
                `Socket connect error. Temporary failure, will automatically try to reconnect: ${error.message}`
            );
        } else {
            // the connection was denied by the server
            // in that case, `socket.connect()` must be manually called in order to reconnect
            console.log(
                `Socket connect error. The connection was denied by the server and will not reconnect: ${error.message}`
            );
            dispatch(logoutUser());
        }

        console.log(`Socket connect error: ${error}`);
    });
}

function useSocketManager({ dependencies, namespaces, options, url }) {
    const cognitoSession = useSelector(getCognitoSession);
    const userSession = useSelector(getUserSession);
    const socketManager = useSelector(getSocketManager);

    const dispatch = useDispatch();

    useEffect(() => {
        if (userSession && cognitoSession) {
            if (!socketManager) {
                console.log(`Initializing socket manager`, { userSession });
                const manager = new Manager(url, {
                    path: '/socket',
                    reconnection: true,
                    reconnectionAttempts: Infinity,
                    reconnectionDelay: 10000,
                    randomizationFactor: 0.5,
                    query: `email=${userSession.sessionEmail}&group=${userSession.group}&token=${userSession.token}`,
                    ...options
                });

                manager.on('error', (error) => {
                    console.log(`Socket manager error: ${error}`, { error });
                    if (includes(error.message, 'xhr poll error')) {
                        dispatch(socketsReconnecting(true));
                    }
                });

                manager.on('reconnect', (attempt) => {
                    // At this point the manager is reconnected, but the sockets will still be reconnecting
                    // We will not update redux state until the sockets are reconnected
                    console.log(
                        `Socket manager reconnect successful on attempt #${attempt}`
                    );
                });

                manager.on('reconnect_error', (error) => {
                    console.log(`Socket manager reconnect error: ${error}`);
                });

                manager.on('reconnect_failed', () => {
                    console.log(`Socket manager reconnect failed`);
                });

                for (let socketNamespace of namespaces) {
                    initializeSocket(manager, dispatch, socketNamespace);
                }

                dispatch(setSocketManager(manager));
            }
        }

        // component will unmount
        return () => {};
    }, [socketManager, cognitoSession, userSession, ...dependencies]);

    // more values can be returned as needed.
}

export { useSocketManager };
