import '../../styling/AlertDetails.css';

import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../config/testConstants';
import AlertMap from '../AlertMap';

function ReadOnlyAlertDetails({ mostRecentAlertDetails }) {
    const dateFormat = 'MMM D, Y h:mm A z';
    const startDate = mostRecentAlertDetails.startDate
        ? moment.unix(mostRecentAlertDetails.startDate).format(dateFormat)
        : moment(mostRecentAlertDetails.start_date).format(dateFormat);
    const endDate = mostRecentAlertDetails.endDate
        ? moment.unix(mostRecentAlertDetails.endDate).format(dateFormat)
        : moment(mostRecentAlertDetails.end_date).format(dateFormat);

    return (
        !isEmpty(mostRecentAlertDetails) && (
            <>
                <h1
                    className="AlertDetails-title"
                    data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.TITLE}-${mostRecentAlertDetails.id}`}
                >
                    {mostRecentAlertDetails.title}
                </h1>
                <div>
                    <strong>From: </strong>
                    {startDate} - {endDate}
                </div>
                <div
                    className="Read-Only-map"
                    data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.MAP}-${mostRecentAlertDetails.id}`}
                >
                    <AlertMap
                        alert={mostRecentAlertDetails}
                        editMode={false}
                        readOnly={true}
                    />
                </div>
                <div
                    className="AlertDetails-section"
                    data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.MESSAGE}-${mostRecentAlertDetails.id}`}
                >
                    <div
                        key={`alertDetails_text`}
                        dangerouslySetInnerHTML={{
                            __html: mostRecentAlertDetails.message
                        }}
                    />
                </div>
                {mostRecentAlertDetails.details && (
                    <div className="AlertDetails-section">
                        {mostRecentAlertDetails.details.map((detail, index) => (
                            <div key={`AlertDetails-section-${index}`}>
                                <strong>{detail.label}</strong>
                                {detail.value.map((text, valueIndex) => (
                                    <div
                                        className="AlertDetails-value"
                                        key={`AlertDetails-value-${valueIndex}`}
                                        dangerouslySetInnerHTML={{
                                            __html: text
                                        }}
                                    ></div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </>
        )
    );
}

export default ReadOnlyAlertDetails;
