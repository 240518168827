import React, { useState } from 'react';
import { Container } from 'react-grid-system';
import { useParams } from 'react-router-dom';

import EventDetails from '../../Events/EventDetails';
import PageNotFound from '../../PageNotFound';

export default function ThreatDeckEventDetails() {
    // state
    const [doesNotExist, setDoesNotExist] = useState(false);

    // React-Router
    const { eventId } = useParams();

    /* ------ */
    /* Render */
    /* ------ */
    if (doesNotExist) {
        return <PageNotFound link="/events" objectName="GATOR Event" />;
    }
    return (
        <Container fluid>
            <EventDetails eventId={parseInt(eventId)} />
        </Container>
    );
}
