import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import map from 'lodash/map';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import ICButton from '../../Buttons/presentational/ICButton';

export default function ListSelector({
    all,
    available,
    selected,
    displayLabel,
    onChange
}) {
    function select(index) {
        const item = differenceBy(available, selected, 'name')[index];

        const newList = sortBy([...selected, item], displayLabel);

        onChange(newList);
    }

    function deSelect(index) {
        let item = selected[index];
        const newList = reject(selected, (i) => i === item);

        onChange(newList);
    }

    function selectAll() {
        onChange(available);
    }

    function removeAll() {
        onChange([]);
    }

    return (
        <div className="ThreatDeck-input-container">
            <div className="ThreatDeck-row ThreatDeck-group-columns-row">
                <div className="ThreatDeck-group">
                    <div className={'ThreatDeck-group-columns-header'}>
                        Available Columns:
                    </div>
                    <div className="ThreatDeck-group-columns-container">
                        {differenceBy(available, selected, displayLabel).map(
                            (item, index) => {
                                return (
                                    <div
                                        className="ThreatDeck-group-column"
                                        data-testid={`${
                                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                                .LIST_SELECTOR.SELECT
                                        }-${get(item, 'id')}`}
                                        key={item?.id}
                                        onClick={() => {
                                            select(index);
                                        }}
                                    >
                                        {get(
                                            item,
                                            displayLabel,
                                            'Label Not Found'
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
                <div className="ThreatDeck-group ThreatDeck-carets">
                    <IoChevronForward />
                    <IoChevronBack />
                </div>
                <div className="ThreatDeck-group">
                    <div className={'ThreatDeck-group-columns-header'}>
                        Selected Columns:
                    </div>
                    <div className="ThreatDeck-group-columns-container">
                        {map(selected, (selectedItem, index) => {
                            return (
                                <div
                                    className="ThreatDeck-group-column"
                                    data-testid={`${TEST_ATTRIBUTES.CONTENT_MANAGEMENT.LIST_SELECTOR.DESELECT}-${selectedItem?.id}`}
                                    key={selectedItem?.id}
                                    onClick={() => {
                                        deSelect(index);
                                    }}
                                >
                                    {selectedItem[displayLabel]}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="ThreatDeck-group-buttons">
                <ICButton
                    color="white"
                    onClick={selectAll}
                    className="ListSelector-button"
                    data-testid={
                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT.LIST_SELECTOR
                            .SELECT_ALL
                    }
                >
                    Select All
                </ICButton>
                <ICButton
                    color="white"
                    onClick={removeAll}
                    className="ListSelector-button"
                    data-testid={
                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT.LIST_SELECTOR
                            .REMOVE_ALL
                    }
                >
                    Remove All
                </ICButton>
            </div>
        </div>
    );
}
