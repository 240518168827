import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { setAlertModal } from '../../../redux/actions';
import { getNamespace } from '../../../redux/selectors';
import ICButton from '../presentational/ICButton';

export default function ArchiveAlertButton({
    alert,
    onAfterClick,
    onAfterError,
    alertListType
}) {
    // redux
    const dispatch = useDispatch();
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    function displayAlertModal({ alert }) {
        dispatch(setAlertModal({ alert }));
    }

    useEffect(() => {
        return () => {
            threatsNamespace.off(`archiveAlertFailed_${alert.id}`);
        };
    }, []);
    function archiveAlert() {
        confirmAlert({
            title: 'Confirm Archive',
            message: `Are you sure you want to archive ${alert.title}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        displayAlertModal({ alert: false });
                        if (onAfterClick) {
                            onAfterClick();
                        }
                        threatsNamespace.once(
                            `archiveAlertFailed_${alert.id}`,
                            archiveFailed
                        );
                        threatsNamespace.emit('archiveAlert', alert.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    function archiveFailed(errorMessage) {
        confirmAlert({
            title: 'Failed to Archive',
            message: 'Archiving failed with the following message:',
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {
                        if (onAfterError) {
                            onAfterError();
                        }
                    }
                }
            ],
            childrenElement: () => (
                <>
                    <p>
                        <tt>{errorMessage}</tt>
                    </p>
                    <p>
                        If you continue to see problems, please contact support.
                    </p>
                </>
            )
        });
    }

    return (
        <ICButton
            onClick={archiveAlert}
            color="white"
            data-testid={`${alertListType ? `${alertListType}-` : ''}${
                TEST_ATTRIBUTES.BUTTONS.ARCHIVE
            }-${alert.id}`}
        >
            Archive
        </ICButton>
    );
}
