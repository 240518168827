import '../../../styling/ThreatDeckCard.css';

import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { getNamespace } from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';

export default function ThreatDeckCardRemoveEvent({ item, markAsRead }) {
    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    function removeEvent() {
        confirmAlert({
            title: 'Confirm Remove',
            message:
                'Are you sure you want to remove this item from the event?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        threatDeckNamespace.emit('removeFeedItemEvent', {
                            id: item.id
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    return (
        <div
            id={`td-card-remove-event-${item.id}`}
            className="ThreatDeckCard-removeEvent"
        >
            <ICButton
                color="red"
                onClick={removeEvent}
                className="ThreatDeckCard-removeEvent-button"
                // data-testid={`${
                //     TEST_ATTRIBUTES.THREAT_DECK.CARDS.BUTTONS.REVIEW
                // }${item?.id ? `-${item.id}` : ''}`}
            >
                Remove
            </ICButton>
        </div>
    );
}
