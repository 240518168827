import { Editor } from '@tinymce/tinymce-react';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment-timezone';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import DateTimePicker from 'react-datetime-picker';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import validate from 'validate.js';

import {
    MULTISELECT_COMPONENT_STYLES,
    UPDATETYPES,
    URGENCIES
} from '../config/constants';
import { TEST_ATTRIBUTES } from '../config/testConstants';
import { tinyMceConfig } from '../config/tinyMce';
import { usePrevious } from '../hooks/shared';
import { getAuthInfo, getCategoriesList } from '../redux/selectors';
import {
    extractCategoryOption,
    extractRegionOption,
    extractUrgencyOption,
    getLocationFromOverlay
} from '../utils';
import AlertMap from './AlertMap';
import AnalystNotes from './AnalystNotes';
import ICButton from './Buttons/presentational/ICButton';

const END_DATE_OFFSET_HOURS = 5;

export default function AlertForm({
    alert,
    closeAlertModal,
    modalCallback,
    onDiscardAlert,
    onSaveAsDraft,
    onSendForApproval
}) {
    // Redux
    const authInfo = useSelector(getAuthInfo);
    const categoriesList = useSelector(getCategoriesList);
    // State
    const [alertId, setAlertId] = useState(alert ? alert.id : null);

    const [isActiveUpdate, setIsActiveUpdate] = useState(
        alert &&
            alert.status !== 'PENDING' &&
            alert.status !== 'DRAFT' &&
            alert.status !== 'DRAFT_REJECTED'
    );
    const [isUpdateEdit, setIsUpdateEdit] = useState(
        alert &&
            alert.alertId &&
            (alert.status === 'PENDING' ||
                alert.status === 'DRAFT' ||
                alert.status === 'DRAFT_REJECTED')
    );
    const [updateType, setUpdateType] = useState(
        alert && alert.updateType
            ? find(UPDATETYPES, (type) => type.value === alert.updateType)
            : false
    );
    const [title, setTitle] = useState(alert ? alert.title : '');
    const [worldwide, setWorldwide] = useState(alert ? alert.worldwide : false);
    const [message, setMessage] = useState(alert ? alert.message : '');
    const [analystNote, setAnalystNote] = useState(
        get(alert, 'analystNote.note', '')
    );
    const [startDate, setStartDate] = useState(_getInitialStartDate());
    const [endDate, setEndDate] = useState(_getInitialEndDate());
    const [category, setCategory] = useState(
        alert
            ? extractCategoryOption(
                  alert.new_category ? alert.new_category : alert.category,
                  categoriesList
              )
            : ''
    );
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [subCategory, setSubCategory] = useState('');
    const [urgency, setUrgency] = useState(
        alert ? extractUrgencyOption(alert.urgency, URGENCIES) : ''
    );
    const [region, setRegion] = useState(
        alert && alert.region
            ? extractRegionOption(alert.region.id, authInfo.regions)
            : ''
    );
    const [overlays, setOverlays] = useState([]);
    const [editorKey, setEditorKey] = useState(0); // Editor key is used to trigger a rerender of TinyMCE to clear the description on form submit
    const [analystNotesEditorKey, setAnalystNotesEditorKey] = useState(0);
    const [charCount, setCharCount] = useState(0);
    // Refs
    const tinyMCERef = useRef(null);
    const prevUpdateType = usePrevious(updateType);

    useEffect(() => {
        setSubCategoryList(category?.sub_categories);
    }, [category]);

    // Audit constraints
    const publishConstraints = {
        title: {
            presence: {
                allowEmpty: false,
                message: "^Headline can't be blank"
            }
        },
        message: {
            presence: {
                allowEmpty: false,
                message: "^Details can't be blank"
            }
        },
        startDate: {
            presence: {
                allowEmpty: false,
                message: "^Start Date can't be blank"
            }
        },
        endDate: {
            presence: {
                allowEmpty: false,
                message: "^End Date can't be blank"
            }
        },
        category: { presence: { allowEmpty: false } },
        urgency: {
            presence: {
                allowEmpty: false,
                message: "^Severity can't be blank"
            }
        },
        region: { presence: { allowEmpty: false } },
        locations: {
            presence: {
                allowEmpty: false,
                message: "^Impact Area can't be blank"
            }
        },
        // custom validators
        // see _addCustomValidators
        updateType: {
            updateType: { message: "^Update Type can't be blank" }
        },
        subCategory: {
            subCategory: {
                message:
                    "^Subcategory can't be blank if there are available subcategories"
            }
        }
    };

    const draftConstraints = {
        title: {
            presence: {
                allowEmpty: false,
                message: "^Headline can't be blank"
            }
        },
        region: { presence: { allowEmpty: false } },
        // update Type uses a custom validator
        updateType: {
            updateType: { message: "^Update Type can't be blank" }
        }
    };

    useEffect(() => {
        if (subCategoryList?.length && alert?.subCategory) {
            setSubCategory(
                extractCategoryOption(alert.subCategory, subCategoryList)
            );
        } else {
            setSubCategory('');
        }
    }, [subCategoryList]);

    // Effects
    useEffect(() => {
        // The following sets automatic update content and formatting
        // in the alert headline and in the alert body
        // This is to help streamline the analyst workflow when updating alerts
        if (
            prevUpdateType?.label !== updateType?.label &&
            updateType?.label === 'Update - Additional information' &&
            alert.publishedDate
        ) {
            if (!isEmpty(tinyMCERef.current)) {
                tinyMCERef.current.setContent(
                    _getUpdatedAlertBody(
                        alert.message,
                        alert.title,
                        alert.publishedDate
                    )
                );
                setTitle(_getUpdatedAlertTitle(alert.title));
            }
        }
    }, [updateType, tinyMCERef.current]);

    // Public methods
    function discardAlert() {
        confirmAlert({
            title: 'Confirm Discard',
            message: `Are you sure you want to discard${
                alert ? ' edits to' : ''
            } this alert?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: onDiscardAlert || _resetCreateAlertForm
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function refreshDateFields(event) {
        if (event.target.value === '') {
            let startDate = moment();
            let endDate = moment().add(END_DATE_OFFSET_HOURS, 'hours');
            setStartDate(startDate.toDate());
            setEndDate(endDate.toDate());
        }
    }

    function saveAsDraft() {
        console.log(title);
        _saveAlert(draftConstraints, {
            title: 'Confirm Draft',
            message: `Are you sure you want to save ${title} as a draft?`,
            onConfirm: onSaveAsDraft
        });
    }

    function sendForApproval() {
        _saveAlert(publishConstraints, {
            title: 'Confirm Send for Approval',
            message: `Are you sure you want to submit ${title} for approval?`,
            onConfirm: onSendForApproval
        });
    }

    function updateTitle(event) {
        setTitle(event.target.value);
    }

    function updateMessage(text) {
        const charCount = text.replace(/(<([^>]+)>)/gi, '').length;
        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        setMessage(text);
        setCharCount(charCount);
    }

    function updateAnalystNote(text) {
        setAnalystNote(text);
    }

    function updateCategory(selectedList, item) {
        setCategory(item);
    }
    function updateSubCategory(selectedList, item) {
        setSubCategory(item);
    }

    function updateUrgency(selectedList, item) {
        setUrgency(item);
    }

    function updateUpdateType(selectedList, item) {
        setUpdateType(item);
    }

    function updateStartDate(value) {
        if (moment(value).isAfter(endDate)) {
            setStartDate(value);
            setEndDate(
                moment(value).add(END_DATE_OFFSET_HOURS, 'hours').toDate()
            );
        } else {
            setStartDate(value);
        }
    }

    function updateEndDate(value) {
        setEndDate(value);
    }

    function updateLocationOverlays(overlays) {
        setOverlays(overlays);
    }

    function updateRegion(selectedList, item) {
        setRegion(item);
    }

    function updateWorldwide() {
        setWorldwide(!worldwide);
    }

    // Private methods
    function _addCustomValidators() {
        // update type requires a custom validator with access to state
        validate.validators.updateType = (updateType) => {
            if (isActiveUpdate || isUpdateEdit) {
                if (isEmpty(updateType)) {
                    return "^Update Type can't be blank";
                }
            }
        };

        validate.validators.subCategory = (subCategory) => {
            if (subCategoryList?.length) {
                if (isEmpty(subCategory)) {
                    return "^Subcategory can't be blank if there are available subcategories";
                }
            }
        };
    }

    function _getInitialStartDate() {
        return (
            alert
                ? alert.startDate
                    ? moment.unix(alert.startDate)
                    : moment(alert.start_date)
                : moment()
        ).toDate();
    }

    function _getInitialEndDate() {
        return (
            alert
                ? alert.endDate
                    ? moment.unix(alert.endDate)
                    : moment(alert.end_date)
                : moment().add(END_DATE_OFFSET_HOURS, 'hours')
        ).toDate();
    }

    function _getUpdatedAlertBody(alertBody, alertTitle, alertLastPublished) {
        let body = '';
        // We are going to end up trying to inject this date element as the first span in the paragraph below
        let dateEl = `<span data-timestamp="${alertLastPublished}"></span>`;
        let dateElReplacement = `<p>${dateEl}`;
        body = `<br/>
                <hr />
                ${alertTitle}
                ${alertBody.replace('<p>', dateElReplacement)}`;

        return body;
    }

    function _composeData() {
        let locations = [];
        overlays.forEach((overlay) => {
            locations.push(getLocationFromOverlay(overlay));
        });
        let data = {
            title,
            message,
            analystNote,
            startDate: Math.floor(startDate.getTime() / 1000), // We just need seconds
            endDate: Math.floor(endDate.getTime() / 1000), // We just need seconds
            urgency: urgency.value,
            category: category.value,
            subCategory: subCategory?.label,
            region:
                authInfo.regions.length === 1
                    ? authInfo.regions[0].id
                    : region?.id,
            locations,
            worldwide
        };

        if (isActiveUpdate || isUpdateEdit) {
            data.updateType = updateType.value;
        }

        return data;
    }

    function _getUpdatedAlertTitle(title) {
        if (title) {
            let matchTitle = title.trim();
            /**
             * Expected Input: Alert title, may contain 'Update <update number> - ' pattern which we will filter on
             * Group 1 : Update number
             * Group 2 : Original title text
             */
            let matches = matchTitle.match(/Update (\d+) - (.*)/);
            if (matches) {
                let updateNumber = matches[1];
                return `Update ${++updateNumber} - ${matches[2]}`;
            } else {
                return `Update 1 - ${title}`;
            }
        }
        return title;
    }

    function _resetCreateAlertForm() {
        for (let overlay of overlays) {
            // Make sure we detach the overlay from the map as well
            overlay.value.setMap(null);
        }
        setTitle('');
        setMessage('');
        setAnalystNote('');
        setAnalystNotesEditorKey(analystNotesEditorKey + 1);
        setStartDate(moment().toDate());
        setEndDate(moment().add(END_DATE_OFFSET_HOURS, 'hours').toDate());
        setCategory('');
        setUrgency('');
        setWorldwide(false);
        setRegion('');
        // not directly part of the form
        setOverlays([]);
        setEditorKey(editorKey + 1);
    }

    function _saveAlert(constraints, confirmData) {
        _addCustomValidators();
        const data = _composeData();
        const validationMessages = _validateAlert(data, constraints, {
            format: 'grouped'
        });

        if (isEmpty(validationMessages)) {
            confirmAlert({
                title: confirmData.title,
                message: confirmData.message,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            if (confirmData.onConfirm) {
                                confirmData.onConfirm(data);
                            }
                            _resetCreateAlertForm();
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
        } else {
            confirmAlert({
                title: 'Missing Information',
                message:
                    'Please make sure you have filled in all the required information:',
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ],
                childrenElement: () => (
                    <ul>
                        {map(Object.keys(validationMessages), (key) => (
                            <li key={`${key}ValidationMessage`}>
                                {validationMessages[key]}
                            </li>
                        ))}
                    </ul>
                )
            });
        }
    }

    function _validateAlert(form, constraints, options) {
        return validate(form, constraints, options);
    }

    return (
        <Container className="AlertForm-Container">
            {(isActiveUpdate || isUpdateEdit) && (
                <Row className="AlertForm-Row">
                    <Col className="AlertForm-Column">
                        <div className="AlertForm-form-group">
                            <Multiselect
                                id="updateTypeMultiselect"
                                selectionLimit={1}
                                options={UPDATETYPES}
                                onSelect={updateUpdateType}
                                displayValue="label"
                                singleSelect={true}
                                placeholder="Type of Update"
                                style={MULTISELECT_COMPONENT_STYLES}
                                selectedValues={updateType ? [updateType] : []}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            {
                <div>
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <div className="AlertForm-form-group">
                                <input
                                    type="text"
                                    value={title}
                                    onChange={updateTitle}
                                    onFocus={refreshDateFields}
                                    className="AlertForm-form-control"
                                    placeholder="Headline"
                                    autoComplete="off"
                                    data-testid={`${
                                        TEST_ATTRIBUTES.ALERT_FORM.HEADLINE
                                    }${alertId ? alertId : ''}`}
                                />
                                {title?.length > 0 && (
                                    <label className="AlertForm-details-count">
                                        {title.length} Characters
                                    </label>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <div className="AlertForm-form-group">
                                <b>Details:</b>
                                <Editor
                                    key={editorKey}
                                    id={`${'description_tinymce'}${
                                        alert ? `-${alert.id}` : ''
                                    }`}
                                    initialValue={alert?.message}
                                    content={message || null}
                                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                                    init={tinyMceConfig('Type details here')}
                                    onInit={(event, editor) =>
                                        (tinyMCERef.current = editor)
                                    }
                                    onEditorChange={updateMessage}
                                />
                                {message?.length > 0 && (
                                    <label className="AlertForm-details-count">
                                        {charCount} Characters
                                    </label>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <div className="AlertForm-form-group">
                                <div>
                                    <span className="AlertForm-label">
                                        Start:
                                    </span>
                                    <DateTimePicker
                                        value={startDate}
                                        onChange={updateStartDate}
                                        clearIcon={null}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col className="AlertForm-Column">
                            <div className="AlertForm-form-group">
                                <div>
                                    <span className="AlertForm-label">
                                        End:
                                    </span>
                                    <DateTimePicker
                                        value={endDate}
                                        onChange={updateEndDate}
                                        clearIcon={null}
                                        minDate={startDate}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <div className="AlertForm-form-group AlertForm-form-group-multiselect">
                                <Multiselect
                                    id={`categoryMultiselect${
                                        alertId ? `-${alertId}` : ''
                                    }`}
                                    selectionLimit={1}
                                    options={categoriesList}
                                    onSelect={updateCategory}
                                    displayValue="label"
                                    singleSelect={true}
                                    placeholder="Category"
                                    avoidHighlightFirstOption={true}
                                    style={MULTISELECT_COMPONENT_STYLES}
                                    selectedValues={category ? [category] : []}
                                />
                            </div>
                        </Col>
                        <Col className="AlertForm-Column">
                            <div className="AlertForm-form-group AlertForm-form-group-multiselect">
                                <Multiselect
                                    id={`subCategoryMultiselect${
                                        alertId ? `-${alertId}` : ''
                                    }`}
                                    selectionLimit={1}
                                    options={subCategoryList}
                                    onSelect={updateSubCategory}
                                    displayValue="label"
                                    singleSelect={true}
                                    placeholder="Sub Category"
                                    avoidHighlightFirstOption={true}
                                    style={MULTISELECT_COMPONENT_STYLES}
                                    selectedValues={
                                        subCategory ? [subCategory] : []
                                    }
                                    disable={!category}
                                />
                            </div>
                        </Col>
                        <Col className="AlertForm-Column">
                            <div className="AlertForm-form-group AlertForm-form-group-multiselect">
                                <Multiselect
                                    id={`severityMultiselect${
                                        alertId ? `-${alertId}` : ''
                                    }`}
                                    selectionLimit={1}
                                    options={URGENCIES}
                                    onSelect={updateUrgency}
                                    displayValue="label"
                                    singleSelect={true}
                                    placeholder="Severity"
                                    avoidHighlightFirstOption={true}
                                    style={MULTISELECT_COMPONENT_STYLES}
                                    selectedValues={urgency ? [urgency] : []}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={worldwide}
                                    onChange={updateWorldwide}
                                    data-testid={`${
                                        TEST_ATTRIBUTES.ALERT_FORM.WORLDWIDE
                                    }${alertId ? `-${alertId}` : ''}`}
                                />
                                Worldwide Impact
                            </label>
                        </Col>
                    </Row>
                </div>
            }
            <Row className="AlertForm-Row AlertForm-map">
                <Col className="AlertForm-Column ">
                    <AlertMap
                        alert={alert || false}
                        editMode={alert !== undefined}
                        onSaveOverlays={updateLocationOverlays}
                        skipSavedBoundaries={false}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="AnalystNotes-alert-form">
                    <b>Analyst Notes:</b>
                    <AnalystNotes
                        analystNote={analystNote}
                        editorKey={analystNotesEditorKey}
                        existingNote={get(alert, 'analystNote.note')}
                        id={`${TEST_ATTRIBUTES.ALERT_PANEL.ANALYST_NOTES}${
                            alert ? `-${alert.id}` : ''
                        }`}
                        showSave={!!alert}
                        updateNote={updateAnalystNote}
                    />
                </Col>
            </Row>
            <div>
                {authInfo.regions.length > 1 && (
                    <Row className="AlertForm-region">
                        <Col>
                            <Multiselect
                                id={`regionMultiselect${
                                    alertId ? `-${alertId}` : ''
                                }`}
                                selectionLimit={1}
                                options={authInfo.regions}
                                onSelect={updateRegion}
                                onRemove={updateRegion}
                                displayValue="name"
                                singleSelect={true}
                                placeholder="Select Region"
                                avoidHighlightFirstOption={true}
                                style={MULTISELECT_COMPONENT_STYLES}
                                selectedValues={region ? [region] : []}
                            />
                        </Col>
                    </Row>
                )}
                <div className="formButtons">
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <ICButton
                                onClick={sendForApproval}
                                color="blue"
                                data-testid={`${
                                    TEST_ATTRIBUTES.ALERT_FORM.SEND_FOR_APPROVAL
                                }${alertId ? `-${alertId}` : ''}`}
                            >
                                Send for Approval
                            </ICButton>
                        </Col>
                    </Row>
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <ICButton
                                onClick={saveAsDraft}
                                color="yellow"
                                data-testid={`${
                                    TEST_ATTRIBUTES.ALERT_FORM.SAVE_DRAFT
                                }${alertId ? `-${alertId}` : ''}`}
                            >
                                Save Draft
                            </ICButton>
                        </Col>
                    </Row>
                    <Row className="AlertForm-Row">
                        <Col className="AlertForm-Column">
                            <ICButton
                                onClick={discardAlert}
                                color="red"
                                data-testid={`${
                                    TEST_ATTRIBUTES.ALERT_FORM.DISCARD
                                }${alertId ? `-${alertId}` : ''}`}
                            >
                                {`Discard${' '}
                                    ${alert ? 'Edits' : 'Alert'}
                                `}
                            </ICButton>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
}
