import React from 'react';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../config/constants';
import { getNamespace } from '../redux/selectors';
import AlertForm from './AlertForm';

export default function CreateAlert() {
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    function createAlert(data) {
        threatsNamespace.emit('createAlert', data);
    }

    function createDraft(data) {
        threatsNamespace.emit('createDraft', data);
    }

    return (
        <div className="CreateAlert">
            <AlertForm
                onSendForApproval={createAlert}
                onSaveAsDraft={createDraft}
            />
        </div>
    );
}
