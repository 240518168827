import '../styling/AlertPanel.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { BiWorld } from 'react-icons/bi';
import { CgFileDocument } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';

import { ROLES, URGENCIES } from '../config/constants';
import { TEST_ATTRIBUTES } from '../config/testConstants';
import { setAlertModal } from '../redux/actions';
import { getAuthInfo } from '../redux/selectors';
import ArchiveAlertButton from './Buttons/alerts/ArchiveAlertButton';
import DeleteAlertDraftButton from './Buttons/alerts/DeleteAlertDraftButton';
import PublishAlertButton from './Buttons/alerts/PublishAlertButton';
import RejectAlertButton from './Buttons/alerts/RejectAlertButton';
import UpdateAlertButton from './Buttons/alerts/UpdateAlertButton';
import ICButton from './Buttons/presentational/ICButton';

const { ADMINISTRATOR, LEAD_ANALYST, READ_ONLY } = ROLES;

export default function AlertPanel({
    alert,
    dateField,
    onAddToSituation,
    onRemoveFromSituation,
    title,
    type
}) {
    const [isHidden, setIsHidden] = useState(false);
    const authInfo = useSelector(getAuthInfo);
    const dispatch = useDispatch();

    function hideThisPanel() {
        setIsHidden(true);
    }

    function showThisPanel() {
        setIsHidden(false);
    }

    function approvalAlertButtons() {
        if (authInfo.role === READ_ONLY) {
            return;
        }
        return (
            <>
                {(authInfo.role === LEAD_ANALYST ||
                    authInfo.role === ADMINISTRATOR) &&
                    alert.status === 'PENDING' && (
                        <Row>
                            <Col>
                                <PublishAlertButton
                                    alert={alert}
                                    onAfterClick={hideThisPanel}
                                    onAfterError={showThisPanel}
                                />
                            </Col>
                            <Col>
                                <RejectAlertButton
                                    alert={alert}
                                    onAfterClick={hideThisPanel}
                                />
                            </Col>
                        </Row>
                    )}
                {(authInfo.role === LEAD_ANALYST ||
                    authInfo.role === ADMINISTRATOR ||
                    alert.status === 'DRAFT' ||
                    alert.status === 'DRAFT_REJECTED' ||
                    alert.creatorId === authInfo.user.id) && (
                    <Row>
                        <Col>
                            <UpdateAlertButton alert={alert} />
                        </Col>
                    </Row>
                )}
                {(alert.status === 'DRAFT' ||
                    alert.status === 'DRAFT_REJECTED') && (
                    <Row>
                        <Col>
                            <DeleteAlertDraftButton
                                alert={alert}
                                onAfterClick={hideThisPanel}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    }

    function reviewAlertButtons() {
        if (authInfo.role === READ_ONLY) {
            return;
        }

        return (
            <Row>
                <Col>
                    <ArchiveAlertButton
                        alert={alert}
                        title={title}
                        alertListType={type}
                        onAfterClick={hideThisPanel}
                        onAfterError={showThisPanel}
                    />
                </Col>
            </Row>
        );
    }

    function activeAlertButtons() {
        if (authInfo.role === READ_ONLY) {
            return;
        }
        return (
            <Row>
                <Col>
                    <ArchiveAlertButton
                        alert={alert}
                        alertListType={type}
                        onAfterClick={hideThisPanel}
                        onAfterError={showThisPanel}
                    />
                </Col>
                <Col>
                    <UpdateAlertButton alert={alert} />
                </Col>
            </Row>
        );
    }

    function contentManagementRemoveButton() {
        if (authInfo.role === READ_ONLY) {
            return;
        }
        return (
            <Row>
                <Col>
                    <ICButton
                        color="red"
                        onClick={() => onRemoveFromSituation(alert.id)}
                        data-testid={`${TEST_ATTRIBUTES.CONTENT_MANAGEMENT.CONTENT_REMOVE_ALERT}-${alert.id}`}
                    >
                        Remove from Situation Report
                    </ICButton>
                </Col>
            </Row>
        );
    }

    function contentManagementAddButton() {
        if (authInfo.role === READ_ONLY) {
            return;
        }
        return (
            <Row>
                <Col>
                    <ICButton
                        color="blue"
                        onClick={() => onAddToSituation(alert.id)}
                        data-testid={`${TEST_ATTRIBUTES.CONTENT_MANAGEMENT.ADD_ALERT_TO_CONTENT}-${alert.id}`}
                    >
                        Add to Situation Report
                    </ICButton>
                </Col>
            </Row>
        );
    }

    function openAlertModal() {
        dispatch(
            setAlertModal({
                alert: alert.id,
                editMode: false,
                isUpdate: alert.isUpdate,
                modalCallback: null
            })
        );
    }

    function alertButtons() {
        if (authInfo.role === READ_ONLY || type === 'presentational') {
            return;
        }
        let buttonsToRender;
        if (type === 'reviewList') {
            buttonsToRender = reviewAlertButtons;
        }
        if (_.includes(['draftsList', 'approvalList'], type)) {
            buttonsToRender = approvalAlertButtons;
        }
        if (type === 'activeList' || type === 'expiringList') {
            buttonsToRender = activeAlertButtons;
        }
        if (type === 'contentManagementRemove') {
            buttonsToRender = contentManagementRemoveButton;
        }
        if (type === 'contentManagementAdd') {
            buttonsToRender = contentManagementAddButton;
        }
        return (
            <div
                className="AlertPanel-buttons"
                data-testid={`${TEST_ATTRIBUTES.ALERT_PANEL.BUTTONS}-${alert.id}`}
            >
                <Container>{buttonsToRender()}</Container>
            </div>
        );
    }

    function authorInitials() {
        let initials = '';
        let name = '';
        if (alert.creator) {
            _.forEach(alert.creator.name.split(' '), (part) => {
                initials = `${initials}${part.charAt(0)}`;
            });
            name = alert.creator.name;
        }
        return (
            <div
                className="AlertPanel-author"
                data-testid={`${TEST_ATTRIBUTES.ALERT_PANEL.AUTHOR}-${alert.id}`}
                title={`Created in Intelligence Center by ${name}`}
            >
                {initials}
            </div>
        );
    }

    function alertUrgency() {
        let urgencyLabel = '';
        URGENCIES.forEach((urgency) => {
            if (urgency.value === parseInt(alert.urgency)) {
                urgencyLabel = urgency.label;
            }
        });
        return (
            <div
                className={`AlertPanel-urgency AlertUrgency-${urgencyLabel}`}
                data-testid={`${TEST_ATTRIBUTES.ALERT_PANEL.URGENCY}-${alert.id}`}
            >
                {urgencyLabel}
            </div>
        );
    }

    let dateString = moment.unix(alert[dateField]).format('MMM Do - hh:mm A');
    let panelClass =
        parseInt(alert.end_date) < Math.floor(moment.utc() / 1000) + 900
            ? 'AlertPanel AlertPanel-expiring'
            : 'AlertPanel';
    if (isHidden) {
        panelClass = 'AlertPanel-hidden';
    }
    const situationReport = _.filter(
        alert.isUpdate ? alert.alert.situationReports : alert.situationReports,
        (report) => report.deletedDate === null
    )[0];
    return (
        <div
            className={panelClass}
            data-testid={`${TEST_ATTRIBUTES.ALERT_PANEL.BOX}-${alert.id}`}
        >
            <div className="AlertPanel-header">
                <div className="AlertPanel-header-column">
                    {authorInitials()}
                    {situationReport && (
                        <CgFileDocument
                            className="AlertPanel-ContentManagement-icon"
                            title={`Linked to Situation Report "${situationReport.title}"`}
                        />
                    )}
                </div>
                <div className="AlertPanel-header-column">
                    {alert.worldwide && (
                        <span
                            data-testid={`${TEST_ATTRIBUTES.ALERT_PANEL.WORLDWIDE}`}
                        >
                            <BiWorld
                                className="AlertPanel-worldwide-icon"
                                title={'Worldwide Impact'}
                            />
                        </span>
                    )}
                    {alertUrgency()}
                    <div
                        className="AlertPanel-timestamp"
                        data-testid={`${TEST_ATTRIBUTES.ALERT_PANEL.TIMESTAMP}-${alert.id}`}
                    >
                        {dateString}
                    </div>
                </div>
            </div>
            <div
                className="AlertPanel-title"
                data-testid={`${TEST_ATTRIBUTES.ALERT_PANEL.TITLE}-${alert.id}`}
                onClick={openAlertModal}
            >
                {alert.title}
            </div>
            {alertButtons()}
        </div>
    );
}
