import '../../../styling/MapManagement.css';

import _, { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import { NAMESPACES, ROLES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getAuthInfo, getNamespace } from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';
import ICTable, { renderCell } from '../../ICTable';
import MapForm from './MapForm';
import {
    canDeleteMap,
    canDeletePendingMap,
    translateRisk
} from './MapFunctions';

const { ADMINISTRATOR, LEAD_ANALYST, READ_ONLY } = ROLES;

export default function MapManagement() {
    // State
    const [boundaries, setBoundaries] = useState([]);
    const [pendingBoundaries, setPendingBoundaries] = useState([]);
    const [boundariesLoaded, setBoundariesLoaded] = useState(false);
    const [pendingBoundariesLoaded, setPendingBoundariesLoaded] =
        useState(false);
    const [selectedMap, setSelectedMap] = useState(undefined);
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    // This is used to determine if we're viewing/editing a pending map or not
    const [pendingMode, setPendingMode] = useState(false);
    // Redux
    const authInfo = useSelector(getAuthInfo);
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    useEffect(() => {
        if (threatsNamespace) {
            threatsNamespace.on('boundariesList', (data) => {
                setBoundaries(data);
                setBoundariesLoaded(true);
            });
            threatsNamespace.on('pendingBoundariesList', (data) => {
                setPendingBoundaries(data);
                setPendingBoundariesLoaded(true);
            });
            threatsNamespace.emit('getBoundaries');
            threatsNamespace.emit('getPendingBoundaries');
        }

        return () => {
            threatsNamespace.off('boundariesList');
            threatsNamespace.off('pendingBoundariesList');
        };
    }, [threatsNamespace]);

    const createMap = () => {
        let map = {
            label: 'New Map',
            description: 'Starting with a blank canvas',
            geometry: {},
            enabled: true
        };
        setPendingMode(false);
        setEditMode(false);
        setCreateMode(true);
        setSelectedMap(map);
    };

    const editMap = ({ map }) => {
        setPendingMode(false);
        setEditMode(true);
        setCreateMode(false);
        setSelectedMap(map);
    };
    const editPendingMap = ({ map }) => {
        setPendingMode(true);
        setEditMode(true);
        setCreateMode(false);
        setSelectedMap(map);
    };
    const deleteMap = ({ map }) => {
        if (!canDeleteMap(authInfo.role)) {
            return false;
        }
        confirmAlert({
            title: 'Confirm Delete Map',
            message: `Are you sure you want to delete ${map.label}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        threatsNamespace.emit('deleteBoundary', map.key);
                        setSelectedMap(undefined);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };
    const deletePendingMap = ({ map }) => {
        if (!canDeletePendingMap(authInfo.role)) {
            return false;
        }
        confirmAlert({
            title: 'Confirm Delete Pending Map',
            message: `Are you sure you want to delete ${map.label} that is pending approval?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        threatsNamespace.emit('deletePendingBoundary', map.key);
                        setSelectedMap(undefined);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };
    const publishMap = ({ map }) => {
        confirmAlert({
            title: 'Confirm Publish Map',
            message: `Are you sure you want to publish ${map.label} to be used in threats?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        threatsNamespace.emit('publishBoundary', map.key);
                        setSelectedMap(undefined);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };
    const saveMap = ({ map }) => {
        // We need to check the map being passed in as opposed to the selectedMap; the data
        //  it contains actually corresponds to the BoundaryUpdate that will be created or
        //  updated in the DB.
        const action = map?.id ? 'update' : 'create';
        threatsNamespace.emit(`${action}BoundaryUpdate`, map);
    };
    const enableMap = ({ map }) => {
        threatsNamespace.emit('enableBoundary', map.key);
    };
    const disableMap = ({ map }) => {
        threatsNamespace.emit('disableBoundary', map.key);
    };

    const viewMap = ({ map }) => {
        setPendingMode(false);
        setEditMode(false);
        setCreateMode(false);
        setSelectedMap(map);
    };

    const viewPendingMap = ({ map }) => {
        setPendingMode(true);
        setEditMode(false);
        setCreateMode(false);
        setSelectedMap(map);
    };

    const boundaryToNode = (boundary, boundaries = []) => {
        return {
            key: boundary.id,
            // We need to add primaryKey because the TreeMenu library messes with the `key` value when
            // rendering into the DOM. They look like this `key: '550/607'` where 550 is the parentId
            // of boundary 604
            primaryKey: boundary.id,
            description: boundary.description,
            label: boundary.title,
            enabled: boundary.enabled,
            risk: translateRisk(boundary.risk),
            creator: boundary.creator ? boundary.creator.name : '',
            nodes: orderBy(
                boundaries
                    .filter((b) => b.parentId === boundary.id)
                    .map((b) => boundaryToNode(b, boundaries)),
                ['label', 'asc']
            )
        };
    };

    const processBoundariesIntoTree = (boundaries = []) => {
        if (isEmpty(boundaries)) {
            return [];
        }

        let treeData = [];
        let topLevel = _(boundaries)
            // .filter((b) => b.parentId === null)
            .orderBy(['title', 'asc'])
            .value();

        topLevel.forEach((boundary) => {
            treeData.push(boundaryToNode(boundary, boundaries));
        });
        return treeData;
    };

    const renderActiveMapsButtons = ({ map }) => {
        let activeMapsButtons = [];
        if (authInfo.role === READ_ONLY) {
            return <></>;
        }
        if (authInfo.role === ADMINISTRATOR || authInfo.role === LEAD_ANALYST) {
            if (map.enabled) {
                activeMapsButtons.push({
                    label: 'Disable',
                    action: disableMap,
                    color: 'yellow',
                    dataTestId: TEST_ATTRIBUTES.BUTTONS.MAP_DISABLE
                });
            } else {
                activeMapsButtons.push({
                    label: 'Enable',
                    action: enableMap,
                    color: 'green',
                    dataTestId: TEST_ATTRIBUTES.BUTTONS.MAP_ENABLE
                });
            }
        }
        activeMapsButtons.push({
            label: 'Edit',
            action: editMap,
            color: 'blue',
            dataTestId: TEST_ATTRIBUTES.BUTTONS.MAP_EDIT
        });
        if (authInfo.role === ADMINISTRATOR) {
            activeMapsButtons.push({
                label: 'Delete',
                action: deleteMap,
                color: 'red',
                dataTestId: TEST_ATTRIBUTES.BUTTONS.MAP_DELETE
            });
        }
        return activeMapsButtons.map((button) => (
            <MapsActionButton key={button.label} {...button} map={map} />
        ));
    };

    const renderPendingMapsButtons = ({ map }) => {
        if (authInfo.role === READ_ONLY) {
            return <></>;
        }
        const pendingMapsButtons = [
            {
                label: 'Edit',
                action: editPendingMap,
                color: 'blue',
                dataTestId: TEST_ATTRIBUTES.BUTTONS.MAP_EDIT_PENDING
            },
            {
                label: 'Delete',
                action: deletePendingMap,
                color: 'red',
                dataTestId: TEST_ATTRIBUTES.BUTTONS.MAP_DELETE_PENDING
            }
        ];
        return pendingMapsButtons.map((button) => (
            <MapsActionButton key={button.label} {...button} map={map} />
        ));
    };

    // const expandCollapsButton = (row) => {
    //     if (row.isExpanded) {
    //         return (
    //             <span
    //                 {...row.getToggleRowExpandedProps()}
    //                 className={'MapRowExpanded'}
    //             >
    //                 -
    //             </span>
    //         );
    //     } else {
    //         return (
    //             <span
    //                 {...row.getToggleRowExpandedProps()}
    //                 className={'MapRowCollapsed'}
    //             >
    //                 +
    //             </span>
    //         );
    //     }
    // };

    const activeMapsColumns = React.useMemo(
        () => [
            {
                Header: 'Map Name',
                accessor: (d) => d.label,
                customColumnRender: {
                    render: ({ label, nodes, row }) => {
                        return (
                            <>
                                {/*Maybe in the future we do this, but not right now*/}
                                {/*{_.size(nodes) ? expandCollapsButton(row) : ''}*/}
                                <span
                                    className={'MapRowLabel'}
                                    data-testid={`${TEST_ATTRIBUTES.LINKS.MAP_VIEW}_${row.original.key}`}
                                    onClick={() =>
                                        viewMap({ map: row.original })
                                    }
                                >
                                    {label}
                                </span>
                            </>
                        );
                    },
                    args: {
                        label: 'value',
                        nodes: 'row.original.nodes',
                        primaryKey: 'row.original.primaryKey',
                        row: 'row'
                    }
                }
            },
            {
                Header: 'Description',
                accessor: (d) => d.description,
                customColumnRender: {
                    render: ({ description }) => {
                        return <span>{description}</span>;
                    },
                    args: { description: 'value' }
                }
            },
            {
                Header: 'Risk',
                accessor: (d) => d.risk,
                customColumnRender: {
                    render: ({ risk }) => {
                        return <span>{risk}</span>;
                    },
                    args: { risk: 'value' }
                }
            },
            {
                Header: 'Status',
                accessor: (d) => d.enabled,
                customColumnRender: {
                    render: ({ enabled }) => {
                        let statusText = !enabled ? (
                            <span className={'MapRowLabel-Disabled'}>
                                DISABLED
                            </span>
                        ) : (
                            <span className={'MapRowLabel-Enabled'}>
                                ENABLED
                            </span>
                        );
                        return statusText;
                    },
                    args: { enabled: 'value' }
                }
            },
            {
                Header: 'Actions',
                accessor: (d) => d.key,
                customColumnRender: {
                    render: renderActiveMapsButtons,
                    args: { map: 'row.original' }
                }
            }
        ],
        []
    );

    const pendingMapsColumns = React.useMemo(
        () => [
            {
                Header: 'Map Name',
                accessor: (d) => d.label,
                customColumnRender: {
                    render: ({ label, nodes, row }) => {
                        return (
                            <>
                                {/*Maybe in the future we do this, but not right now*/}
                                {/*{_.size(nodes) ? expandCollapsButton(row) : ''}*/}
                                <span
                                    className={'MapRowLabel'}
                                    data-testid={`${TEST_ATTRIBUTES.LINKS.MAP_VIEW_PENDING}_${row.original.key}`}
                                    onClick={() =>
                                        viewPendingMap({ map: row.original })
                                    }
                                >
                                    {label}
                                </span>
                            </>
                        );
                    },
                    args: {
                        label: 'value',
                        nodes: 'row.original.nodes',
                        primaryKey: 'row.original.primaryKey',
                        row: 'row'
                    }
                }
            },
            {
                Header: 'Description',
                accessor: (d) => d.description,
                customColumnRender: {
                    render: ({ description }) => {
                        return <span>{description}</span>;
                    },
                    args: { description: 'value' }
                }
            },
            {
                Header: 'Risk',
                accessor: (d) => d.risk,
                customColumnRender: {
                    render: ({ risk }) => {
                        return <span>{risk}</span>;
                    },
                    args: { risk: 'value' }
                }
            },
            {
                Header: 'Creator',
                accessor: (d) => d.creator,
                customColumnRender: {
                    render: ({ creator }) => {
                        return <span>{creator}</span>;
                    },
                    args: { creator: 'value' }
                }
            },
            {
                Header: 'Actions',
                accessor: (d) => d.key,
                customColumnRender: {
                    render: renderPendingMapsButtons,
                    args: { map: 'row.original' }
                }
            }
        ],
        []
    );

    const activeMapsData = React.useMemo(() => {
        let processedBoundaries = processBoundariesIntoTree(boundaries);
        return processedBoundaries;
    }, [boundaries]);
    const pendingMapsData = React.useMemo(() => {
        let processedUpdates = processBoundariesIntoTree(pendingBoundaries);
        return processedUpdates;
    }, [pendingBoundaries]);

    const renderRowSubComponents = React.useCallback(({ row, rowProps }) => {
        return (
            <SubRows
                row={row}
                rowProps={rowProps}
                data={row.original.nodes}
            ></SubRows>
        );
    }, []);

    return (
        <Container>
            {selectedMap ? (
                <MapForm
                    map={selectedMap}
                    editMode={editMode}
                    createMode={createMode}
                    pendingMode={pendingMode}
                    onCloseMap={() => setSelectedMap(undefined)}
                    onDisableMap={pendingMode ? () => {} : disableMap}
                    onEnableMap={pendingMode ? () => {} : enableMap}
                    onEditMap={pendingMode ? editPendingMap : editMap}
                    onSaveMap={saveMap}
                    onPublishMap={publishMap}
                    onDeleteMap={
                        pendingMode
                            ? deletePendingMap
                            : authInfo.role !== ADMINISTRATOR
                            ? () => {}
                            : deleteMap
                    }
                />
            ) : (
                <>
                    <Row>
                        <Col>
                            <h1>Maps Pending Approval</h1>
                        </Col>
                        <Col>
                            {authInfo.role === READ_ONLY ? (
                                ''
                            ) : (
                                <ICButton
                                    color="green"
                                    className="MapFormButton"
                                    onClick={createMap}
                                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_CREATE}`}
                                >
                                    Create Map
                                </ICButton>
                            )}
                        </Col>
                    </Row>
                    <ICTable
                        columns={pendingMapsColumns}
                        data={pendingMapsData}
                        reference={'PendingMaps'}
                    />
                    <h1>Active Maps</h1>
                    <ICTable
                        columns={activeMapsColumns}
                        data={activeMapsData}
                        reference={'ActiveMaps'}
                        renderRowSubComponents={renderRowSubComponents}
                        boundariesLoaded={boundariesLoaded}
                    />
                </>
            )}
        </Container>
    );
}

/**
 * Button component for use in MapManagement action column
 * @param {object} map reference to the map
 * @param {string} label displayed button text, also the component key
 * @param {function} action onClick
 * @returns action button component jsx
 */
function MapsActionButton({ map, label, action, color, dataTestId }) {
    return (
        <ICButton
            color={color}
            className="MapManagement-button"
            onClick={() => action({ map })}
            data-testid={`${dataTestId}_${map.key}`}
        >
            {label}
        </ICButton>
    );
}

function SubRows({ row, rowProps, data }) {
    return (
        <>
            {data.map((node, i) => {
                return (
                    <tr {...rowProps} key={`${node.key}-expanded-${i}`}>
                        {row.cells.map((cell) => {
                            return (
                                <td
                                    key={`${row.original.key}/${node.key}`}
                                    className={'ICTable-td'}
                                    {...cell.getCellProps()}
                                >
                                    {cell.render(renderCell, {
                                        value:
                                            cell.column.accessor &&
                                            cell.column.accessor(node, i),
                                        row: {
                                            ...row,
                                            original: node
                                        }
                                    })}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </>
    );
}
