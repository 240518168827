import {
    ADD_THREAT_DECK_FEED_ITEM,
    ARCHIVE_EVENT,
    CLEAR_MESSAGES_LIST,
    DECREMENT_REPLY_COUNT,
    DELETE_CHAT_MESSAGE,
    EXPIRE_THREAT_DECK_FEED_ITEMS,
    GET_BOUNDARY,
    GROUP_CREATED,
    GROUP_DELETED,
    GROUP_UPDATED,
    LOGOUT_USER,
    ON_COLUMN_SAVED,
    REFRESH_THREAT_DECK_FEED_ITEMS,
    REMOVE_THREAT_DECK_FEED_ITEM,
    SET_ALERT_MODAL,
    SET_APP,
    SET_APP_REGIONS,
    SET_APP_USERS,
    SET_AVAILABLE_GROUP_COLUMNS,
    SET_CATEGORIES_LIST,
    SET_COGNITO_SESSION,
    SET_CURRENT_VIEW,
    SET_DRAFT_AUTHOR_CONTENT_FILTER,
    SET_ENABLED_CAPABILITIES_LIST,
    SET_FILTERS,
    SET_GROUPS,
    SET_HIDE_THIRD_PARTY_CONTENT,
    SET_IS_LOGGED_IN,
    SET_MESSAGES_LIST,
    SET_SAVED_BOUNDARY_SEARCH,
    SET_THREAT_DECK_EVENTS,
    SET_THREAT_DECK_FEED_ITEMS,
    SET_TWITTER_RULES,
    SET_USER,
    SET_USER_GROUPS,
    SET_USER_REGIONS,
    SET_USER_ROLE,
    SET_USER_SESSION,
    SOCKET_MANAGER,
    SOCKET_NAMESPACE,
    SOCKETS_CONNECTED,
    SOCKETS_DISCONNECTED,
    SOCKETS_RECONNECTED,
    SOCKETS_RECONNECTING,
    TOGGLE_MODAL,
    UPDATE_EVENT,
    UPDATE_THREAT_DECK_FEED_ITEM_ANNOTATION
} from './actionTypes';

// General
export const toggleModal = ({ data, modalName, value }) => ({
    type: TOGGLE_MODAL,
    payload: { data, modalName, value }
});

// Authorization Actions
export const setUserSession = (userSession) => ({
    type: SET_USER_SESSION,
    payload: userSession
});

export const setUser = (userInfo) => ({
    type: SET_USER,
    payload: userInfo
});
export const setCognitoSession = (cognitoSession) => ({
    type: SET_COGNITO_SESSION,
    payload: cognitoSession
});
export const setIsLoggedIn = (isLoggedIn) => ({
    type: SET_IS_LOGGED_IN,
    payload: isLoggedIn
});

export const logoutUser = (id) => ({
    type: LOGOUT_USER,
    payload: { id: id }
});

export const setUserRole = (role) => ({
    type: SET_USER_ROLE,
    payload: { role: role }
});

export const setApplicationRegions = (regions) => ({
    type: SET_APP_REGIONS,
    payload: regions
});

export const setUserRegions = (regions) => ({
    type: SET_USER_REGIONS,
    payload: { regions: regions }
});

// Socket Actions

export const setNamespaceSocket = (namespace, socket) => {
    return {
        type: SOCKET_NAMESPACE,
        payload: { namespace, socket }
    };
};

export const setSocketManager = (SocketManager) => ({
    type: SOCKET_MANAGER,
    payload: { SocketManager }
});

export const socketsConnected = (areSocketsConnected) => ({
    type: SOCKETS_CONNECTED,
    payload: {
        areSocketsConnected
    }
});

export const socketsReconnected = (areSocketsConnected) => ({
    type: SOCKETS_RECONNECTED,
    payload: {
        areSocketsConnected
    }
});

export const socketsReconnecting = (socket) => ({
    type: SOCKETS_RECONNECTING,
    payload: { areSocketsConnected: false, socketsReconnecting: true }
});

export const socketsDisconnected = (socket) => ({
    type: SOCKETS_DISCONNECTED,
    payload: { areSocketsConnected: false }
});

// Application Actions
export const setApplication = (app) => ({
    type: SET_APP,
    payload: app
});

export const setApplicationUsers = (users) => ({
    type: SET_APP_USERS,
    payload: users
});

export const setAlertModal = ({
    alert,
    editMode = false,
    isUpdate = false,
    modalCallback = () => {}
}) => ({
    type: SET_ALERT_MODAL,
    payload: {
        id: alert,
        editMode,
        isUpdate,
        modalCallback
    }
});

export const setFilters = (modalType, filters) => {
    return {
        type: SET_FILTERS(modalType),
        payload: filters
    };
};

export const setSavedBoundarySearch = (active, editMode = false) => ({
    type: SET_SAVED_BOUNDARY_SEARCH,
    payload: {
        active: active || false,
        editMode: editMode || false
    }
});

export const getBoundary = (id) => ({
    type: GET_BOUNDARY,
    payload: {
        id: id || null
    }
});

export const hideThirdPartyContent = (hide) => ({
    type: SET_HIDE_THIRD_PARTY_CONTENT,
    payload: hide
});

export const setDraftAuthorContentFilter = (filter) => ({
    type: SET_DRAFT_AUTHOR_CONTENT_FILTER,
    payload: filter
});

export const setCategoriesList = (categories) => ({
    type: SET_CATEGORIES_LIST,
    payload: categories
});

export const setCapabilitiesList = (capabilities) => ({
    type: SET_ENABLED_CAPABILITIES_LIST,
    payload: capabilities
});

export const setCurrentView = (currentView) => ({
    type: SET_CURRENT_VIEW,
    payload: currentView
});

export const setMessagesList = (chatMessages) => ({
    type: SET_MESSAGES_LIST,
    payload: chatMessages
});

export const clearMessagesList = () => ({
    type: CLEAR_MESSAGES_LIST,
    payload: []
});

export const deleteChatMessage = (chatMessageToDelete) => ({
    type: DELETE_CHAT_MESSAGE,
    payload: chatMessageToDelete
});

export const decrementReplyCount = (replyToId, lastReply) => ({
    type: DECREMENT_REPLY_COUNT,
    payload: { replyToId, lastReply }
});

// Threat Deck Actions
export const groupCreated = (group) => ({
    type: GROUP_CREATED,
    payload: group
});

export const groupDeleted = (groupId) => ({
    type: GROUP_DELETED,
    payload: groupId
});

export const groupUpdated = (group) => ({
    type: GROUP_UPDATED,
    payload: group
});

export const setAvailableGroupColumns = (columns) => ({
    type: SET_AVAILABLE_GROUP_COLUMNS,
    payload: columns
});

export const setGroups = (groups) => ({
    type: SET_GROUPS,
    payload: groups
});

export const setUserGroups = (groups) => ({
    type: SET_USER_GROUPS,
    payload: groups
});

export const setThreatDeckFeedItemsList = (feedItems) => {
    return {
        type: SET_THREAT_DECK_FEED_ITEMS,
        payload: feedItems
    };
};

export const setTwitterRules = (twitterRules) => ({
    type: SET_TWITTER_RULES,
    payload: twitterRules
});

export const addThreatDeckFeedItem = (feedItem) => ({
    type: ADD_THREAT_DECK_FEED_ITEM,
    payload: feedItem
});

export const refreshThreatDeckFeedItems = () => ({
    type: REFRESH_THREAT_DECK_FEED_ITEMS
});

export const removeThreatDeckFeedItem = (feedItem) => ({
    type: REMOVE_THREAT_DECK_FEED_ITEM,
    payload: feedItem
});

export const updateThreatDeckFeedItemAnnotation = (annotation) => ({
    type: UPDATE_THREAT_DECK_FEED_ITEM_ANNOTATION,
    payload: annotation
});

export const expireThreatDeckFeedItems = () => ({
    type: EXPIRE_THREAT_DECK_FEED_ITEMS
});

export const onColumnSaved = ({ columnId, groupId, threatDeckNamespace }) => ({
    type: ON_COLUMN_SAVED,
    payload: { columnId, groupId, threatDeckNamespace }
});

export const setThreatDeckEvents = (events) => ({
    type: SET_THREAT_DECK_EVENTS,
    payload: events
});

export const updateEvent = (event) => ({
    type: UPDATE_EVENT,
    payload: event
});

export const archiveEvent = (event) => ({
    type: ARCHIVE_EVENT,
    payload: event
});
