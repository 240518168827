import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    NAMESPACES,
    ROLES,
    THREAT_DECK_MULTISELECT_COMPONENT_STYLES as TD_MULTISELECT_STYLE,
    URGENCIES
} from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import {
    getApplicationRegions,
    getAuthInfo,
    getCategoriesList,
    getNamespace
} from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';
import ThreatDeckCardAcceptReject from '../../ThreatDeck/ThreatDeckCard/ThreatDeckCardAcceptReject';
import ThreatDeckCardContent from '../../ThreatDeck/ThreatDeckCard/ThreatDeckCardContent';
import ThreatDeckCardSource from '../../ThreatDeck/ThreatDeckCard/ThreatDeckCardSource';

const { ADMINISTRATOR, LEAD_ANALYST, ANALYST, LABELER } = ROLES;

export default function ThreatDeckCardReviewModal({
    item,
    closeReviewModal,
    labeling = false,
    queueName = 'threatdeck_labeler'
}) {
    const DEFAULT_UNKNOWN_REGION = 1;
    const categoriesList = useSelector(getCategoriesList);
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );
    const regionList = useSelector(getApplicationRegions);
    const authInfo = useSelector(getAuthInfo);

    const location = useLocation();

    // NOTE: We are not displaying/editing severity for now, but
    // leaving the skeleton code to do so in place in case that changes.
    // In the meantime, severity here should always match ml_severity
    // and never get changed.
    const [severity, setSeverity] = useState(getItemSeverity());
    const [category, setCategory] = useState(getItemCategory());
    const [region, setRegion] = useState();
    const [isThreat, setIsThreat] = useState(item?.annotation?.ui_type);

    function getItemCategory() {
        const itemCategory =
            item?.annotation?.ui_category || item?.annotation?.ml_category;

        const cat = find(categoriesList, { label: itemCategory });

        if (cat) {
            return [cat];
        }
    }

    // For now, ui_severity should always be undefined, and so this should
    // always return ml_severity
    function getItemSeverity() {
        const itemSeverity =
            item?.annotation?.ui_severity || item?.annotation?.ml_severity;
        const severity = itemSeverity
            ? [find(URGENCIES, { label: itemSeverity })]
            : undefined;

        return severity;
    }

    // Get item region value.
    // This also prioritizes annotated values over ML values,
    // and will default to Unknown Region if those are null.
    function getItemRegion() {
        const regionId =
            item?.annotation?.ui_region ||
            item?.annotation?.ml_region ||
            DEFAULT_UNKNOWN_REGION;

        const reg = find(regionList, (r) => {
            return r.id === regionId;
        });

        return reg ? [reg] : undefined;
    }

    function updateCategory(cat) {
        setCategory(cat);
    }

    function updateRegion(reg) {
        setRegion(reg);
    }

    // We will not be displaying/editing severity for now,
    // leaving the skeleton here in case that changes.
    // function updateSeverity(sev) {
    //     // setSeverity(sev);
    //     return;
    // }

    function resetData() {
        setCategory(getItemCategory());
        setSeverity(getItemSeverity());
        setRegion(getItemRegion());
    }

    function resetAndCloseModal() {
        resetData();
        closeReviewModal();
    }

    function createThreat() {
        threatDeckNamespace.on(
            `failedCreateFeedItemAlert-${item.id}`,
            (message) => {
                confirmAlert({
                    title: 'Alert Creation Failed',
                    message: `There was an error creating the alert. ${message}.`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            }
        );
        threatDeckNamespace.emit('createAlertFromFeedItem', {
            ...item,
            annotation: {
                ...item.annotation,
                ui_category: get(category, '[0].label'),
                ui_region: get(region, '[0].id'),
                ui_type: isThreat
            }
        });
    }

    function skipItem() {
        threatDeckNamespace.emit('skipJob', {
            jobId: `${queueName}:${item?.id}`,
            queueName
        });
    }

    function saveFeedItem() {
        // This shouldn't happen, but we don't want to accidentally
        // create a new duplicate feed item
        if (!item?.id || !item?.annotation) {
            console.error(
                'Unable to save item, id or annotation is missing. Item: ',
                item
            );
        } else {
            if (labeling) {
                threatDeckNamespace.emit('completeJob', {
                    annotation: {
                        ThreatDeckFeedItemId:
                            item.annotation.ThreatDeckFeedItemId,
                        ui_category: get(category, '[0].label'),
                        ui_confidence: item.annotation.ui_confidence,
                        ui_region: get(region, '[0].id'),
                        ui_severity: item.annotation.ui_severity,
                        ui_type: isThreat
                    },
                    queueName: labeling.queueName
                });
                labeling.setLabelerItem(null);
                resetData();
            } else {
                // Note that we are not allowing labeling of severity at this time
                threatDeckNamespace.emit('saveFeedItemAnnotation', {
                    ThreatDeckFeedItemId: item.annotation.ThreatDeckFeedItemId,
                    ui_category: get(category, '[0].label'),
                    ui_confidence: item.annotation.ui_confidence,
                    ui_region: get(region, '[0].id'),
                    // ui_severity: get(severity, '[0].label'),
                    ui_severity: item.annotation.ui_severity,
                    ui_type: isThreat
                });
            }
        }
        if (closeReviewModal) {
            closeReviewModal();
        }
    }

    function onSwitchChange(state) {
        setIsThreat(state);
    }

    // Only set initial region value after region list has loaded
    useEffect(() => {
        if (!region) {
            setRegion(getItemRegion());
        }
    }, [regionList]);

    return (
        <div>
            <div className="ThreatDeckCardReview-header">
                <h2>Source Details</h2>
                {closeReviewModal && (
                    <button
                        className="ReactModal__CloseButton"
                        onClick={resetAndCloseModal}
                        data-testid={
                            TEST_ATTRIBUTES.THREAT_DECK.REVIEW.BUTTONS.CLOSE
                        }
                    >
                        <i className="fa fa-window-close" />
                    </button>
                )}
            </div>
            <div className="ThreatDeckCardReview-source ThreatDeckReview-row">
                <label className="ThreatDeckCardReview-label">Source</label>
                <div className="ThreatDeckCardReview-source-container">
                    <ThreatDeckCardSource
                        type={item?.type}
                        sourceName={item?.source_name}
                        sourceLink={item?.link || item?.source_link}
                    />
                </div>
            </div>
            {/* Will not be showing location information yet */}
            {/* <div className="ThreatDeckCardReview-location ThreatDeckReview-row">
                <label htmlFor="td-review-location">Location</label>
                <input
                    id="td-review-location"
                    className="ThreatDeckCardReview-input"
                    type="text"
                    value={location}
                    onChange={updateLocation}
                    data-testid={TEST_ATTRIBUTES.THREAT_DECK.REVIEW.LOCATION}
                />
            </div> */}
            <div className="ThreatDeckCardReview-annotations ThreatDeckReview-row">
                <div className="ThreatDeckCardReview-region">
                    <label htmlFor="regionMultiselect">Region</label>
                    <Multiselect
                        id={'regionMultiselect'}
                        selectionLimit={1}
                        options={regionList}
                        onSelect={updateRegion}
                        displayValue="name"
                        singleSelect={true}
                        placeholder="Region"
                        avoidHighlightFirstOption={true}
                        style={TD_MULTISELECT_STYLE}
                        selectedValues={region}
                    />
                </div>
                <div className="ThreatDeckCardReview-category">
                    <label htmlFor="categoryMultiselect">Category</label>
                    <Multiselect
                        id={'categoryMultiselect'}
                        selectionLimit={1}
                        options={categoriesList}
                        onSelect={updateCategory}
                        displayValue="label"
                        singleSelect={true}
                        placeholder="Category"
                        avoidHighlightFirstOption={true}
                        style={TD_MULTISELECT_STYLE}
                        selectedValues={category}
                    />
                </div>
                {/* Removing severity display/editing due to design changes,
                    but will keep this here for now just in case. */}
                {/* <div className="ThreatDeckCardReview-severity">
                    <label
                        htmlFor={`severityMultiselect${
                            item?.id ? `-${item.id}` : ''
                        }`}
                    >
                        Severity
                    </label>
                    <Multiselect
                        id={`severityMultiselect${
                            item?.id ? `-${item.id}` : ''
                        }`}
                        selectionLimit={1}
                        options={URGENCIES}
                        onSelect={updateSeverity}
                        displayValue="label"
                        singleSelect={true}
                        placeholder="Severity"
                        avoidHighlightFirstOption={true}
                        style={TD_MULTISELECT_STYLE}
                        selectedValues={severity}
                    />
                </div> */}
            </div>
            <div className="ThreatDeckCardReview-details ThreatDeckReview-row">
                <label className="ThreatDeckCardReview-label">Details</label>
                <div className="ThreatDeckCardReview-details-container">
                    <ThreatDeckCardContent item={item} />
                </div>
            </div>
            <div className="ThreatDeckCardReview-footer ThreatDeckReview-row">
                <div className="footer-left">
                    <ThreatDeckCardAcceptReject
                        onSwitchChange={onSwitchChange}
                        state={isThreat}
                    />
                </div>
                <div className="footer-right">
                    {closeReviewModal && (
                        <ICButton
                            color="white"
                            onClick={resetAndCloseModal}
                            className="ThreatDeckCardReview-cancel-button"
                            data-testid={
                                TEST_ATTRIBUTES.THREAT_DECK.REVIEW.BUTTONS
                                    .CANCEL
                            }
                        >
                            Cancel
                        </ICButton>
                    )}
                    {(authInfo.role === LABELER ||
                        location.pathname === '/labeling') && (
                        <ICButton
                            color="light-blue"
                            onClick={skipItem}
                            className="ThreatDeckCardReview-skip-button"
                            data-testid={
                                TEST_ATTRIBUTES.THREAT_DECK.REVIEW.BUTTONS.SKIP
                            }
                        >
                            Skip
                        </ICButton>
                    )}
                    <ICButton
                        color="blue"
                        onClick={saveFeedItem}
                        className="ThreatDeckCardReview-save-button"
                        data-testid={
                            TEST_ATTRIBUTES.THREAT_DECK.REVIEW.BUTTONS.SAVE
                        }
                    >
                        Save
                    </ICButton>
                    {includes(
                        [ADMINISTRATOR, LEAD_ANALYST, ANALYST],
                        authInfo.role
                    ) &&
                        location.pathname !== '/labeling' && (
                            <ICButton
                                color="green"
                                onClick={createThreat}
                                className="ThreatDeckCardReview-create-button"
                                data-testid={
                                    TEST_ATTRIBUTES.THREAT_DECK.REVIEW.BUTTONS
                                        .CREATE_THREAT
                                }
                            >
                                Create Threat
                            </ICButton>
                        )}
                </div>
            </div>
        </div>
    );
}
