import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { setAlertModal } from '../../../redux/actions';
import { getNamespace } from '../../../redux/selectors';
import ICButton from '../presentational/ICButton';

export default function PublishAlertButton({
    alert,
    onAfterClick,
    onAfterError,
    isUpdate
}) {
    // redux
    const dispatch = useDispatch();
    function displayAlertModal({ alert }) {
        dispatch(setAlertModal({ alert }));
    }
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    useEffect(() => {
        return () => {
            if (alert.isUpdate || isUpdate) {
                threatsNamespace.off(`publishUpdateFailed_${alert.id}`);
            } else {
                threatsNamespace.off(`publishAlertFailed_${alert.id}`);
            }
        };
    });

    function publishFailed(message) {
        confirmAlert({
            title: 'Failed to Publish',
            message: 'Publishing failed with the following message:',
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {
                        if (onAfterError) {
                            onAfterError();
                        }
                    }
                }
            ],
            childrenElement: () => (
                <>
                    <p>
                        <tt>{message}</tt>
                    </p>
                    <p>
                        If you continue to see problems, please contact support.
                    </p>
                </>
            )
        });
    }

    function publishAlert() {
        confirmAlert({
            title: 'Confirm Publish',
            message: `Are you sure you want to publish ${alert.title}?  This will mark it active and make it visible to the world.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let socketEmit =
                            alert.isUpdate || isUpdate
                                ? 'publishUpdate'
                                : 'publishAlert';
                        displayAlertModal({ alert: false });
                        if (onAfterClick) {
                            onAfterClick();
                        }
                        if (alert.isUpdate || isUpdate) {
                            threatsNamespace.once(
                                `publishUpdateFailed_${alert.id}`,
                                publishFailed
                            );
                        } else {
                            threatsNamespace.once(
                                `publishAlertFailed_${alert.id}`,
                                publishFailed
                            );
                        }
                        threatsNamespace.emit(socketEmit, alert.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    return (
        <ICButton
            onClick={publishAlert}
            color="dark-green"
            data-testid={`${TEST_ATTRIBUTES.BUTTONS.PUBLISH}-${alert.id}`}
        >
            Publish
        </ICButton>
    );
}
