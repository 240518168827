import '../../styling/ThreatDeckHomePage.css';

import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import size from 'lodash/size';
import some from 'lodash/some';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { MODALS, NAMESPACES } from '../../config/constants';
import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { initColumnConfig } from '../../localStorage/readItemColumnConfigStorage';
import {
    cleanReadItems,
    initReadItems
} from '../../localStorage/readItemsStorage';
import {
    addThreatDeckFeedItem,
    archiveEvent,
    expireThreatDeckFeedItems,
    groupCreated,
    groupDeleted,
    groupUpdated,
    onColumnSaved,
    removeThreatDeckFeedItem,
    setAvailableGroupColumns,
    setCategoriesList,
    setGroups,
    setThreatDeckEvents,
    setThreatDeckFeedItemsList,
    setTwitterRules,
    setUserGroups,
    toggleModal,
    updateEvent,
    updateThreatDeckFeedItemAnnotation
} from '../../redux/actions';
import {
    getAuthInfo,
    getModalState,
    getNamespace
} from '../../redux/selectors';
import { doesItemMatchAnyColumn } from '../../utils';
import ICButton from '../Buttons/presentational/ICButton';
import EventDetails from '../Events/EventDetails';
import ThreatDeckCardReviewModal from '../Modals/ThreatDeck/ThreatDeckCardReviewModal';
import ThreatDeckColumn from './ThreatDeckColumn';
import ThreatDeckColumnForm from './ThreatDeckColumnForm/ThreatDeckColumnForm';
import ThreatDeckGroupForm from './ThreatDeckGroupForm/ThreatDeckGroupForm';
import ThreatDeckGroupSelectionForm from './ThreatDeckGroupSelectionForm/ThreatDeckGroupSelectionForm';

export default function ThreatDeckHomePage() {
    // REDUX
    const dispatch = useDispatch();
    const authInfo = useSelector(getAuthInfo);
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );
    const feedItemReviewModalState = useSelector(
        getModalState(MODALS.FEED_ITEM_REVIEW)
    );
    const eventDetailsModalState = useSelector(
        getModalState(MODALS.EVENT_DETAILS)
    );

    // STATE
    const [allFeedItems, setAllFeedItems] = useState([]);
    const [showCreateColumnModal, setShowCreateColumnModal] = useState(false);
    const [showGroupSelectionModal, setShowGroupSelectionModal] =
        useState(false);
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [expireIntervalId, setExpireIntervalId] = useState(false);
    const [reorderingColumns, setReorderingColumns] = useState(false);
    const columnToEdit = useRef();
    const groupToEdit = useRef();
    const userThreatDeckColumns = useRef([]);

    // Initialize read item storage
    initReadItems(authInfo?.user?.id);
    initColumnConfig(authInfo?.user?.id);

    function closeModal(modalName) {
        dispatch(
            toggleModal({
                data: {},
                modalName,
                value: false
            })
        );
    }

    // the newFeedItem listener should be cleared and remade when the user's column list changes
    // otherwise we run the risk of creating duplicate listeners which have their own host of pitfalls
    useEffect(() => {
        if (size(userThreatDeckColumns.current)) {
            threatDeckNamespace.off('newFeedItem');
            threatDeckNamespace.on(`newFeedItem`, (feedItem) => {
                // We can also now pre-filter any new feed item we receive
                // using the user's column settings
                if (
                    doesItemMatchAnyColumn(
                        feedItem,
                        userThreatDeckColumns.current
                    )
                ) {
                    dispatch(addThreatDeckFeedItem(feedItem));
                } else {
                    // TODO: Eventually remove this?
                    console.log(
                        'New feed item does not match any column filters. Discarding. ',
                        feedItem
                    );
                }
            });
        }
    }, [userThreatDeckColumns.current]);

    useEffect(() => {
        if (threatDeckNamespace) {
            // listeners
            threatDeckNamespace.on('threatDeckEventsList', (events) => {
                dispatch(setThreatDeckEvents(events));
            });

            threatDeckNamespace.on('updatedFeedItemEvent', (event) => {
                dispatch(updateEvent(event));
            });

            threatDeckNamespace.on('threatDeckEventArchived', (event) => {
                dispatch(archiveEvent(event));
            });

            // emits
            threatDeckNamespace.emit('getThreatDeckEvents');

            return () => {
                threatDeckNamespace.off('updatedFeedItemEvent');
                threatDeckNamespace.off('threatDeckEventArchived');
                threatDeckNamespace.off('threatDeckEventsList');
            };
        }
    }, [threatDeckNamespace]);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('threatDeckEventsList', (events) => {
                dispatch(setThreatDeckEvents(events));
            });

            threatDeckNamespace.on('updatedFeedItemEvent', (event) => {
                dispatch(updateEvent(event));
            });

            threatDeckNamespace.on('threatDeckEventArchived', (event) => {
                dispatch(archiveEvent(event));
            });

            // On receiving the current user's column details
            threatDeckNamespace.on(`userColumnsList`, (userColumnsList) => {
                userThreatDeckColumns.current = userColumnsList;

                dispatch(
                    setAvailableGroupColumns(
                        map(userColumnsList, (userColumn) =>
                            pick(userColumn, [
                                'id',
                                'name',
                                'type',
                                'group',
                                'ThreatDeckUserColumns'
                            ])
                        )
                    )
                );

                // Now that we have the user's column details, we will
                // request the initial batch of feed items. Emitting here
                // allows us to re-request that batch when column settings change.
                threatDeckNamespace.emit('getFeedItems');
            });

            threatDeckNamespace.on('columnSaved', ({ columnId, groupId }) => {
                dispatch(
                    onColumnSaved({ columnId, groupId, threatDeckNamespace })
                );
            });

            threatDeckNamespace.on('groupsList', (groups) => {
                dispatch(setGroups(groups));
            });

            threatDeckNamespace.on('userGroups', (userGroups) => {
                dispatch(setUserGroups(userGroups));
            });

            // On receiving the categories list
            threatDeckNamespace.on('categoriesList', (categories) => {
                dispatch(setCategoriesList(categories));
            });

            // On receiving the initial batch of feed items
            threatDeckNamespace.on('feedItemsList', (feedItems) => {
                // Set array of feed items to be pre-filtered
                setAllFeedItems(feedItems);
                // Clean up any old items from the readItems datastore
                cleanReadItems(map(feedItems, 'id'));
            });

            // On updating the annotations of a feed item
            threatDeckNamespace.on(
                'updatedFeedItemAnnotation',
                (annotation) => {
                    dispatch(updateThreatDeckFeedItemAnnotation(annotation));
                }
            );

            // On creating an alert from a feed item
            threatDeckNamespace.on('feedItemAlertCreated', (feedItem) => {
                // turn off the failed check
                threatDeckNamespace.off(
                    `failedCreateFeedItemAlert-${feedItem.id}`
                );

                // remove the feed item from the list in redux
                dispatch(removeThreatDeckFeedItem(feedItem));
                // close the modal
                dispatch(
                    toggleModal({
                        modalName: MODALS.FEED_ITEM_REVIEW,
                        value: false,
                        data: {}
                    })
                );
            });

            // on twitterRules dispatch twitter rules to redux
            threatDeckNamespace.on('twitterRules', (twitterRules) => {
                dispatch(setTwitterRules(twitterRules));
            });

            // On group deletion
            threatDeckNamespace.on('groupDeleted', (groupId) => {
                dispatch(groupDeleted(groupId));
                // get user columns if they are using a column in that group
                if (
                    find(
                        userThreatDeckColumns.current,
                        (column) => column?.group[0]?.id === groupId
                    )
                ) {
                    threatDeckNamespace.emit('getUserColumns');
                }
            });

            // When a group is created and the user is using a column in that group, refresh the user's columns
            threatDeckNamespace.on('groupCreated', ({ columns, group }) => {
                dispatch(groupCreated(group));
                if (
                    some(userThreatDeckColumns.current, (c) =>
                        includes(columns, c.id)
                    )
                ) {
                    threatDeckNamespace.emit('getUserColumns');
                }
            });
            // When a group is saved, if the user is in that group, refresh the user's columns
            threatDeckNamespace.on('groupSaved', (group) => {
                dispatch(groupUpdated(group));
                // currently we determine if the user is in the group by checking if the user is using a column in that group
                // TODO: this can improved when we are loading the user's groups into redux, but we haven't done that yet
                if (
                    find(
                        userThreatDeckColumns.current,
                        (column) => column?.group[0]?.id === group.id
                    )
                ) {
                    threatDeckNamespace.emit('getUserColumns');
                }
            });

            threatDeckNamespace.on('threatDeckEventsList', (events) => {
                dispatch(setThreatDeckEvents(events));
            });

            threatDeckNamespace.on('updatedFeedItemEvent', (event) => {
                dispatch(updateEvent(event));
            });

            threatDeckNamespace.on('threatDeckEventArchived', (event) => {
                dispatch(archiveEvent(event));
            });

            threatDeckNamespace.emit('getCategories');
            threatDeckNamespace.emit('getGroups');
            threatDeckNamespace.emit('getUserGroups');
            threatDeckNamespace.emit('getTwitterRules');
            threatDeckNamespace.emit('getUserColumns');
            threatDeckNamespace.emit('getThreatDeckEvents');

            // Run the item expiration action every 10 seconds to check for ones older than the allowed time
            let intervalId = setInterval(
                doExpiration,
                10 * 1000 /* every 10 seconds */
            );
            setExpireIntervalId(intervalId);
        }

        return () => {
            if (threatDeckNamespace) {
                threatDeckNamespace.off(`categoriesList`);
                threatDeckNamespace.off(`feedItems`);
                threatDeckNamespace.off('groupCreated');
                threatDeckNamespace.off('groupSaved');
                threatDeckNamespace.off(`newFeedItem`);
                threatDeckNamespace.off(`updatedFeedItemAnnotation`);
                threatDeckNamespace.off(`userColumnsList`);
                threatDeckNamespace.off('updatedFeedItemEvent');
                threatDeckNamespace.off('threatDeckEventArchived');
                threatDeckNamespace.off('threatDeckEventsList');
            }
            if (expireIntervalId) {
                clearInterval(expireIntervalId);
            }
        };
    }, [threatDeckNamespace]);

    function doExpiration() {
        dispatch(expireThreatDeckFeedItems());
    }

    // Filter out feed items that don't match current column filters
    function filterFeedItems() {
        const filteredFeedItems = filter(allFeedItems, (item) => {
            return doesItemMatchAnyColumn(item, userThreatDeckColumns.current);
        });

        // TODO: Eventually remove this?
        console.log(
            'New column settings received. Removing items that do not match column filters:',
            differenceBy(allFeedItems, filteredFeedItems, 'id')
        );

        // Store the filtered array of feed items to be read by the columns
        dispatch(setThreatDeckFeedItemsList(filteredFeedItems));
        // Clear the original array of all feed items
        setAllFeedItems([]);
    }

    useEffect(() => {
        // Once we have the details of the current user's column settings,
        // and the result from the request to the db for the latest
        // chunk of items, pre-filter this items to remove anything
        // that doesn't match the column filters
        if (!isEmpty(userThreatDeckColumns.current) && !isEmpty(allFeedItems)) {
            filterFeedItems();
        }
    }, [userThreatDeckColumns.current, allFeedItems]);

    function closeCreateColumnModal() {
        setShowCreateColumnModal(false);
        columnToEdit.current = null;
    }

    function closeGroupModal() {
        setShowCreateGroupModal(false);
        groupToEdit.current = {};
    }

    function closeGroupSelectionModal() {
        setShowGroupSelectionModal(false);
    }

    function onDragEnd(result) {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const draggedColumn = find(userThreatDeckColumns.current, [
            'id',
            parseInt(draggableId)
        ]);

        userThreatDeckColumns.current.splice(source.index, 1);
        userThreatDeckColumns.current.splice(
            destination.index,
            0,
            draggedColumn
        );

        const newColumns = [...userThreatDeckColumns.current];
        userThreatDeckColumns.current = newColumns;
        threatDeckNamespace.emit('saveColumnOrder', newColumns);
    }

    return (
        <div>
            <div className="TD-Header">
                <div className="TD-Header-Buttons">
                    <ICButton
                        className="TD-Header-Button"
                        onClick={() => {
                            setReorderingColumns(!reorderingColumns);
                        }}
                        color="light-blue"
                        data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.BUTTONS.REORDER_COLUMNS}`}
                    >
                        {reorderingColumns ? 'Lock Columns' : 'Reorder Columns'}
                    </ICButton>
                    <ICButton
                        className="TD-Header-Button"
                        onClick={() => {
                            setShowCreateColumnModal(true);
                        }}
                        color="light-blue"
                        data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.BUTTONS.CREATE_NEW_COLUMN}`}
                    >
                        New Column
                    </ICButton>
                    <ICButton
                        className="TD-Header-Button"
                        onClick={() => {
                            setShowGroupSelectionModal(true);
                        }}
                        color="light-blue"
                        data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.BUTTONS.VIEW_GROUP}`}
                    >
                        View Groups
                    </ICButton>
                    {includes(['Administrator'], authInfo?.user?.role) && (
                        <ICButton
                            className="TD-Header-Button"
                            onClick={() => {
                                setShowCreateGroupModal(true);
                            }}
                            color="light-blue"
                            data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.BUTTONS.NEW_GROUP}`}
                        >
                            New Group
                        </ICButton>
                    )}
                </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="1" direction="horizontal">
                    {(provided) => (
                        <div
                            className={`TD-Columns ${
                                reorderingColumns ? 'TD-Columns-reordering' : ''
                            }`}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {map(
                                userThreatDeckColumns.current,
                                (column, index) => (
                                    <Draggable
                                        draggableId={String(column.id)}
                                        key={column.id}
                                        index={index}
                                        isDragDisabled={!reorderingColumns}
                                    >
                                        {(provided) => (
                                            <ThreatDeckColumn
                                                column={column}
                                                key={`${
                                                    column.id
                                                        ? column.id
                                                        : column.name
                                                }`}
                                                openModal={() => {
                                                    columnToEdit.current =
                                                        column;
                                                    setShowCreateColumnModal(
                                                        true
                                                    );
                                                }}
                                                groupToEdit={groupToEdit}
                                                setShowCreateGroupModal={
                                                    setShowCreateGroupModal
                                                }
                                                innerRef={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            />
                                        )}
                                    </Draggable>
                                )
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Modal
                isOpen={showCreateGroupModal}
                contentLabel="Create New Group"
                ariaHideApp={false}
                style={{
                    content: {
                        width: '565px',
                        minWidth: '565px',
                        padding: '0 20px'
                    }
                }}
            >
                <ThreatDeckGroupForm
                    closeModal={closeGroupModal}
                    group={groupToEdit.current}
                />
            </Modal>
            <Modal
                isOpen={showCreateColumnModal}
                contentLabel="Create New Column"
                ariaHideApp={false}
                style={{
                    content: {
                        width: '565px',
                        minWidth: '565px',
                        padding: '0 20px'
                    }
                }}
            >
                <ThreatDeckColumnForm
                    closeModal={closeCreateColumnModal}
                    column={columnToEdit.current}
                    columns={userThreatDeckColumns.current}
                />
            </Modal>
            <Modal
                isOpen={showGroupSelectionModal}
                contentLabel="View Groups"
                ariaHideApp={false}
                style={{
                    content: {
                        width: '565px',
                        minWidth: '565px',
                        padding: '0 20px'
                    }
                }}
            >
                <ThreatDeckGroupSelectionForm
                    closeModal={closeGroupSelectionModal}
                    group={groupToEdit.current}
                    viewOnly
                />
            </Modal>
            <Modal
                isOpen={
                    feedItemReviewModalState?.data?.id &&
                    feedItemReviewModalState?.isOpen
                }
                contentLabel="Source Details"
                ariaHideApp={false}
                onRequestClose={() => {
                    closeModal(MODALS.FEED_ITEM_REVIEW);
                }}
                style={{
                    content: {
                        top: '20%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -20%)',
                        width: '600px',
                        minHeight: '42%',
                        maxHeight: '80%'
                    }
                }}
            >
                <ThreatDeckCardReviewModal
                    item={feedItemReviewModalState?.data}
                    closeReviewModal={() => {
                        closeModal(MODALS.FEED_ITEM_REVIEW);
                    }}
                />
            </Modal>
            {eventDetailsModalState && (
                <Modal
                    isOpen={
                        eventDetailsModalState?.data?.id &&
                        eventDetailsModalState?.isOpen
                    }
                    contentLabel="Source Details"
                    ariaHideApp={false}
                    onRequestClose={() => {
                        closeModal(MODALS.EVENT_DETAILS);
                    }}
                    style={{
                        content: {
                            top: '20%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -20%)',
                            // width: '900px',
                            minHeight: '42%',
                            maxHeight: '80%',
                            overflowY: 'hidden'
                        }
                    }}
                >
                    <EventDetails
                        eventId={eventDetailsModalState?.data?.id}
                        isModalView={true}
                        setDoesNotExist={() => {}}
                        closeModal={() => {
                            closeModal(MODALS.EVENT_DETAILS);
                        }}
                    />
                </Modal>
            )}
        </div>
    );
}
