import '../styling/PageNotFound.css';

import React from 'react';
import { Link } from 'react-router-dom';

export default function PageNotFound({ link = '', objectName = 'Page' }) {
    return (
        <div className="PageNotFound-container">
            <h2>404 - {objectName} Not Found</h2>

            <p>
                This {objectName} does not appear to exist. Click{' '}
                <Link to={link}>here</Link> to return to the homepage.
            </p>
        </div>
    );
}
