import { head, split, upperCase } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { BsArrowReturnRight, BsCheckAll } from 'react-icons/bs';
import {
    IoChatbubbleSharp,
    IoCheckmarkDoneOutline,
    IoTrashOutline
} from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../config/constants';
import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { getNamespace } from '../../redux/selectors';

export default function AnalystChatMessage({
    handleDelete,
    message,
    openThread,
    isThread,
    canDelete
}) {
    const [isHovered, setIsHovered] = useState(false);
    const author = split(message.userName, ' ')[0];
    const company = message.companyName || '';
    const timeString = message.createdAt
        ? moment(message.createdAt).format('h:mm A')
        : '';

    const iconText = upperCase(head(author)) + upperCase(head(company));
    const chatNamespace = useSelector(getNamespace(NAMESPACES.CHAT_NAMESPACE));

    function handleMarkRead() {
        chatNamespace.emit('setAnalystReadStatus', {
            markAsRead: !message.readByAnalystName,
            chatMessageId: message.id
        });
    }
    function handleReply() {
        openThread(message);
    }

    return (
        message && (
            <div
                className="ChatMessage"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    className="ChatMessage-main"
                    data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_MESSAGE}-${message.id}`}
                >
                    <div className="ChatMessage-info">
                        <div className="ChatMessage-details">
                            <div
                                className="ChatMessage-icon"
                                data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_MESSAGE_ICON}-${message.id}`}
                            >
                                {iconText}
                            </div>
                            <div
                                className="ChatMessage-author"
                                data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_MESSAGE_AUTHOR}-${message.id}`}
                            >
                                {author}
                            </div>
                            <div
                                className="ChatMessage-company"
                                data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_MESSAGE_COMPANY}-${message.id}`}
                            >
                                {company}
                            </div>
                            <div
                                className="ChatMessage-time"
                                data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_MESSAGE_TIME}-${message.id}`}
                            >
                                {timeString}
                            </div>
                        </div>
                    </div>
                    <div
                        className="ChatMessage-content"
                        dangerouslySetInnerHTML={{ __html: message.message }}
                    ></div>{' '}
                    <div className="ChatMessage-thread-info">
                        {message.readByAnalystName && (
                            <div style={{ display: 'inline-flex' }}>
                                <div
                                    className={`ChatMessage-read-by-name ${
                                        isHovered ? 'hovered' : ''
                                    }`}
                                    data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_MESSAGE_READ_BY}-${message.id}`}
                                >
                                    <BsCheckAll></BsCheckAll> Read by{' '}
                                    {message.readByAnalystName}{' '}
                                </div>
                            </div>
                        )}
                        {!isThread && message.numberOfReplies > 0 && (
                            <div className="ChatMessage-replyCount">
                                <BsArrowReturnRight> </BsArrowReturnRight>{' '}
                                <div
                                    data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_BADGE}-${message.id}`}
                                    className={`analyst-chat-badge ${
                                        message.hasUnreadReplies
                                            ? 'show-badge'
                                            : 'hide-badge'
                                    }`}
                                ></div>
                                <div
                                    className="ChatMessage-reply-link"
                                    data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_MESSAGE.CHAT_MESSAGE_REPLY_COUNT}-${message.id}`}
                                    onClick={handleReply}
                                >
                                    {message.numberOfReplies} repl
                                    {message.numberOfReplies > 1 ? 'ies' : 'y'}
                                </div>{' '}
                                - Last reply{' '}
                                {moment.unix(message.lastReply).calendar()}
                            </div>
                        )}
                    </div>
                </div>
                <div className={'ChatMessage-sidebar'}>
                    {
                        <div className="ChatMessage-options">
                            {canDelete && (
                                <button
                                    onClick={() => handleDelete(message)}
                                    data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_OPTIONS.DELETE_MESSAGE}-${message.id}`}
                                    hidden={!isHovered}
                                >
                                    <IoTrashOutline className="ChatMessage-delete" />
                                </button>
                            )}
                            {!message.analystUserId && (
                                <button
                                    onClick={handleMarkRead}
                                    data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_OPTIONS.MARK_AS_READ}-${message.id}`}
                                    hidden={!isHovered}
                                >
                                    <IoCheckmarkDoneOutline className="ChatMessage-mark-read" />
                                </button>
                            )}
                            {!isThread && (
                                <button
                                    onClick={handleReply}
                                    data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_OPTIONS.OPEN_THREAD}-${message.id}`}
                                    hidden={!isHovered}
                                >
                                    <IoChatbubbleSharp className="ChatMessage-reply" />
                                </button>
                            )}
                        </div>
                    }
                </div>
            </div>
        )
    );
}
