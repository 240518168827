import '../../../styling/UserManagement.css';

import React, { useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { NAMESPACES, ROLES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getAuthInfo, getNamespace } from '../../../redux/selectors';
import ICButton from '../../Buttons/presentational/ICButton';
import ICTable from '../../ICTable';
import UserManagementForm from './UserManagementForm';

const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;

export default function UserManagement({ setHideTabs }) {
    //redux
    const authInfo = useSelector(getAuthInfo);
    const usersNamespace = useSelector(
        getNamespace(NAMESPACES.USERS_NAMESPACE)
    );

    //state
    const [usersCoveringRegions, setUsersCoveringRegions] = useState([]);
    const [createUserFormActive, setCreateUserFormActive] = useState(false);
    const [userToEdit, setUserToEdit] = useState(null);

    // load data via socket
    useEffect(() => {
        if (usersNamespace) {
            usersNamespace.on('usersListWithRegions', setUsersCoveringRegions);
            usersNamespace.emit('getUsersListWithRegions');
        }

        // component cleanup, cancel socket listeners
        // this prevents setting state on unmounted components
        return () => {
            usersNamespace.off('usersListWithRegions');
        };
        // we might not even want to reFetch data if the socket changes
    }, [usersNamespace]);

    /**
     * Used as part of the custom column render for the Regions column
     * @param {array} regions all regions a user is covering
     * @param {object} user the user object
     * @returns a remove from region button for every region the user is covering
     */
    const renderUserRegionButtons = ({ user, regions }) => {
        return regions.map((region) => (
            <button
                key={region.id}
                className="ActiveRoster-region"
                onClick={() => {
                    removeUserFromRegion({ user, region });
                }}
                data-testid={`${TEST_ATTRIBUTES.BUTTONS.REMOVE_USER_FROM_REGION}-user:${user.id}-region:${region.id}`}
            >
                {region.name} <i className="fa fa-ban" />
            </button>
        ));
    };

    function removeUserFromRegion({ user, region }) {
        if (authInfo.role === LEAD_ANALYST || authInfo.role === ADMINISTRATOR) {
            confirmAlert({
                title: 'Confirm Remove from Region',
                message: `Are you sure you want to remove ${user.name} from ${region.name}?`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            usersNamespace.emit('removeUserFromRegion', {
                                user: user.id,
                                external_id: region.external_id
                            });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {} // Do Nothing
                    }
                ]
            });
        }
    }

    const renderUserEditButtons = ({ user }) => {
        return (
            <span>
                <span
                    className={`${
                        user.disabled ? 'user-name-disabled' : 'user-name'
                    }`}
                >
                    {`${user.name}${user.disabled ? ' (Disabled)' : ''}`}
                </span>
                {authInfo.role === ADMINISTRATOR && (
                    <ICButton
                        key={`edit-${user.id}`}
                        color="blue"
                        className="UserManagement-edit-button"
                        onClick={() => editUser(user)}
                        data-testid={`${TEST_ATTRIBUTES.BUTTONS.EDIT_USER}-user:${user.id}`}
                    >
                        Edit
                    </ICButton>
                )}
            </span>
        );
    };

    function editUser(user) {
        if (authInfo.role === ADMINISTRATOR) {
            setUserToEdit(user);
            setCreateUserFormActive(!createUserFormActive);
        }
    }

    const userManagementColumns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
                Filter: false,
                customColumnRender: {
                    // to avoid passing the cell object which basically has references to the entire table
                    // we list the args we want and pull the values in the table
                    render: renderUserEditButtons,
                    args: { user: 'row.original' }
                }
            },
            { Header: 'Email', accessor: 'email' },
            { Header: 'Role', accessor: 'role' },
            {
                Header: 'Regions Monitoring',
                accessor: 'regions',
                Filter: false,
                customColumnRender: {
                    // to avoid passing the cell object which basically has references to the entire table
                    // we list the args we want and pull the values in the table
                    render: renderUserRegionButtons,
                    args: { user: 'row.original', regions: 'value' }
                }
            }
        ];
        // renderUserRegionbuttons isn't going to change dynamically. We memo once.
    }, []);

    return (
        <Container className="ConfigureSystem-Container">
            {!createUserFormActive && (
                <span>
                    <Row>
                        <Col>
                            <h1>Active Analyst Roster</h1>
                        </Col>
                        {authInfo.role === ADMINISTRATOR && (
                            <Col>
                                <ICButton
                                    color="green"
                                    className="UserForm-create-button"
                                    onClick={() => {
                                        setCreateUserFormActive(
                                            !createUserFormActive
                                        );
                                    }}
                                >
                                    Create User
                                </ICButton>
                            </Col>
                        )}
                    </Row>

                    <ICTable
                        columns={userManagementColumns}
                        data={usersCoveringRegions}
                        reference={'UserManagement'}
                    />
                </span>
            )}
            {createUserFormActive && (
                <UserManagementForm
                    setCreateUserFormActive={setCreateUserFormActive}
                    userToEdit={userToEdit}
                    setUserToEdit={setUserToEdit}
                    setHideTabs={setHideTabs}
                />
            )}
        </Container>
    );
}
