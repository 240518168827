import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

import { NAMESPACES, ROLES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getAuthInfo, getNamespace } from '../../../redux/selectors';
import ICPencilIcon from '../../Buttons/presentational/ICPencilIcon';
import ICTrashIcon from '../../Buttons/presentational/ICTrashIcon';
import StatusBadge from '../shared/StatusBadge';

const { LEAD_ANALYST, ADMINISTRATOR } = ROLES;
const { SITUATIONS_NAMESPACE } = NAMESPACES;

export default function ArticlesTable({
    allowEdit = true,
    articles = [],
    onAdd,
    onEdit
}) {
    const authInfo = useSelector(getAuthInfo);
    const situationsNamespace = useSelector(getNamespace(SITUATIONS_NAMESPACE));

    function deleteArticle(article) {
        confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure you want to delete ${article.title}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () =>
                        situationsNamespace.emit('deleteArticle', article.id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const canDelete = _.includes([LEAD_ANALYST, ADMINISTRATOR], authInfo.role);

    function renderTable() {
        return (
            <table className="ArticlesTable">
                <thead className="ArticlesTable-head">
                    <tr>
                        <th>TITLE</th>
                        <th>DATE</th>
                        <th>STATUS</th>
                        <th>PINNED?</th>
                        {allowEdit && <th id="actions-header">ACTIONS</th>}
                    </tr>
                </thead>
                <tbody className="ArticlesTable-body">
                    {_.map(articles, (article) => {
                        return (
                            <tr key={article.id}>
                                <td>
                                    <strong
                                        className="ContentManagement-title-cell"
                                        onClick={() => onEdit(article.id)}
                                    >
                                        {article.title}
                                    </strong>
                                </td>
                                <td>
                                    {moment
                                        .unix(article.articleDate)
                                        .format('MMMM Do, YYYY')}
                                </td>
                                <td>
                                    <StatusBadge status={article.status} />
                                </td>
                                <td>
                                    {article.pinned ? (
                                        <span className="badge grey-button">
                                            Pinned
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </td>
                                {allowEdit && (
                                    <td className="icons-container">
                                        {canDelete && (
                                            <ICTrashIcon
                                                onClick={() =>
                                                    deleteArticle(article)
                                                }
                                                data-testid={`${TEST_ATTRIBUTES.CONTENT_MANAGEMENT.DELETE_ARTICLE}-${article.id}`}
                                            />
                                        )}
                                        <ICPencilIcon
                                            onClick={() => onEdit(article.id)}
                                            data-testid={`${TEST_ATTRIBUTES.CONTENT_MANAGEMENT.EDIT_ARTICLE}-${article.id}`}
                                        />
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    return (
        <div className="ContentForm-area">
            <div className="ContentForm-area-header">
                <h3>Articles</h3>
                {allowEdit && (
                    <div className="actions">
                        <a
                            href="#"
                            onClick={onAdd}
                            data-testid={
                                TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                    .ADD_NEW_ARTICLE
                            }
                        >
                            Add New
                        </a>
                    </div>
                )}
            </div>
            {articles.length === 0 ? (
                <p>No articles have been added yet.</p>
            ) : (
                renderTable()
            )}
        </div>
    );
}
