import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import {
    MAP,
    MULTISELECT_COMPONENT_STYLES,
    NAMESPACES,
    ROLES
} from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { getAuthInfo, getNamespace } from '../../../redux/selectors';
import { getLocationFromOverlay } from '../../../utils';
import AlertMap from '../../AlertMap';
import ICButton from '../../Buttons/presentational/ICButton';
import {
    canDeleteMap,
    canDeletePendingMap,
    extractRiskOption
} from './MapFunctions';

const { RISK_OPTIONS } = MAP;
const { ADMINISTRATOR, LEAD_ANALYST, READ_ONLY } = ROLES;

export default function MapForm({
    // The map is what comes in from the table view. This object has been modified
    //  to conform to the TreeMenu library specifications, so the data does NOT
    //  match the DB (fun, right?). Check boundaryToNode function in MapManagement
    //  to understand data structure. Once this component loads, it uses the key from
    //  the map to fetch the boundary from the DB, and yet we are using both some-
    //  what indiscriminately throughout the file. I would prefer to pick one as the
    //  source of record (should be the boundary from DB imo) but *shrug*
    map,
    editMode,
    createMode,
    pendingMode,
    onCloseMap,
    onEnableMap,
    onDisableMap,
    onEditMap,
    onDeleteMap,
    onSaveMap,
    onPublishMap
}) {
    // State
    const [boundary, setBoundary] = useState(undefined);
    const [overlays, setOverlays] = useState(undefined);
    const [savedGeometry, setSavedGeometry] = useState(undefined);
    const [mapLabel, setMapLabel] = useState(map.label);
    const [mapDescriptionValue, setMapDescription] = useState(map.description);
    const [mapRisk, setMapRisk] = useState(
        map ? extractRiskOption(map.risk) : null
    );
    // Redux
    const authInfo = useSelector(getAuthInfo);
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    useEffect(() => {
        if (threatsNamespace) {
            threatsNamespace.on(
                pendingMode ? 'pendingBoundaryById' : 'boundaryById',
                (data) => {
                    setBoundary(data);
                    setMapLabel(data.title);
                    setMapDescription(data.description);
                    setMapRisk(extractRiskOption(data.risk));
                }
            );
            // If the map has no key, we're creating and there's no map to fetch
            if (map.key) {
                threatsNamespace.emit(
                    pendingMode ? 'getPendingBoundary' : 'getBoundary',
                    { id: map.key }
                );
            }
        }

        return () => {
            threatsNamespace.off('pendingBoundaryById');
            threatsNamespace.off('boundaryById');
        };
    }, [threatsNamespace]);

    const updateMapLabel = (event) => {
        setMapLabel(event.target.value);
    };

    const updateMapDescription = (event) => {
        setMapDescription(event.target.value);
    };

    const updateMapRisk = (selectedList, item) => {
        setMapRisk(item);
    };

    const enableMap = () => {
        onEnableMap({ map });
        onCloseMap();
    };

    const disableMap = () => {
        onDisableMap({ map });
        onCloseMap();
    };

    const deleteMap = () => {
        onDeleteMap({ map });
        // Don't close it because it'll be handled by the confirmation prompt
    };

    const editMap = () => {
        onEditMap({ map });
    };

    const saveMap = () => {
        let boundaryId;
        if (createMode) {
            boundaryId = null;
        } else if (boundary && pendingMode) {
            boundaryId = boundary.boundaryId;
        } else if (boundary) {
            boundaryId = boundary.id;
        }
        let geometry = convertOverlaysToGeometries();
        console.log({ geometry });
        if (
            geometry.geometry.coordinates === undefined ||
            geometry.geometry.coordinates.length === 0
        ) {
            confirmAlert({
                title: 'Missing Shape',
                message: 'You must include at least one shape on the map',
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ]
            });
            return;
        }
        let mapUpdate = {
            boundaryId: boundaryId,
            title: mapLabel,
            description: mapDescriptionValue,
            geometry: geometry,
            status: 'PENDING',
            risk: mapRisk?.value
        };
        if (pendingMode) {
            // If we're modifying a boundaryUpdate, then we need to send the id so we can find the record
            //  and update it rather than creating a new one
            mapUpdate.id = boundary.id;
        }
        onSaveMap({ map: mapUpdate });
        onCloseMap();
    };

    const publishMap = () => {
        console.log('publishMap', map);
        onPublishMap({ map });
    };

    const editButtons = () => {
        let mapKeySuffix = map.key ? `_${map.key}` : '';
        return (
            <>
                <ICButton
                    color="white"
                    className="MapFormButton"
                    onClick={onCloseMap}
                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_CANCEL}${mapKeySuffix}`}
                >
                    Cancel
                </ICButton>
                <ICButton
                    color="green"
                    className="MapFormButton"
                    onClick={saveMap}
                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_SAVE}${mapKeySuffix}`}
                >
                    Save
                </ICButton>
            </>
        );
    };

    const showDelete = () => {
        if (pendingMode) {
            return canDeletePendingMap(authInfo.role);
        } else {
            return canDeleteMap(authInfo.role);
        }
    };

    const viewButtons = () => {
        return (
            <>
                <ICButton
                    color="white"
                    className="MapFormButton"
                    onClick={onCloseMap}
                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_CANCEL}_${map.key}`}
                >
                    Cancel
                </ICButton>
                {authInfo.role !== READ_ONLY && (
                    <>
                        <ICButton
                            color="blue"
                            className="MapFormButton"
                            onClick={editMap}
                            data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_EDIT}_${map.key}`}
                        >
                            Edit
                        </ICButton>
                        {!pendingMode &&
                            (authInfo.role === LEAD_ANALYST ||
                                authInfo.role === ADMINISTRATOR) &&
                            (map.enabled ? (
                                <ICButton
                                    color="yellow"
                                    className="MapFormButton"
                                    onClick={disableMap}
                                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_DISABLE}_${map.key}`}
                                >
                                    Disable
                                </ICButton>
                            ) : (
                                <ICButton
                                    color="green"
                                    className="MapFormButton"
                                    onClick={enableMap}
                                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_ENABLE}_${map.key}`}
                                >
                                    Enable
                                </ICButton>
                            ))}
                        {showDelete() && (
                            <ICButton
                                color="red"
                                className="MapFormButton"
                                onClick={deleteMap}
                                data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_DELETE}_${map.key}`}
                            >
                                Delete
                            </ICButton>
                        )}
                        {pendingMode &&
                            _.includes(
                                [LEAD_ANALYST, ADMINISTRATOR],
                                authInfo.role
                            ) && (
                                <ICButton
                                    color="green"
                                    className="MapFormButton"
                                    onClick={publishMap}
                                    data-testid={`${TEST_ATTRIBUTES.BUTTONS.MAP_MODAL_PUBLISH}_${map.key}`}
                                >
                                    Publish
                                </ICButton>
                            )}
                    </>
                )}
            </>
        );
    };

    const mapHeader = () => {
        return (
            <h1>
                {editMode || createMode ? (
                    <input
                        className="MapForm-MapLabel"
                        type="text"
                        value={mapLabel}
                        onChange={updateMapLabel}
                    />
                ) : (
                    mapLabel
                )}
                {/* This is admittedly a bit janky BUT when viewing a pending map, there is no enabled column on the
                      BoundaryUpdate table, so enabled is undefined, not false. This logic only shows the DISABLED
                      text when viewing a published map that has disabled explicitly set to false. */}
                {map.enabled === false && (
                    <span className="MapRowLabel-Disabled">
                        &nbsp;&nbsp;&nbsp;Disabled
                    </span>
                )}
            </h1>
        );
    };

    const mapDescription = () => {
        return editMode || createMode ? (
            <input
                className="MapForm-MapDescription"
                type="text"
                value={mapDescriptionValue}
                onChange={updateMapDescription}
            />
        ) : (
            <p>{mapDescriptionValue}</p>
        );
    };

    const mapRiskSection = () => {
        return editMode || createMode ? (
            <Multiselect
                id="MapForm-MapRisk"
                selectionLimit={1}
                options={RISK_OPTIONS}
                onSelect={updateMapRisk}
                displayValue="label"
                singleSelect={true}
                placeholder="Risk Level"
                style={MULTISELECT_COMPONENT_STYLES}
                selectedValues={mapRisk ? [mapRisk] : []}
            />
        ) : (
            <p>
                <strong>Risk Level:</strong> {mapRisk ? mapRisk.label : 'N/A'}
            </p>
        );
    };

    const onSaveOverlays = (overlays) => {
        setOverlays(overlays);
    };

    const convertOverlaysToGeometries = () => {
        // Need to get an array of Google Map Overlays into GeoJSON for the database...
        let mappedOverlays = overlays === undefined ? [] : overlays;
        let geometry = {
            geometry: {
                type: mappedOverlays.length > 1 ? 'MultiPolygon' : 'Polygon',
                coordinates: mappedOverlays.map((overlay) => {
                    let location = getLocationFromOverlay(overlay);
                    return location.coordinates;
                })
            },
            properties: {
                name: mapLabel
            }
        };
        if (geometry.geometry.type === 'Polygon') {
            // Fix up the Geometry data for single polygon
            geometry.geometry.coordinates = geometry.geometry.coordinates[0];
        }
        setSavedGeometry(geometry);
        return geometry;
    };

    return (
        <>
            <Row>
                <Col sm={7}>
                    {mapHeader()}
                    {mapDescription()}
                    {mapRiskSection()}
                </Col>
                <Col>
                    {editMode || createMode ? editButtons() : viewButtons()}
                </Col>
            </Row>
            <Row>
                <Col className="MapFormMap">
                    {(boundary || createMode) && (
                        <AlertMap
                            boundary={boundary}
                            editMode={editMode || createMode}
                            onSaveOverlays={onSaveOverlays}
                            skipCircles={true}
                            skipSavedBoundaries={true}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
}
